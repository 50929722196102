import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EMotorCompCode } from "../../../Enum/EMotorCompCode";
import ReviewDetails from "../../../Page/Desktop/TW/Common/ReviewDetails";
import MReviewDetails from "../../../Page/Mobile/TW/Common/MReviewDetails";
import { CKYC_ROUTES } from "../../../Router/Path/CKYCRoutes";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWAddFormServices";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  calculateAgeInDays,
  FORMAT_DATE_DD_MM_YYYY,
  GET_QUERY_PARAMETERS,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TW_CREATE_PROPOSAL_REQUEST } from "../../../SupportingFiles/TWHelper";
import { TWSlice } from "../../../redux/slice/TwoWheeler/TWSlice";
import { CLIENTS } from "../../../URLCollection/Clients";

const ReviewDetailsContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [pageStatus, setPageStatus] = useState<number>(7);

  const {
    OWNER_DETAILS,
    ADDRESS_DETAILS,
    SELECTED_QUOTE_DATA,
    VEHICLE_DETAILS,
    NOMINEE_DETAILS,
    ADD_FORM_RESPONSE,
    PLAN_TYPE,
    ADD_FORM,
  } = useAppSelector((state) => state.TW);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const { TW, CKYC } = useAppSelector((state) => state);
  const [paymentForm, setPaymentForm] = useState<any>("");
  const [paymentFormData, setPaymentFormData] = useState<any>();
  const [openRedirectPopup, setOpenRedirectPopup] = useState(false);
  const [CIS_DATA, setCIS_DATA] = useState<any>({});
  const [devUrl, setDevUrl] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "DEV") {
      setDevUrl(true);
    } else {
      setDevUrl(false);
    }
  }, []);

  const CIS_URL_DATA = (propsal: any, TransId: any) => {
    const onSuccess = (res: any) => {
      setLoader(false);
      setOpenRedirectPopup(true);
      setCIS_DATA(res.results.response);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param: any = {
      ProposalNumber: propsal,
      TransactionID: TransId,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_id: SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id,
      insurance_type: "TW Insurance",
      product_code:
        SELECTED_QUOTE_DATA.quotationDetail?.productDetails.product_code,
    };
    TW_ADD_FORM_SERVICES.GET_CIS_URL(onSuccess, onError, param);
  };
  console.log("OWNER_DETAILS", OWNER_DETAILS);

  const PreviewDetails = [
    {
      title: "Owner Details",
      url: `${TW_ROUTES.PROPOSAL_PAGE}/${
        TW_ROUTES.OWNER_DETAILS
      }?type=TW&companyCode=${
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code
      }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
        SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id
      }&user_type=${GET_QUERY_PARAMETERS(
        "user_type"
      )}&utm_source=${GET_QUERY_PARAMETERS(
        "utm_source"
      )}&utm_medium=${GET_QUERY_PARAMETERS("utm_medium")}`,
      detailView: [
        {
          label: "Full Name",
          label_value: OWNER_DETAILS.name.value,
        },
        {
          label: "Email",
          label_value: OWNER_DETAILS.email.value,
        },
        {
          label: "Mobile",
          label_value: OWNER_DETAILS.mobile.value,
        },
        ...(VERIFY_KYC_FORM.customer_type.value !== "Organization"
          ? [
              {
                label: "Gender",
                label_value:
                  OWNER_DETAILS.gender.value === "M" ? "Male" : "Female",
              },
              {
                label: "DOB",
                label_value: OWNER_DETAILS.dob.value,
              },
              {
                label: "Marital Status",
                label_value: OWNER_DETAILS.maritalStatus.value,
              },
            ]
          : [
              {
                label: "GST",
                label_value: OWNER_DETAILS?.GST?.value,
              },
            ]),
        ...(OWNER_DETAILS.includePAOwner
          ? [
              {
                label: "pacover",
                label_value: OWNER_DETAILS.pacover.value,
              },
            ]
          : OWNER_DETAILS.vehicleRegistered
          ? []
          : []),
      ],
    },
    {
      title: "Vehicle Details",
      url: `${TW_ROUTES.PROPOSAL_PAGE}/${
        TW_ROUTES.VEHICLE_DETAILS
      }?type=TW&companyCode=${
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code
      }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
        SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id
      }&user_type=${GET_QUERY_PARAMETERS(
        "user_type"
      )}&utm_source=${GET_QUERY_PARAMETERS(
        "utm_source"
      )}&utm_medium=${GET_QUERY_PARAMETERS("utm_medium")}`,

      detailView:
        ADD_FORM.business_type === "New"
          ? [
              {
                label: "Chassis No",
                label_value: VEHICLE_DETAILS.chasis_no.value,
              },
              {
                label: "Engine No",
                label_value: VEHICLE_DETAILS.engine_no.value,
              },
              {
                label: "Manufacturing Date",
                label_value: FORMAT_DATE_DD_MM_YYYY(
                  VEHICLE_DETAILS.mfg_year.value
                ),
              },
              {
                label: "PAN",
                label_value: VEHICLE_DETAILS.PAN.value,
              },
              ...(VEHICLE_DETAILS.is_vehicle_on_loan
                ? [
                    {
                      label: "Bank Name",
                      label_value: VEHICLE_DETAILS.bank_name.value,
                    },
                    {
                      label: "City",
                      label_value: VEHICLE_DETAILS.city.value,
                    },
                  ]
                : []),
            ]
          : [
              {
                label: "Chassis No",
                label_value: VEHICLE_DETAILS.chasis_no.value,
              },
              {
                label: "Engine No",
                label_value: VEHICLE_DETAILS.engine_no.value,
              },
              {
                label: "Previous Policy No",
                label_value: VEHICLE_DETAILS.prev_policy_no.value,
              },
              {
                label: "Previous Insurer",
                label_value: VEHICLE_DETAILS.prev_insurer.value,
              },
              {
                label: "Manufacturing Date",
                label_value: FORMAT_DATE_DD_MM_YYYY(
                  VEHICLE_DETAILS.mfg_year.value
                ),
              },
              {
                label: "Registration Date",
                label_value: VEHICLE_DETAILS.reg_date.value,
              },
              {
                label: "Policy Expiry Date",
                label_value:
                  VEHICLE_DETAILS.prev_insurer.value === "Do not remember"
                    ? ""
                    : VEHICLE_DETAILS.policy_exp_date.value,
              },
              {
                label: "PAN",
                label_value: VEHICLE_DETAILS.PAN.value,
              },
              ...(TW.PLAN_TYPE === "SAOD"
                ? [
                    {
                      label: "TP Policy Start Date",
                      label_value: FORMAT_DATE_DD_MM_YYYY(
                        VEHICLE_DETAILS.tp_policy_start.value
                      ),
                    },
                  ]
                : []),
              ...(VEHICLE_DETAILS.is_vehicle_on_loan
                ? [
                    {
                      label: "Bank Name",
                      label_value: VEHICLE_DETAILS.bank_name.value,
                    },
                    {
                      label: "City",
                      label_value: VEHICLE_DETAILS.city.value,
                    },
                  ]
                : []),
            ],
    },
    VERIFY_KYC_FORM.customer_type.value === "Organization"
      ? null
      : {
          title: "Nominee Details",
          url: `${TW_ROUTES.PROPOSAL_PAGE}/${
            TW_ROUTES.NOMINEE_DETAILS
          }?type=TW&companyCode=${
            SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code
          }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
            SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id
          }&user_type=${GET_QUERY_PARAMETERS(
            "user_type"
          )}&utm_source=${GET_QUERY_PARAMETERS(
            "utm_source"
          )}&utm_medium=${GET_QUERY_PARAMETERS("utm_medium")}`,

          detailView: [
            {
              label: "Nominee Name",
              label_value: NOMINEE_DETAILS.fullName?.value,
            },
            {
              label: "Relationship",
              label_value: NOMINEE_DETAILS.relationship?.value,
            },
            {
              label: "DOB",
              label_value: NOMINEE_DETAILS.dob?.value,
            },
          ],
        },
    {
      title: "Address Details",
      url: `${TW_ROUTES.PROPOSAL_PAGE}/${
        TW_ROUTES.ADDRESS_DETAILS
      }?type=TW&companyCode=${
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code
      }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
        SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id
      }&user_type=${GET_QUERY_PARAMETERS(
        "user_type"
      )}&utm_source=${GET_QUERY_PARAMETERS(
        "utm_source"
      )}&utm_medium=${GET_QUERY_PARAMETERS("utm_medium")}`,
      isMailingAddresssame: ADDRESS_DETAILS.isMailingAddressSame,
      detailView: [
        {
          label: "H.No./Building",
          label_value: ADDRESS_DETAILS.addressLineOne.value,
        },
        {
          label: "Street/Area",
          label_value: ADDRESS_DETAILS.addressLineTwo.value,
        },
        {
          label: "City",
          label_value: `${ADDRESS_DETAILS.city}`,
        },
        {
          label: "State",
          label_value: ADDRESS_DETAILS.state,
        },
        {
          label: "Pincode",
          label_value: ADDRESS_DETAILS.pincode.value,
        },
      ],
      mailingDetailView:
        ADDRESS_DETAILS.isMailingAddressSame === false
          ? [
              {
                label: "H.No./Building",
                label_value: ADDRESS_DETAILS.mailingAddressLineOne.value,
              },
              {
                label: "Street/Area",
                label_value: ADDRESS_DETAILS.mailingAddressLineTwo.value,
              },

              {
                label: "City",
                label_value: `${ADDRESS_DETAILS.mailingCity}`,
              },
              {
                label: "MailinState",
                label_value: ADDRESS_DETAILS.mailingState,
              },
              {
                label: "Pincode",
                label_value: ADDRESS_DETAILS.mailingPinCode.value,
              },
            ]
          : null,
    },
  ];

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "pageStatus") {
    } else if (attrName === "backbutton") {
      navigate(-1);
    }
  };
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState<boolean>(false);

  const CREATE_PROPOSAL = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      setTimeout(() => {
        setLoader(false);
      }, 3000);

      if (error) {
        const message = results.message;
        toast.error(`${message}`);
        setLoader(false);
      } else {
        const make_payment = (results: any) => {
          const response = results.response;
          const paymentRequest = response["paymentRequest"];
          const actionURL = paymentRequest["actionURL"];
          const actionMethod = paymentRequest["actionMethod"];

          if (actionMethod === "POST") {
            let form = document.createElement("form");
            form.action = actionURL;
            form.method = actionMethod;

            Object.entries(paymentRequest).forEach(([key, value]) => {
              if (key !== "actionURL" && key !== "actionMethod") {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = `${value}`;
                form.appendChild(input);
              }
            });

            document.body.appendChild(form);
            form.submit();
          } else {
            window.location.href = actionURL;
          }
        };

        if (
          SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.TATA
        ) {
          const response = results.response;
          const policyId = response.policyId;
          const paymentId = response.paymentId;
          if (TW.SELECTED_QUOTE_DATA.quotationDetail) {
            dispatch(
              TWSlice.actions.BULK_UPDATE({
                ...TW,
                SELECTED_QUOTE_DATA: {
                  ...TW.SELECTED_QUOTE_DATA,
                  quotationDetail: {
                    ...TW.SELECTED_QUOTE_DATA.quotationDetail,
                    premiumDetails: {
                      ...TW.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails,
                      paymentId: paymentId,
                    },
                  },
                },
                create_proposal_response: response,
                policyId: policyId,
              })
            );
          }
          const onSuccessTata = (res: any) => {
            setLoader(false);
            const results = res.results;
            const error = results.error;

            if (error) {
              toast.error("Something went wrong");
            } else {
              const response = results.response;
              const paymentRequest = response["paymentRequest"];
              const actionURL = paymentRequest["actionURL"];
              const actionMethod = paymentRequest["actionMethod"];

              if (actionMethod === "POST") {
                let form = document.createElement("form");
                form.action = actionURL;
                form.method = actionMethod;

                Object.entries(paymentRequest).forEach(([key, value]) => {
                  if (key !== "actionURL" && key !== "actionMethod") {
                    const input = document.createElement("input");
                    input.type = "hidden";
                    input.name = key;
                    input.value = `${value}`;
                    form.appendChild(input);
                  }
                });

                document.body.appendChild(form);
                form.submit();
              } else {
                window.location.href = actionURL;
              }
            }
          };
          setLoader(true);

          let paramTata = TW_CREATE_PROPOSAL_REQUEST(
            TW,
            CKYC,
            ADD_FORM_RESPONSE
          );
          paramTata = {
            ...paramTata,
            create_propoal_response: {
              ...paramTata.create_propoal_response,
              ...response,
            },
          };

          TW_ADD_FORM_SERVICES.PAYMENT(onSuccessTata, onError, paramTata);
        } else if (
          SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
          EMotorCompCode.HDFC
        ) {
          const response = results.response;
          const paymentRequest = response["paymentRequest"];
          const actionURL = paymentRequest["actionURL"];
          const actionMethod = paymentRequest["actionMethod"];

          let form = document.createElement("form");
          form.action = actionURL;
          form.method = actionMethod;

          Object.entries(paymentRequest).forEach(([key, value]) => {
            if (key !== "actionURL" && key !== "actionMethod") {
              const input = document.createElement("input");
              input.type = "hidden";
              input.name = key;
              input.value = `${value}`;
              form.appendChild(input);
            }
          });

          document.body.appendChild(form);
          if (PLAN_TYPE === "Third Party") {
            make_payment(results);
          } else {
            // make_payment(results);

            const ProposalNumber = response?.ProposalNumber;
            const TransactionID = response?.TransactionID;

            if (
              (window.location.hostname === CLIENTS?.POLICYPARIVAAR ||
                window.location.hostname === CLIENTS?.LOCALHOST) &&
              (process.env.REACT_APP_ENV === "DEV" ||
                process.env.REACT_APP_ENV === "STG")
            ) {
              CIS_URL_DATA(ProposalNumber, TransactionID);
            } else {
              make_payment(results);
            }
          }

          setPaymentFormData(results?.response);
          setPaymentForm(form);
        } else {
          make_payment(results);
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      setLoader(false);
    };
    let param = TW_CREATE_PROPOSAL_REQUEST(TW, CKYC, ADD_FORM_RESPONSE);

    setLoader(true);

    if (
      SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
      EMotorCompCode.BAJAJ
    ) {
      navigate(
        `${TW_ROUTES.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=TW&quote_no=${
          ADD_FORM_RESPONSE.quote_no
        }&product_id=${
          SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id
        }&user_type=${GET_QUERY_PARAMETERS(
          "user_type"
        )}&utm_source=${GET_QUERY_PARAMETERS(
          "utm_source"
        )}&utm_medium=${GET_QUERY_PARAMETERS("utm_medium")}`
      );
    } else {
      TW_ADD_FORM_SERVICES.PAYMENT(onSuccess, onError, param);
    }
  };

  useEffect(() => {
    dispatch(TWSlice.actions.CHANGE_PAGE_STATUS(false));
  }, []);

  return (
    <>
      {isMobile ? (
        <MReviewDetails
          PreviewDetails={PreviewDetails}
          updateMasterState={updateMasterState}
          pageStatus={pageStatus}
          loader={loader}
          CREATE_PROPOSAL={CREATE_PROPOSAL}
        />
      ) : (
        <ReviewDetails
          PreviewDetails={PreviewDetails}
          updateMasterState={updateMasterState}
          pageStatus={pageStatus}
          loader={loader}
          CREATE_PROPOSAL={CREATE_PROPOSAL}
          openRedirectPopup={openRedirectPopup}
          setOpenRedirectPopup={setOpenRedirectPopup}
          paymentForm={paymentForm}
          paymentFormData={paymentFormData}
          CIS_DATA={CIS_DATA}
        />
      )}
    </>
  );
};

export default ReviewDetailsContainer;
