import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CareKyc from "../../../Page/Desktop/CKYC/CARE/CareKyc";
import MCareKyc from "../../../Page/Mobile/CKYC/Care/MCareKyc";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { CKYCSlice } from "../../../redux/slice/CKYC/CKYCSlice";
import { CommonDropdownSlice } from "../../../redux/slice/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../redux/slice/Health/HealthSlice";
import { TravelSlice } from "../../../redux/slice/Travel/TravelSlice";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { TRAVEL_ROUTE } from "../../../Router/Path/TravelRoutes";
import { CKYCServcies } from "../../../Services/ckyc-node/CKYCServices";
import {
  isEmpty,
  isValid_Pan_Number,
  validateFullName,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TVerifyCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";

function CareCKYCContainerNode() {
  const [loader, setLoader] = useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const { TW, CKYC, Car, Health, Travel } = useAppSelector((state) => state);
  const { DISCOUNT_FORM } = useAppSelector((state) => state.Car);
  const { SELECTED_QUOTE_DATA, ADD_FORM_RESPONSE } = useAppSelector(
    (state) => state.Health
  );
  const REDUX_HEAD = useAppSelector((state) => state);
  const [pageStatus, setPageStatus] = useState<string>("");

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    if (type) {
      setPageStatus(type);
    }
  }, []);

  const [formDetails, setFormDetails] =
    useState<TVerifyCKYCForm>(VERIFY_KYC_FORM);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "customer_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
        dob: { value: "", warning: false },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  useEffect(() => {
    if (DISCOUNT_FORM.vehicle_owned === "Organization") {
      setFormDetails((prev) => ({
        ...prev,
        customer_type: { value: "Organization", warning: false },
      }));
      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYC,
          VERIFY_KYC_FORM: {
            ...formDetails,
            customer_type: { value: "Organization", warning: false },
          },
        })
      );
    }
  }, [DISCOUNT_FORM]);

  useEffect(() => {
    setFormDetails(VERIFY_KYC_FORM);
  }, [VERIFY_KYC_FORM]);

  useEffect(() => {
    GET_DROPDOWN_MASTER("POV");
  }, []);

  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.data;
      const error = results.error;

      if (!error) {
        const response = results.data;
        const manupulated_data: TDropdown[] = [];
        for (const data of response) {
          manupulated_data.push({
            label: `${data?.document}`,
            value: `${data?.document_code || data?.doc_code}`,
          });
        }
        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: manupulated_data,
          })
        );
      }
    };

    const onError = (error: any) => {};
    const Health_params = {
      company_code: "G025",
      list_for: TYPE,
    };
    CKYCServcies.CKYC_DROPDOWN_MASTER(onError, onSuccess, Health_params);
  };

  const validateForm = () => {
    let data: TVerifyCKYCForm = { ...formDetails };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: { ...data.dob, warning: isEmpty(data.dob.value) },
      doc_type: { ...data.doc_type, warning: isEmpty(data.doc_type.value) },
      doc_id: {
        ...data.doc_id,
        warning:
          isEmpty(data.doc_id.value) ||
          (data.doc_type.value === "PAN" &&
            !isValid_Pan_Number(data.doc_id.value)),
      },
      customer_type: {
        ...data.customer_type,
        warning: isEmpty(data.customer_type.value),
      },
      gender: { ...data.gender, warning: isEmpty(data.gender.value) },
    };
    setFormDetails(data);
    let hasError =
      data.full_name.warning ||
      data.doc_type.warning ||
      data.doc_id.warning ||
      data.customer_type.warning ||
      data.dob.warning;

    if (data.customer_type.value !== "Organization") {
      hasError = hasError || data.gender.warning;
    }

    if (!hasError) {
      VERIFY_CKYC_DETAILS(data);
    }
  };

  const VERIFY_CKYC_DETAILS = (data: TVerifyCKYCForm) => {
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res;
      const error = results.error;
      if (!error) {
        const message = results.message;
        const response: any = results.response;
        if (message === "No record found") {
          // const kycManualURL = response.kycManualURL;
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              //   MANUAL_KYC_THIRD_PARTY_URL: kycManualURL,
            })
          );
          setShowManualPopup(true);
        } else {
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              CKYC_DETAILS: response,
            })
          );
          setShowCKYCDetailsPopup(true);
        }
      } else {
        setShowManualPopup(true);
      }
    };

    const onError = (err: any) => {};
    setLoader(true);

    switch (pageStatus) {
      case "TI":
        let dto_ti: any = {
          quote_no: `${REDUX_HEAD.Travel.ADD_FORM_RESPONSE.quote_no}`,
          cusType: data.customer_type.value,
          product_code: `${REDUX_HEAD.Travel.SELECTED_QUOTE_DATA.quote_details?.productDetails?.product_code}`,
          product_id: `${REDUX_HEAD.Travel.SELECTED_QUOTE_DATA.quote_details?.productDetails.id}`,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type.value,
          docIDNoForKyc: data.doc_id.value,
          section: "premium_details",
        };
        CKYCServcies.VERIFY_CARE_CKYC(onSuccess, onError, dto_ti);
        break;
      case "HP":
        let dto_hp: any = {
          quote_no: `${REDUX_HEAD.Health.ADD_FORM_RESPONSE.quote_no}`,
          cusType: data.customer_type.value,
          product_code: `${REDUX_HEAD.Health.SELECTED_QUOTE_DATA.productDetails.product_code}`,
          product_id: `${REDUX_HEAD.Health.SELECTED_QUOTE_DATA.productDetails.id}`,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type.value,
          docIDNoForKyc: data.doc_id.value,
          section: "premium_details",
        };
        CKYCServcies.VERIFY_CARE_CKYC(onSuccess, onError, dto_hp);
        break;
      default:
        break;
    }
  };

  const CONTINUE_TO_PROPOSAL = () => {
    switch (pageStatus) {
      case "HP":
        const windowURL = window.location.search;
        const params = new URLSearchParams(windowURL);
        const param = params.get("cus_type");
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              PROPOSER_DETAILS: {
                ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                name: {
                  value: CKYC.CKYC_DETAILS.kycCustomerName,
                  warning: false,
                },
                dob: {
                  value: CKYC.CKYC_DETAILS.kycDOB,
                  warning: false,
                },
                mobile: { value: CKYC.CKYC_DETAILS.kycMobile, warning: false },
                emergencyPhone: { value: "", warning: false },
                email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                aadhar: { value: "", warning: false },
                gender: { value: VERIFY_KYC_FORM.gender.value, warning: false },
                maritalStatus: { value: "", warning: false },
                weight: { value: "", warning: false },
                heightFeet: { value: "", warning: false },
                heightInches: { value: "", warning: false },
                occupation: { value: "", warning: false },
                relationship: { value: "", warning: false },
                proposer_name: { value: "", warning: false },
              },
            },
            ADDRESS_DETAILS: {
              ...Health.ADDRESS_DETAILS,
              pincode: { value: CKYC.CKYC_DETAILS.kycPincode, warning: false },
              city: CKYC.CKYC_DETAILS.kycCity,
              state: CKYC.CKYC_DETAILS.kycState,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: CKYC.CKYC_DETAILS.kycAddress2,
                warning: false,
              },
              isMailingAddressSame: true,
              mailingPinCode: { value: "", warning: false },
              mailingCity: "",
              mailingState: "",
              mailingAddressLineOne: { value: "", warning: false },
              mailingAddressLineTwo: { value: "", warning: false },
            },
          })
        );
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
            HEALTH_ROUTE.ADDON_DETAILS
          }?type=HP&companyCode=${
            SELECTED_QUOTE_DATA?.CompanyDetails.company_code
          }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
            SELECTED_QUOTE_DATA?.productDetails.id
          }${param === "user" ? "&user_type=user" : ""}`
        );
        break;
      case "TI":
        dispatch(
          TravelSlice.actions.BULK_UPLOAD({
            ...Travel,
            INSURED_MEMBER_DETAILS: {
              ...Travel.INSURED_MEMBER_DETAILS,
              PROPOSER_DETAILS: {
                ...Travel.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                name: {
                  value: CKYC.CKYC_DETAILS.kycCustomerName,
                  warning: false,
                },
                dob: {
                  value: CKYC.CKYC_DETAILS.kycDOB,
                  warning: false,
                },
                mobile: { value: CKYC.CKYC_DETAILS.kycMobile, warning: false },
                emergencyPhone: { value: "", warning: false },
                email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                aadhar: { value: "", warning: false },
                gender: { value: VERIFY_KYC_FORM.gender.value, warning: false },
                maritalStatus: { value: "", warning: false },
                weight: { value: "", warning: false },
                heightFeet: { value: "", warning: false },
                heightInches: { value: "", warning: false },
                occupation: { value: "", warning: false },
                relationship: { value: "", warning: false },
                proposer_name: { value: "", warning: false },
              },
            },
            ADDRESS_DETAILS: {
              ...Travel.ADDRESS_DETAILS,
              pincode: { value: CKYC.CKYC_DETAILS.kycPincode, warning: false },
              city: CKYC.CKYC_DETAILS.kycCity,
              state: CKYC.CKYC_DETAILS.kycState,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: CKYC.CKYC_DETAILS.kycAddress2,
                warning: false,
              },
              isMailingAddressSame: true,
              mailingPinCode: { value: "", warning: false },
              mailingCity: "",
              mailingState: "",
              mailingAddressLineOne: { value: "", warning: false },
              mailingAddressLineTwo: { value: "", warning: false },
            },
          })
        );
        navigate(
          `${TRAVEL_ROUTE.PROPOSAL_PAGE}/${TRAVEL_ROUTE.ADDON_DETAILS}?type=TI&companyCode=${REDUX_HEAD.Travel.SELECTED_QUOTE_DATA.companyCode}&quote_no=${REDUX_HEAD.Travel.ADD_FORM_RESPONSE.quote_no}&product_id=${REDUX_HEAD.Travel.SELECTED_QUOTE_DATA.productID}`
        );
        break;
      default:
        break;
    }
  };

  return (
    <div>
      {isMobile ? (
        <MCareKyc
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          isLoading={loader}
        />
      ) : (
        <CareKyc
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          isLoading={loader}
          pageStatus={pageStatus}
        />
      )}
    </div>
  );
}

export default CareCKYCContainerNode;
