import { Box, Collapse } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import GoDigitMedicalQuestion from "../../../../Component/MedicalQuestion/GoDigitMedicalQuestion";
import MemberRadioButtonNew from "../../../../Component/MemberRadioButton/MemberRadioButtonNew";
import { TGoDigitMedicalQuestion } from "../../../../types/Health/HealthQuotation/TGodigitMedicalQuestion";
import HealthFooter from "../HealthFooter/HealthFooter";

import React, { useEffect } from "react";
import { useAppSelector } from "../../../../redux/hooks";
const MedicalDetails = ({
  medicalQuestionData,
  updateMasterState,
  validateForm,
  PAGE_STATUS,
  selectedItems,
  handleChange,
}: {
  medicalQuestionData: TGoDigitMedicalQuestion;
  updateMasterState: Function;
  validateForm: Function;
  PAGE_STATUS: boolean;
  selectedItems: any;
  handleChange: Function;
}) => {
  const { INSURED_MEMBER_DETAILS } = useAppSelector((state) => state.Health);

  const findFemaleMember: any = [];
  Object.keys(INSURED_MEMBER_DETAILS).forEach((member: any) => {
    //@ts-ignore
    let meb = INSURED_MEMBER_DETAILS?.[member];
    //@ts-ignore
    if (
      meb.relationship?.value === "daughter" ||
      meb.relationship?.value === "Spouse"
    ) {
      const n = member.toLowerCase().split("_");

      findFemaleMember.push(n[0] + "_" + n[1]);
    }
  });

  return (
    <Box>
      <h5 className="sectionTitle">Underwriting Details</h5>
      <>
        {Object.values(medicalQuestionData).map(
          (data: any, indexMainQuestion: number) => (
            <div key={indexMainQuestion}>
              <GoDigitMedicalQuestion
                key={indexMainQuestion}
                main_class="medicalQuestion borderBottom"
                icon_class={data?.main_question?.icon_class}
                question_name={data?.main_question?.title}
                sub_details={data?.main_question?.description}
                input_id_first={data?.main_question?.id}
                input_id_second={data?.main_question?.id}
                input_name="diagnosed"
                toggle_button_status={data?.main_question?.status}
                attrName={["question", data?.main_question?.id]}
                value_update={updateMasterState}
                data={data}
                handleChange={handleChange}
                selectedItems={selectedItems}
                updateMasterState={updateMasterState}
                select_disease={
                  <Collapse
                    in={data?.main_question?.status}
                    className="medicalQuestion-SubSection"
                  >
                    <Grid container spacing={3} className="pr-2 mb-3">
                      {data?.main_question?.sub_question && (
                        <Grid xs={12}>
                          {/* <h5>High or low blood pressure</h5> */}
                          {data?.main_question?.sub_question &&
                            Object.values(
                              data?.main_question?.sub_question
                            )?.map(
                              (
                                dataSubQuestion: any,
                                indexSubQuestion: number
                              ) => (
                                <>
                                  <GoDigitMedicalQuestion
                                    handleChange={handleChange}
                                    selectedItems={selectedItems}
                                    key={indexSubQuestion}
                                    main_class="medicalQuestion borderBottom"
                                    icon_class={"tobaco"}
                                    question_name={dataSubQuestion?.title}
                                    sub_details={
                                      <p>{dataSubQuestion?.description}</p>
                                    }
                                    input_id_first={dataSubQuestion?.id}
                                    input_id_second={dataSubQuestion?.id}
                                    input_name="blood pressure"
                                    toggle_button_status={
                                      dataSubQuestion?.status
                                    }
                                    attrName={[
                                      "subQuestion",
                                      data?.main_question?.id,
                                      dataSubQuestion?.id,
                                    ]}
                                    value_update={updateMasterState}
                                    data={data}
                                    dataSubQuestion={dataSubQuestion}
                                    updateMasterState={updateMasterState}
                                    select_disease={
                                      <Collapse
                                        in={dataSubQuestion?.status}
                                        className="medicalQuestion-SubSection"
                                      >
                                        <Grid container spacing={2}>
                                          {dataSubQuestion?.member_data &&
                                            dataSubQuestion?.status &&
                                            Object.values(
                                              dataSubQuestion?.member_data
                                            )?.map(
                                              (
                                                memberDataValue: any,
                                                memberIndex: number
                                              ) => (
                                                <React.Fragment
                                                  key={memberIndex}
                                                >
                                                  {memberDataValue.name !==
                                                    "" && (
                                                    <Grid xs={4}>
                                                      {data?.main_question
                                                        ?.id === "MQ02" &&
                                                      dataSubQuestion?.id ===
                                                        "MQ6-SQ6" ? (
                                                        findFemaleMember.includes(
                                                          memberDataValue.keyName
                                                        ) ? (
                                                          <MemberRadioButtonNew
                                                            class_name={
                                                              memberIndex ===
                                                                0 ||
                                                              memberIndex === 1
                                                                ? "adult"
                                                                : "child"
                                                            }
                                                            checked={
                                                              memberDataValue?.selection_status
                                                            }
                                                            label_name={
                                                              memberDataValue.name
                                                            }
                                                            attrName={[
                                                              "mainMember",
                                                              data
                                                                ?.main_question
                                                                ?.id,
                                                              dataSubQuestion.id,
                                                              memberDataValue?.keyName,
                                                            ]}
                                                            value_update={
                                                              updateMasterState
                                                            }
                                                          />
                                                        ) : (
                                                          ""
                                                        )
                                                      ) : (
                                                        <MemberRadioButtonNew
                                                          class_name={
                                                            memberIndex === 0 ||
                                                            memberIndex === 1
                                                              ? "adult"
                                                              : "child"
                                                          }
                                                          checked={
                                                            memberDataValue?.selection_status
                                                          }
                                                          label_name={
                                                            memberDataValue.name
                                                          }
                                                          attrName={[
                                                            "mainMember",
                                                            data?.main_question
                                                              ?.id,
                                                            dataSubQuestion.id,
                                                            memberDataValue?.keyName,
                                                          ]}
                                                          value_update={
                                                            updateMasterState
                                                          }
                                                        />
                                                      )}
                                                      {memberDataValue?.selection_status &&
                                                        memberDataValue.keyName && (
                                                          <>
                                                            {Object.values(
                                                              memberDataValue.field_data
                                                            ).map(
                                                              (
                                                                field_data_data: any
                                                              ) => {
                                                                let datePickerRendered =
                                                                  false; // Flag to track DatePicker rendering
                                                                return (
                                                                  <React.Fragment
                                                                    key={
                                                                      field_data_data.id
                                                                    }
                                                                  >
                                                                    {field_data_data.field_type ===
                                                                    "DROPDOWN" ? (
                                                                      <SelectDropdown
                                                                        class_name=" inputField mb-5"
                                                                        title={
                                                                          field_data_data.title
                                                                        }
                                                                        attrName={[
                                                                          "mainMemberDataField",
                                                                          data
                                                                            ?.main_question
                                                                            ?.id,
                                                                          memberDataValue.keyName,
                                                                          field_data_data.id,
                                                                          dataSubQuestion.id,
                                                                        ]}
                                                                        value={
                                                                          field_data_data
                                                                            ?.value
                                                                            ?.value
                                                                        }
                                                                        value_update={
                                                                          updateMasterState
                                                                        }
                                                                        warn_status={
                                                                          field_data_data
                                                                            ?.value
                                                                            ?.warning
                                                                        }
                                                                        data={
                                                                          field_data_data.data_value
                                                                        }
                                                                        error_message={
                                                                          "Enter description"
                                                                        }
                                                                      />
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                    {field_data_data.field_type ===
                                                                    "TEXTFIELD" ? (
                                                                      <RKTextField
                                                                        class_name=" inputField mb-5"
                                                                        max_length={
                                                                          100
                                                                        }
                                                                        title={
                                                                          field_data_data.title
                                                                        }
                                                                        attrName={[
                                                                          "mainMemberDataField",
                                                                          data
                                                                            ?.main_question
                                                                            ?.id,
                                                                          memberDataValue.keyName,
                                                                          field_data_data.id,
                                                                          dataSubQuestion.id,
                                                                        ]}
                                                                        value={
                                                                          field_data_data
                                                                            ?.value
                                                                            ?.value
                                                                        }
                                                                        value_update={
                                                                          updateMasterState
                                                                        }
                                                                        warn_status={
                                                                          field_data_data
                                                                            ?.value
                                                                            ?.warning
                                                                        }
                                                                        error_message={
                                                                          "Enter description"
                                                                        }
                                                                      />
                                                                    ) : (
                                                                      ""
                                                                    )}
                                                                  </React.Fragment>
                                                                );
                                                              }
                                                            )}
                                                          </>
                                                        )}
                                                    </Grid>
                                                  )}
                                                </React.Fragment>
                                              )
                                            )}
                                        </Grid>
                                      </Collapse>
                                    }
                                  />
                                </>
                              )
                            )}
                        </Grid>
                      )}
                    </Grid>
                  </Collapse>
                }
              />
            </div>
          )
        )}
      </>
      <HealthFooter
        textName={PAGE_STATUS ? "Update" : "Continue"}
        forward={() => validateForm()}
      />
    </Box>
  );
};

export default MedicalDetails;
