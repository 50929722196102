import { Box } from "@mui/material";
import React from "react";
import "./MemberRadioButton.scss";

interface Props {
  label_name: any;
  class_name: string | undefined;
  value_update: Function;
  attrName: any;
  checked?: boolean;
}

const MemberRadioButtonNew: React.FC<Props> = ({
  class_name,
  label_name,
  value_update,
  attrName,
  checked,
}) => {
  return (
    <Box
      className="insuredMembers mb-4"
      onClick={() => value_update(attrName, !checked)}
    >
      <input checked={checked} type={"checkbox"} />
      <label className={class_name}>{label_name}</label>
    </Box>
  );
};

export default MemberRadioButtonNew;
