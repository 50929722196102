import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import NivaBupaBankDetailsContainer from "../../../../../Container/BankDetails/NivaBupa/NivaBupaBankDetailsContainer";
import NIVABUPACKYCContainerPHP from "../../../../../Container/CKYC/Niva_bupa/NIVABUPACKYCContainer";
import NivaBupaBankDetailsContainerNode from "../../../../../Container/bank-details-node/NivaBupa/NivaBupaBankDetailsContainer";
import NIVABUPACKYCContainer from "../../../../../Container/ckyc-node/niva-bupa/NIVABUPACKYCContainer";
import AddonDetailsCOntainerNIVABUPA from "../../../../../Container/health-node/NIVA_BUPA/AddonDetailsCOntainerNIVABUPA";
import MedicalDetailContainerNivaBupa from "../../../../../Container/health-node/NIVA_BUPA/MedicalDetailContainerNivaBupa";
import AddonDetailsCOntainerNIVABUPAPHP from "../../../../../Container/health-php/NIVA_BUPA/AddonDetailsCOntainerNIVABUPA";
import MedicalDetailContainerNivaBupaPHP from "../../../../../Container/health-php/NIVA_BUPA/MedicalDetailContainerNivaBupa";
import { isHealthNode } from "../../../../../SupportingFiles/HelpingFunction";
import { CLIENTS } from "../../../../../URLCollection/Clients";
import { useAppSelector } from "../../../../../redux/hooks";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";

const NIVABIPA_ROUTES = () => {
  const { id } = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA.productDetails
  );
  const [productCode, setProductCode] = useState<string>("");
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const product_code = searchParams.get("product_code");
    setProductCode(`${product_code}`);
  }, []);

  // const isNode = isHealthNode(id) || isHealthNode(productCode);
  const isNode = false;
  const reAssure = [2592, 2593, 2594];
  const [isReAssure, setIsReAssure] = useState(true);

  // useEffect(() => {
  //   // Check if the node is part of reAssure and if the hostname matches
  //   const checkIsReAssure =
  //     reAssure.some((node) => node === id) &&
  //     window.location.hostname === CLIENTS.GROWWINSURANCE;
  //   setIsReAssure(checkIsReAssure);
  // }, [id]);

  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        {/* {isReAssure ? ( */}
        <Route path={CKYC_ROUTES.CKYC} element={<NIVABUPACKYCContainerPHP />} />
        {/* ) : isNode ? (
          <Route path={CKYC_ROUTES.CKYC} element={<NIVABUPACKYCContainer />} />
        ) : (
          <Route
            path={CKYC_ROUTES.CKYC}
            element={<NIVABUPACKYCContainerPHP />}
          />
        )} */}
        {/* {isReAssure ? ( */}
        <Route
          path={HEALTH_ROUTE.ADDON_DETAILS}
          element={<AddonDetailsCOntainerNIVABUPAPHP />}
        />
        {/* ) : isNode ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsCOntainerNIVABUPA />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsCOntainerNIVABUPAPHP />}
          />
        )} */}
        {/* {isReAssure ? ( */}
        <Route
          path={HEALTH_ROUTE.MEDICAL_DETAILS}
          element={<MedicalDetailContainerNivaBupaPHP />}
        />
        {/* ) : isNode ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailContainerNivaBupa />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailContainerNivaBupaPHP />}
          />
        )} */}
        {/* {isReAssure ? ( */}
        <Route
          path={HEALTH_ROUTE.BANK_DETAILS}
          element={<NivaBupaBankDetailsContainer />}
        />
        {/* ) : isNode ? (
          <Route
            path={HEALTH_ROUTE.BANK_DETAILS}
            element={<NivaBupaBankDetailsContainerNode />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.BANK_DETAILS}
            element={<NivaBupaBankDetailsContainer />}
          />
        )} */}
      </Route>
    </Routes>
  );
};

export default NIVABIPA_ROUTES;
