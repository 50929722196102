import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EMotorCompCode } from "../../../Enum/EMotorCompCode";
import VehicleDetails from "../../../Page/Desktop/TW/Common/VehicleDetails";
import MVehicleDetails from "../../../Page/Mobile/TW/Common/MVehicleDetails";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { TW_ADD_FORM_SERVICES } from "../../../Services/TW/TWAddFormServices";
import { TW_SERVICES } from "../../../Services/TW/TWServices";
import { TWO_WHEELER_SERVICES } from "../../../Services/TW/TwoWheelerServices";
import { TWSlice } from "../../../redux/slice/TwoWheeler/TWSlice";
import { useAppSelector } from "../../../redux/hooks";
import {
  isEmpty,
  validateChassisNo,
  validateEngineNo,
  validatePAN,
  validatePrevPolicyNo,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TWPremiumCal } from "../../../SupportingFiles/TWHelper";
import { CLIENTS } from "../../../URLCollection/Clients";
import { TVehicleDetails } from "../../../types/TTWSlice";
import { TTWPremiumDetails } from "../../../types/TwoWheeler/TTWSingleQuoteDetail";
import {
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";

const VehicalDetailsContainer = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const dispatch = useDispatch();
  const { TW, CKYC } = useAppSelector((state) => state);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const [recalculatedPremium, setRecalculatedPremium] =
    useState<TTWPremiumDetails>({
      BasicODwithOutZeroDep: 0,
      netPremiumWithoutAddons: 0,
      paymentId: "",
      RSQUOTEID: "",
      UniqueRequestID: "",
      withAddonnBasicOD: 0,
      withoutAddonnBasicOD: 0,
      withAddonNetPremium: 0,
      withoutAddonNetPremium: 0,
      pTransactionId: "",
      correlationId: "",
      exshowroomPrice: "",
      minimumIDV: 0,
      maximumIDV: 0,
      IDV: 0,
      basicOD: 0,
      basicTP: 0,
      netPremium: 0,
      serviceTax: 0,
      ncbDiscount: 0,
      depreciationCover: 0,
      paOwnerDriver: 0,
      odDiscount: 0,
      driverCover: 0,
      secondYear: null,
      thirdYear: null,
      product_code: "",
      company_code: "",
      roadSideAssistance: 0,
      includedPAOwnerDriver: 0,
      freeRoadSideAssistance: 0,
      totalTPPremium: "",
    });
  const {
    VEHICLE_DETAILS,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    ADDON_STATUS,
    PAGE_STATUS,
    PLAN_TYPE,
    REDIRECTED,
    OWNER_DETAILS,
  } = useAppSelector((state) => state.TW);
  const [pageStatus, setPageStatus] = useState<number>(1);
  const [policyRevisionPopup, setPolicyRevisionPopup] =
    useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [vehicleDetailFields, setVehicleDetailFields] =
    useState<TVehicleDetails>({
      ...VEHICLE_DETAILS,
      PAN: {
        value: validatePAN(VERIFY_KYC_FORM.doc_id.value)
          ? VERIFY_KYC_FORM.doc_id.value
          : VEHICLE_DETAILS.PAN.value,
        warning: false,
      },
    });
  const [bankdata, setBankdata] = useState<string>("");
  const [value, setValue] = useState<string>("");
  function useDebounce(text: string, delay: number) {
    useEffect(() => {
      const timerId = setTimeout(() => {
        setValue(text);
      }, delay);
      return () => {
        clearTimeout(timerId);
      };
    }, [text, delay]);
    return value;
  }

  const debouncedBankdata = useDebounce(bankdata, 500);
  useEffect(() => {
    VEHICLE_ON_LOANS(debouncedBankdata);
  }, [debouncedBankdata]);

  const VEHICLE_ON_LOANS = (bankdata: any) => {
    const onSuccess = (res: any) => {
      let data = Object.values(res.results.response).map((item: any) => ({
        label: item,
        value: item,
      }));

      dispatch(
        TWSlice.actions.SET_DROPDOWN_DATA({
          key: "VEHICLE_ON_LOAN",
          value: data,
        })
      );
    };
    const onError = (err: any) => {
      console.log("error", err);
    };
    let param: any = {
      product_code:
        SELECTED_QUOTE_DATA.quotationDetail?.productDetails.product_code,
      term: bankdata,
    };
    if (bankdata.length >= 3) {
      TW_ADD_FORM_SERVICES.VEHICLE_ON_LOAN(onSuccess, onError, param);
    }
  };

  const handleClick = () => {
    setVehicleDetailFields((prev) => ({
      ...prev,
      is_vehicle_on_loan: !prev.is_vehicle_on_loan,
      bank_name: { value: "", warning: false },
      city: { value: "", warning: false },
    }));
  };

  useEffect(() => {
    GET_PREVIOUS_INSURER_LISTS();
  }, []);

  const GET_PREVIOUS_INSURER_LISTS = () => {
    const onSuccess = (res: any) => {
      let data = [];
      data = Object.values(res.results.response).map((item: any) => ({
        label: item,
        value: item,
      }));
      if (VEHICLE_DETAILS.prev_insurer.value === "Do not remember") {
        data.push({ label: "Do not remember", value: "Do not remember" });
      }
      dispatch(
        TWSlice.actions.BULK_UPDATE({
          ...TW,
          DROPDOWN_DATA: {
            ...TW.DROPDOWN_DATA,
            PREVIOUS_INSURER_LIST: [...data],
          },
        })
      );
    };
    const onError = (res: any) => {};
    let parms: any = {
      product_code: SELECTED_QUOTE_DATA.product_code,
    };
    TWO_WHEELER_SERVICES.getPreviousInsurerList(onSuccess, onError, parms);
  };

  const validateForm = () => {
    if (
      SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
      EMotorCompCode.TATA
    ) {
      if (ADDON_STATUS.zero_depreciation.value) {
        if (!VEHICLE_DETAILS.prev_depth_cover) {
          toast.error(
            "You don't have Zero Depreciation coverage in your previous policy, so you cannot continue with this insurer."
          );
          return;
        }
      }
    }

    let data = { ...vehicleDetailFields };

    data = {
      ...data,
      chasis_no: {
        ...data.chasis_no,
        warning: validateChassisNo(
          data.chasis_no.value,
          `${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
          TW.ADD_FORM.business_type
        ),
      },
      engine_no: {
        ...data.engine_no,
        warning: validateEngineNo(data.engine_no.value),
      },
      prev_policy_no: {
        ...data.prev_policy_no,
        warning: validatePrevPolicyNo(data.prev_policy_no.value),
      },
      prev_insurer: {
        ...data.prev_insurer,
        warning: isEmpty(data.prev_insurer.value),
      },
      mfg_year: {
        ...data.mfg_year,
        warning: isEmpty(data.mfg_year.value),
      },
      reg_date: {
        ...data.reg_date,
        warning: isEmpty(data.reg_date.value),
      },
      tp_insurer: {
        ...data.tp_insurer,
        warning: isEmpty(data.tp_insurer.value),
      },
      tp_policy: {
        ...data.tp_policy,
        warning: validatePrevPolicyNo(data.tp_policy.value),
      },
      tp_policy_start: {
        ...data.tp_policy_start,
        warning: isEmpty(data.tp_policy_start.value),
      },
    };

    if (data.policy_exp_date.value !== "Do not remember") {
      data = {
        ...data,
        policy_exp_date: {
          ...data.policy_exp_date,
          warning: isEmpty(data.policy_exp_date.value),
        },
      };
    }

    const premium_details = SELECTED_QUOTE_DATA.quotationDetail;
    const premium = TWPremiumCal(
      premium_details,
      ADDON_STATUS,
      VERIFY_KYC_FORM.customer_type.value
    );
    if (
      SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code ===
      EMotorCompCode.ACKO
    ) {
      if (premium >= 100000) {
        data = {
          ...data,
          PAN: { ...data.PAN, warning: !validatePAN(data.PAN.value) },
        };
      } else {
        data = { ...data, PAN: { ...data.PAN, warning: false } };
      }
    } else {
      if (premium >= 50000) {
        data = {
          ...data,
          PAN: { ...data.PAN, warning: !validatePAN(data.PAN.value) },
        };
      } else {
        data = { ...data, PAN: { ...data.PAN, warning: false } };
      }
    }

    let hasError =
      data.chasis_no?.warning ||
      data.engine_no?.warning ||
      data.mfg_year?.warning;

    if (PLAN_TYPE !== "Bundle Policy") {
      hasError =
        hasError ||
        data.prev_policy_no?.warning ||
        data.prev_insurer?.warning ||
        data.mfg_year?.warning ||
        data.reg_date?.warning ||
        data.policy_exp_date?.warning;
    }

    if (PLAN_TYPE === "SAOD") {
      hasError =
        hasError ||
        data.tp_policy.warning ||
        data.tp_insurer.warning ||
        data.tp_policy_start.warning ||
        data.policy_exp_date?.warning;
    }
    if (
      TWPremiumCal(
        premium_details,
        ADDON_STATUS,
        VERIFY_KYC_FORM.customer_type.value
      ) >= 50000 ||
      !isEmpty(data.PAN.value)
    ) {
      hasError = hasError || data.PAN.warning;
    }

    if (data.is_vehicle_on_loan) {
      data = {
        ...data,
        bank_name: {
          ...data.bank_name,
          warning: isEmpty(data.bank_name.value),
        },
        city: {
          ...data.city,
          warning: isEmpty(data.city.value),
        },
      };
      hasError = hasError || data.bank_name.warning || data.city.warning;
    }

    setVehicleDetailFields(data);
    if (!hasError) {
      if (window.location.hostname === CLIENTS.JIO) {
        pushMotorDataLayer(
          "jioinsure_flow_details",
          "vehicle",
          "proceed",
          "two wheeler",
          REDIRECTED ? "JFS app" : "Insure website"
        );
        const eventProps = {
          action: "vehicle",
          click: "continue",
          type: "two wheeler",
          EntrySource: REDIRECTED ? "JFS app" : "Insure website",
        };
        sendEventToNativeApp("jioinsure_flow_details", eventProps);
      }
      setisLoading(true);
      dispatch(
        TWSlice.actions.BULK_UPDATE({
          ...TW,
          QUOTE_LOADER: true,
          VEHICLE_DETAILS: { ...data },
        })
      );
      updateRecords();
    }
  };

  const updateRecords = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (error === false) {
        if (
          (SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code !==
            EMotorCompCode.BAJAJ &&
            SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code !==
              EMotorCompCode.HDFC &&
            PLAN_TYPE === "Bundle Policy") ||
          (SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code !==
            EMotorCompCode.BAJAJ &&
            SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code !==
              EMotorCompCode.HDFC &&
            PLAN_TYPE === "Third Party")
        ) {
          dispatch(TWSlice.actions.UPDATE_QUOTE_LOADER(false));
          if (PAGE_STATUS) {
            navigate(
              `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}&user_type=${userType}&utm_medium=${utm_medium}&utm_source=${utm_source}`
            );
          } else {
            if (CKYC.VERIFY_KYC_FORM.customer_type.value === "Organization") {
              navigate(
                `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.ADDRESS_DETAILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}&user_type=${userType}&utm_medium=${utm_medium}&utm_source=${utm_source}`
              );
            } else {
              navigate(
                `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.NOMINEE_DETAILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}&user_type=${userType}&utm_medium=${utm_medium}&utm_source=${utm_source}`
              );
            }
          }
        } else {
          Premium_Recalculation();
        }
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    let param: any = {
      prev_depth_cover: VEHICLE_DETAILS.prev_depth_cover ? "Yes" : "No",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      chassisNumber: vehicleDetailFields.chasis_no.value,
      engineNumber: vehicleDetailFields.engine_no.value,
      existingPolicyNo: vehicleDetailFields.prev_policy_no.value,
      preInsAddress: vehicleDetailFields.prev_insurer.value,
      isFinanced: vehicleDetailFields?.is_vehicle_on_loan ? "1" : "0",
      financierName: vehicleDetailFields.bank_name.value,
      financierCity: vehicleDetailFields.city.value,
      manufacture_date: vehicleDetailFields.mfg_year.value,
      policy_expiry_date: vehicleDetailFields.policy_exp_date.value,
      registration_date: vehicleDetailFields.reg_date.value,
      panNumber: validatePAN(VERIFY_KYC_FORM.doc_id.value)
        ? VERIFY_KYC_FORM.doc_id.value
        : vehicleDetailFields.PAN.value,
      ownership_changed: vehicleDetailFields.IS_OWNERSHIP_CHANGED
        ? "Yes"
        : "No",
      section: "coverage_details",
      insurance_type: "TW",
      pincode: OWNER_DETAILS?.pincode?.value,
    };
    if (
      !vehicleDetailFields.tp_policy.warning &&
      !vehicleDetailFields.tp_insurer.warning &&
      !vehicleDetailFields.tp_policy_start.warning
    ) {
      param = {
        ...param,
        tpExistingPolicyNo: vehicleDetailFields.tp_policy.value,
        tp_policy_start_date: vehicleDetailFields.tp_policy_start.value,
        tp_previous_insurer: vehicleDetailFields.tp_insurer.value,
      };
    }
    TW_SERVICES.CAPTURE_RECORD_ON_EVERY_STEP(onSuccess, onError, param);
  };

  const fieldsUpdateState = (attrName: any, value: any) => {
    let data = vehicleDetailFields;
    if (attrName[0] === "IS_OWNERSHIP_CHANGED") {
      data = {
        ...data,
        IS_OWNERSHIP_CHANGED: value,
      };
    } else if (attrName[0] === "chasis_no") {
      data = {
        ...data,
        chasis_no: {
          value: value,
          warning: validateChassisNo(
            value,
            `${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
            TW.ADD_FORM.business_type
          ),
        },
      };
    } else if (attrName[0] === "engine_no") {
      data = {
        ...data,
        engine_no: {
          value: value,
          warning: validateEngineNo(value),
        },
      };
    } else if (attrName[0] === "prev_policy_no") {
      data = {
        ...data,
        prev_policy_no: {
          value: value,
          warning: validatePrevPolicyNo(value),
        },
      };
    } else if (attrName[0] === "prev_insurer") {
      if (data.prev_insurer.value === "Do not remember") {
        data = {
          ...data,
          policy_exp_date: {
            value: "",
            warning: false,
          },
        };
      }
      data = {
        ...data,
        prev_insurer: {
          ...data.prev_insurer,
          value: value,
          warning: isEmpty(value),
        },
      };
    } else if (attrName[0] === "mfg_year") {
      data = {
        ...data,
        mfg_year: { ...data.mfg_year, value: value, warning: isEmpty(value) },
      };
    } else if (attrName[0] === "PAN") {
      data = {
        ...data,
        PAN: { ...data.PAN, value: value, warning: !validatePAN(value) },
      };
    } else if (attrName[0] === "reg_date") {
      data = {
        ...data,
        reg_date: { ...data.reg_date, value: value, warning: isEmpty(value) },
        mfg_year: { ...data.mfg_year, value: "", warning: isEmpty(value) },
        tp_policy_start: {
          ...data.tp_policy_start,
          value: "",
          warning: isEmpty(value),
        },
      };
    } else if (attrName[0] === "invoice_date") {
      data = {
        ...data,
        invoice_date: { value: value, warning: isEmpty(value) },
      };
    } else if (attrName[0] === "policy_exp_date") {
      data = {
        ...data,
        policy_exp_date: {
          ...data.policy_exp_date,
          value: value,
          warning: isEmpty(value),
        },
      };
    } else if (attrName[0] === "city") {
      data = {
        ...data,
        city: { ...data.city, value: value, warning: isEmpty(value) },
      };
    } else if (attrName[0] === "bank_name") {
      data = {
        ...data,
        bank_name: { ...data.bank_name, value: value, warning: isEmpty(value) },
      };
    } else if (attrName[0] === "tp_policy") {
      data = {
        ...data,
        tp_policy: {
          ...data.tp_policy,
          value: value,
          warning: validatePrevPolicyNo(value),
        },
      };
    } else if (attrName[0] === "tp_insurer") {
      data = {
        ...data,
        tp_insurer: {
          ...data.tp_insurer,
          value: value,
          warning: isEmpty(value),
        },
      };
    } else if (attrName[0] === "tp_policy_start") {
      data = {
        ...data,
        tp_policy_start: {
          ...data.tp_policy_start,
          value: value,
          warning: isEmpty(value),
        },
      };
    }

    setVehicleDetailFields(data);
  };

  const parts = ADD_FORM_RESPONSE.policy_expiry_date.split("-");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Subtract 1 since months are zero-based (0-11)
  const year = parseInt(parts[2], 10);

  // Create a new Date object with the extracted components
  const policyExpiryDate = new Date(year, month, day);
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "pageStatus") {
      validateForm();
      setPageStatus(value);
    } else if (attrName === "backbutton") {
      navigate(-1);
    }
  };

  const Premium_Recalculation = () => {
    const onSuccess = (res: any) => {
      setisLoading(false);
      const results = res.results;
      const error = results.error;
      dispatch(TWSlice.actions.UPDATE_QUOTE_LOADER(false));
      if (error === false) {
        const response: TTWPremiumDetails = results.response.premiumDetails;
        if (SELECTED_QUOTE_DATA.quotationDetail && response) {
          const new_premium = TWPremiumCal(
            SELECTED_QUOTE_DATA.quotationDetail,
            ADDON_STATUS,
            VERIFY_KYC_FORM.customer_type.value
          );
          const old_premium = TWPremiumCal(
            {
              ...SELECTED_QUOTE_DATA.quotationDetail,
              premiumDetails: response,
            },
            ADDON_STATUS,
            VERIFY_KYC_FORM.customer_type.value
          );
          if (
            Math.abs(Number(`${new_premium}`) - Number(`${old_premium}`)) <= 2
          ) {
            dispatch(
              TWSlice.actions.SET_SELECTED_QUOTE_DATA({
                ...SELECTED_QUOTE_DATA,
                quotationDetail: {
                  ...SELECTED_QUOTE_DATA.quotationDetail,
                  premiumDetails: {
                    ...response,
                  },
                },
              })
            );
            if (PAGE_STATUS) {
              navigate(
                `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.PREVIEW_DEATILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}&user_type=${userType}&utm_medium=${utm_medium}&utm_source=${utm_source}`
              );
            } else {
              if (CKYC.VERIFY_KYC_FORM.customer_type.value === "Organization") {
                navigate(
                  `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.ADDRESS_DETAILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}&user_type=${userType}&utm_medium=${utm_medium}&utm_source=${utm_source}`
                );
              } else {
                navigate(
                  `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.NOMINEE_DETAILS}?type=TW&companyCode=${SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}&user_type=${userType}&utm_medium=${utm_medium}&utm_source=${utm_source}`
                );
              }
            }
          } else {
            setPolicyRevisionPopup(true);
            setRecalculatedPremium(response);
          }
        }
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };
    let param: any = {
      product_code:
        SELECTED_QUOTE_DATA.quotationDetail?.productDetails.product_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      company_code:
        SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code,
    };
    setTimeout(() => {
      TW_SERVICES.Premium_Recalculation(onSuccess, onError, param);
    }, 100);
  };

  return (
    <>
      {isMobile ? (
        <MVehicleDetails
          setBankdata={setBankdata}
          vehicleDetailFields={vehicleDetailFields}
          handleClick={handleClick}
          fieldsUpdateState={fieldsUpdateState}
          updateMasterState={updateMasterState}
          setVehicleDetailFields={setVehicleDetailFields}
          pageStatus={pageStatus}
          policyRevisionPopup={policyRevisionPopup}
          setPolicyRevisionPopup={setPolicyRevisionPopup}
          isLoading={isLoading}
          validateForm={validateForm}
          recalculatedPremium={recalculatedPremium}
        />
      ) : TW.DROPDOWN_DATA.PREVIOUS_INSURER_LIST.length > 0 ? (
        <VehicleDetails
          vehicleDetailFields={vehicleDetailFields}
          handleClick={handleClick}
          fieldsUpdateState={fieldsUpdateState}
          updateMasterState={updateMasterState}
          setVehicleDetailFields={setVehicleDetailFields}
          policyExpiryDate={policyExpiryDate}
          pageStatus={pageStatus}
          policyRevisionPopup={policyRevisionPopup}
          setPolicyRevisionPopup={setPolicyRevisionPopup}
          isLoading={isLoading}
          setBankdata={setBankdata}
          recalculatedPremium={recalculatedPremium}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default VehicalDetailsContainer;
