import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import Navbar from "../../../Component/Navbar/Navbar";
import MNavbar from "../../../Component/MNavbar/MNavbar";
import HealthLeftSidebar from "../../../Component/HealthLeftSidebar/HealthLeftSidebar";
import LinearProgress from '@mui/material/LinearProgress';
import { HEALTH_ROUTE } from "../../Path/HealthRoutes";
import { CLIENTS } from "../../../URLCollection/Clients";

function HealthProposalContainer() {
  const isMobile = useIsMobile();

  const location = useLocation();
  const [path, setPath] = useState<string>("");
  const [step, setStep] = useState<number>(3);
  const isAmaze = CLIENTS.AMAZE === window.location.hostname;

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes(HEALTH_ROUTE.ADDON_DETAILS)) {
      setStep(4);
    } else if (
      [HEALTH_ROUTE.INSURED_MEMBER, HEALTH_ROUTE.ADULT_ONE_DETAILS, HEALTH_ROUTE.ADULT_TWO_DETAILS,
      HEALTH_ROUTE.CHILD_ONE_DETAILS, HEALTH_ROUTE.CHILD_TWO_DETAILS, HEALTH_ROUTE.CHILD_THREE_DETAILS,
      HEALTH_ROUTE.CHILD_FOUR_DETAILS].some(route => currentPath.includes(route))
    ) {
      setStep(5);
    } else if (currentPath.includes(HEALTH_ROUTE.ADDRESS_DETAILS)) {
      setStep(6);
    } else if (currentPath.includes(HEALTH_ROUTE.MEDICAL_DETAILS)) {
      setStep(7);
    } else if (currentPath.includes(HEALTH_ROUTE.PREVIEW_DETAILS)) {
      setStep(8);
    }

    setPath(currentPath);
  }, [location.pathname]);



  const progressValue = (step / 8) * 100;
  return (
    <Box className={`${isMobile ? "proposalWrapper_m" : "proposalWrapper"}`}>
      <>{isMobile ? <MNavbar /> : <Navbar />}</>
      {/* Progress Bar */}
      {isAmaze && <Box sx={{
        width: '100%',
        position: 'fixed',
        top: isMobile ? "42px" : "65px",
        zIndex: 1000,
        backgroundColor: 'white'
      }}>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          sx={{
            height: '4px',
            backgroundColor: '#00b58721',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#00B587',
              transition: 'transform 0.3s ease-out'
            }
          }}
        />
      </Box>}
      <Grid container spacing={3} className="pb-0">
        {!isMobile && <HealthLeftSidebar />}
        <Grid xs className="proposalContentSection">
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}

export default HealthProposalContainer;
