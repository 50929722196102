import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../../../../Component/Navbar/Navbar";
import MTermNavbar from "../../../../Component/Term/MTermNavbar/MTermNavbar";
import TermLeftSidebar from "../../../../Component/Term/TermLeftSidebar/TermLeftSidebar";
import ProposerDetailsContainer from "../../../../Container/Term/MaxLife/ProposerDetailsContainer";
import RidersBenefitsContainer from "../../../../Container/Term/MaxLife/RidersBenefitsContainer";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TERM_ROUTES } from "../../../Path/TermRoutes";
import "./../../../../SCSS/CommonSCSS/MProposalForms.scss";
import { useAppSelector } from "../../../../redux/hooks";
import RiderBenefitSTEPContainer from "../../../../Container/Term/MaxLife/RiderBenefitSTEPContainer";
import { useEffect, useState } from "react";
import { CLIENTS } from "../../../../URLCollection/Clients";
import LinearProgress from '@mui/material/LinearProgress';

function MaxLifeRoutes() {
  const isMobile = useIsMobile();

  const MAX_LIFE_ROUTES = () => {
    const { SELECTED_QUOTE_DATA } = useAppSelector((state: any) => state.Term);
    return (
      <Routes>
        {SELECTED_QUOTE_DATA.productDetails.product_code === "LTM052" ? (
          <Route
            path={TERM_ROUTES.RIDERS_BENEFITS}
            element={<RiderBenefitSTEPContainer />}
          />
        ) : (
          <Route
            path={TERM_ROUTES.RIDERS_BENEFITS}
            element={<RidersBenefitsContainer />}
          />
        )}
        <Route
          path={TERM_ROUTES.BASIC_DETAILS}
          element={<ProposerDetailsContainer />}
        />
      </Routes>
    );
  };

  const [step, setStep] = useState<number>(3);
  const location = useLocation();
  const isAmaze = CLIENTS.AMAZE === window.location.hostname;



  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes(TERM_ROUTES.BASIC_DETAILS)) {
      setStep(3);
    } else if (currentPath.includes(TERM_ROUTES.RIDERS_BENEFITS)
    ) {
      setStep(4);
    }

  }, [location.pathname]);
  const progressValue = (step / 4) * 100;

  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapper_m">
          <MTermNavbar />
          {isAmaze && <Box sx={{
            width: '100%',
            position: 'fixed',
            top: "42px",
            zIndex: 1000,
            backgroundColor: 'white'
          }}>
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{
                height: '4px',
                backgroundColor: '#00b58721',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00B587',
                  transition: 'transform 0.3s ease-out'
                }
              }}
            />
          </Box>}
          <Grid container spacing={0} className="d-non">
            <Grid xs={12} className="proposalContentSection">
              {MAX_LIFE_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <Navbar />
          {isAmaze && <Box sx={{
            width: '100%',
            position: 'fixed',
            top: "65px",
            zIndex: 1000,
            backgroundColor: 'white'
          }}>
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{
                height: '4px',
                backgroundColor: '#00b58721',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00B587',
                  transition: 'transform 0.3s ease-out'
                }
              }}
            />
          </Box>}
          <Grid container spacing={3} className="pb-0">
            <TermLeftSidebar />
            <Grid xs className="proposalContentSection">
              {MAX_LIFE_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default MaxLifeRoutes;
