import {
  Box,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import MemberRadioButtonNew from "../MemberRadioButton/MemberRadioButtonNew";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import "./MedicalQuestion.scss";
import FormControl from "@mui/material/FormControl";
import { useAppSelector } from "../../redux/hooks";

interface Props {
  main_class: string;
  icon_class: string;
  question_name: string;
  sub_details?: any;
  input_id_first: string;
  input_id_second: string;
  input_name: string;
  toggle_button_status?: boolean;
  value_update?: Function;
  attrName?: any;
  select_disease?: any;
  data?: any;
  dataSubQuestion?: any;
  updateMasterState?: any;
  show_toggle_status?: boolean;
  selectedItems: any;
  handleChange: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GoDigitMedicalQuestion: React.FC<Props> = ({
  main_class,
  icon_class,
  question_name,
  sub_details,
  value_update,
  toggle_button_status,
  attrName,
  data,
  select_disease,
  updateMasterState,
}) => {
  const update_status = (attrName: any, value: any) => {
    value_update?.(attrName, value);
  };

  const { INSURED_MEMBER_DETAILS } = useAppSelector((state) => state.Health);
  return (
    <Box className={main_class}>
      <Grid container spacing={2}>
        <Grid xs={8} md={9} className="medicalQuestionInner">
          <span className={icon_class}></span>
          <div>
            <h6>{isEmpty(question_name) ? sub_details : question_name}</h6>
            {isEmpty(question_name) ? null : <p>{sub_details}</p>}
          </div>
        </Grid>
        <Grid xs={4} md={3} textAlign="right" className="pt-4">
          <ToggleSwitch
            toggle_status={toggle_button_status}
            value_update={update_status}
            attrName={attrName}
          />
        </Grid>
      </Grid>
      {select_disease}
      <Grid container spacing={2}>
        {data?.main_question?.member_data &&
          data?.main_question?.status &&
          Object.values(data?.main_question?.member_data)?.map(
            (memberDataValue: any, memberIndex: number) => (
              <React.Fragment key={memberIndex}>
                {memberDataValue.name !== "" && (
                  <Grid xs={4}>
                    {data?.main_question?.id === "MQ02" &&
                    data?.main_question.sub_questions.id === "MQ6-SQ6" ? (
                      memberIndex > 1 ? (
                        ""
                      ) : (INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship
                          .value === "Spouse" &&
                          memberIndex === 1 &&
                          INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.gender
                            .value === "M") ||
                        (INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.gender
                          .value === "F" &&
                          memberIndex === 0) ? (
                        <MemberRadioButtonNew
                          class_name={
                            memberIndex === 0 || memberIndex === 1
                              ? "adult"
                              : "child"
                          }
                          checked={memberDataValue?.selection_status}
                          label_name={memberDataValue.name}
                          attrName={[
                            "mainMemberData",
                            data?.main_question?.id,
                            memberDataValue?.keyName,
                          ]}
                          value_update={updateMasterState}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      <MemberRadioButtonNew
                        class_name={
                          memberIndex === 0 || memberIndex === 1
                            ? "adult"
                            : "child"
                        }
                        checked={memberDataValue?.selection_status}
                        label_name={memberDataValue.name}
                        attrName={[
                          "mainMemberData",
                          data?.main_question?.id,
                          memberDataValue?.keyName,
                        ]}
                        value_update={updateMasterState}
                      />
                    )}
                    {memberDataValue?.selection_status &&
                      memberDataValue.keyName && (
                        <>
                          {memberDataValue?.field_data &&
                            Object?.values(memberDataValue?.field_data)?.map(
                              (field_data_data: any) => {
                                let datePickerRendered = false; // Flag to track DatePicker rendering
                                return (
                                  <React.Fragment key={field_data_data.id}>
                                    {field_data_data.field_type ===
                                    "MILTIPLE_DROPDOWN" ? (
                                      <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="multiple-name-label">
                                          Diseases
                                        </InputLabel>
                                        <Select
                                          multiple
                                          labelId="multiple-name-label"
                                          value={field_data_data?.value?.value}
                                          error={field_data_data?.value.warning}
                                          // error_message={"Select value"}
                                          placeholder={
                                            field_data_data.title || "Select"
                                          }
                                          input={
                                            <OutlinedInput label="Diseases" />
                                          }
                                          onChange={(event) =>
                                            updateMasterState(
                                              [
                                                "multiple_dripdown",
                                                data?.main_question?.id,
                                                memberDataValue.keyName,
                                                field_data_data.id,
                                              ],
                                              event.target.value
                                            )
                                          }
                                          className={" inputField mb-5"}
                                          MenuProps={MenuProps}
                                        >
                                          {field_data_data.data_value.map(
                                            (item: any) => (
                                              <MenuItem
                                                key={item.value}
                                                value={item.value}
                                              >
                                                {item.label}
                                              </MenuItem>
                                            )
                                          )}
                                        </Select>
                                      </FormControl>
                                    ) : (
                                      ""
                                    )}

                                    {field_data_data.field_type ===
                                    "TEXTFIELD" ? (
                                      <RKTextField
                                        id="mainMemberDataField"
                                        class_name=" inputField mb-5"
                                        title={field_data_data.title}
                                        max_length={100}
                                        attrName={[
                                          "mainMemberDataField",
                                          data?.main_question?.id,
                                          memberDataValue.keyName,
                                          field_data_data.id,
                                        ]}
                                        value={field_data_data?.value?.value}
                                        value_update={updateMasterState}
                                        warn_status={
                                          field_data_data?.value?.warning
                                        }
                                        error_message={"Enter description"}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                );
                              }
                            )}
                        </>
                      )}
                  </Grid>
                )}
              </React.Fragment>
            )
          )}
      </Grid>
    </Box>
  );
};

export default GoDigitMedicalQuestion;
