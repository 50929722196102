/**
 * HealthRoutes Component
 *
 * This component is responsible for rendering health insurance proposal routes
 * based on the selected company's code and product details.
 *
 * Functionality:
 * - Fetches selected company details and product information from the Redux store.
 * - Extracts the product code from the URL's query parameters.
 * - Determines whether the product is a "health node" to decide the type of routes to render.
 * - Dynamically maps company codes to their respective route components.
 *
 * Dependencies:
 * - Redux for state management (`useAppSelector` hook).
 * - Enum for company codes (`ECompanyCode`).
 * - Helper function to determine health nodes (`isHealthNode`).
 * - Route components for different companies and common/shared routes.
 *
 * Usage:
 * - Render this component within the application to dynamically load health proposal routes.
 */

import { useAppSelector } from "../../../redux/hooks";
import { ECompanyCode } from "../../../Services/Enum/EHome";
import { isHealthNode } from "../../../SupportingFiles/HelpingFunction";
import { COMMON_ROUTES_NODE } from "./HealthProposalRoutes/common-node/common-routes";
import { COMMON_ROUTE } from "./HealthProposalRoutes/common-php/common-routes";
import { CARE_ROUTES } from "./HealthProposalRoutes/CARE/CARE_ROUTES";
import { HDFC_ROUTES } from "./HealthProposalRoutes/HDFC/HDFC_ROUTES";
import { HDFC_ROUTES_NODE } from "./HealthProposalRoutes/HDFC/hdfc-routes-node";
import { ICICI_ROUTES } from "./HealthProposalRoutes/ICICI/ICICI_ROUTES";
import { ICICI_ROUTES_NODE } from "./HealthProposalRoutes/ICICI/icici-routes-node";
import STAR_ROUTES from "./HealthProposalRoutes/STAR/STAR_ROUTES";
import GODIGIT_ROUTES from "./HealthProposalRoutes/GODIGIT/GODIGIT_ROUTES";
import NIVABIPA_ROUTES from "./HealthProposalRoutes/niva-bupa/niva-bupa-routes";
import BAJAJ_ROUTES from "./HealthProposalRoutes/BAJAJ/BAJAJ_ROUTES";
import ADITYA_BIRLA_ROUTES from "./HealthProposalRoutes/ADITYA_BIRLA/ADITYA_BIRLA_ROUTES";
import KOTAK_ROUTES from "./HealthProposalRoutes/Kotak/KOTAK_ROUTES";
import CIGNA_ROUTES from "./HealthProposalRoutes/CIGNA/CIGNA_ROUTES";
import { RSA_ROUTES } from "./HealthProposalRoutes/RSA/RSARoutes";
import { useEffect, useState } from "react";
import { CLIENTS } from "../../../URLCollection/Clients";

export default function HealthRoutes() {
  /**
   * Extracts the company details and product ID from the Redux store.
   * `CompanyDetails` includes company-related information like the company code.
   */
  const { CompanyDetails } = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA
  );
  const { SELECTED_QUOTE_DATA } = useAppSelector((state) => state.Health);
  const { id } = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA?.productDetails
  );
  const company_code = CompanyDetails.company_code;

  /**
   * Stores the product code extracted from the URL's query parameters.
   */
  const [productCode, setProductCode] = useState<string>("");

  /**
   * Effect to extract the product code from the URL's query parameters when the component mounts.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const product_code = searchParams.get("product_code");
    setProductCode(`${product_code}`);
  }, []);

  /**
   * Determines whether the product qualifies as a "health node."
   */
  const isNode = isHealthNode(id) || isHealthNode(productCode);

  /**
   * Maps company codes to their corresponding route components.
   */
  const companyRoutes: Record<string, JSX.Element> = {
    [ECompanyCode.CARE]: <CARE_ROUTES />,
    [ECompanyCode.HDFC_HEALTH]: isNode ? <HDFC_ROUTES_NODE /> : <HDFC_ROUTES />,
    [ECompanyCode.ICIC]: isNode ? <ICICI_ROUTES_NODE /> : <ICICI_ROUTES />,
    [ECompanyCode.STAR]: <STAR_ROUTES />,
    [ECompanyCode.GO_DIGIT]: <GODIGIT_ROUTES />,
    [ECompanyCode.NIVA_BUPA]: <NIVABIPA_ROUTES />,
    [ECompanyCode.BAJAJ]: <BAJAJ_ROUTES />,
    [ECompanyCode.ADITY_BIRLA]: <ADITYA_BIRLA_ROUTES />,
    [ECompanyCode.KOTAK]: <KOTAK_ROUTES />,
    [ECompanyCode.CIGNA]: <CIGNA_ROUTES />,
    [ECompanyCode.RSA]: <RSA_ROUTES />,
  };

  /**
   * Renders the appropriate routes:
   * - Common routes based on the health node status.
   * - Company-specific routes based on the selected company's code.
   */
  const reAssure = [2592, 2593, 2594];
  const [isReAssure, setIsReAssure] = useState(false);

  useEffect(() => {
    // Check if the node is part of reAssure and if the hostname matches
    const checkIsReAssure =
      reAssure.some((node) => node === id) &&
      (window.location.hostname === CLIENTS.GROWWINSURANCE ||
        window.location.hostname === CLIENTS.AMAZE);
    setIsReAssure(checkIsReAssure);
  }, [id]);

  console.log(isReAssure, "isReAssure");
  return (
    <>
      {isReAssure &&
      (window.location.hostname === CLIENTS.GROWWINSURANCE ||
        window.location.hostname === CLIENTS.AMAZE) ? (
        <COMMON_ROUTE />
      ) : isNode ? (
        <COMMON_ROUTES_NODE />
      ) : (
        <COMMON_ROUTE />
      )}
      {company_code ? companyRoutes[company_code] : null}
    </>
  );
}
