import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../../../../Component/Navbar/Navbar";
import MTermNavbar from "../../../../Component/Term/MTermNavbar/MTermNavbar";
import TermLeftSidebar from "../../../../Component/Term/TermLeftSidebar/TermLeftSidebar";
import RidersBenefitsContainer from "../../../../Container/Term/Bajaj/RidersBenefitsContainer";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TERM_ROUTES } from "../../../Path/TermRoutes";
import BasicDetailsContainer from "../../../../Container/Term/Bajaj/BasicDetailsContainer";
import { useEffect, useState } from "react";
import { CLIENTS } from "../../../../URLCollection/Clients";
import LinearProgress from '@mui/material/LinearProgress';

const BAJAJ_ROUTES = () => {
  return (
    <Routes>
      <Route
        path={TERM_ROUTES.BASIC_DETAILS}
        element={<BasicDetailsContainer />}
      />
      <Route
        path={TERM_ROUTES.RIDERS_BENEFITS}
        element={<RidersBenefitsContainer />}
      />
    </Routes>
  );
};

const BajajRoutes = () => {
  const isMobile = useIsMobile();

  const [step, setStep] = useState<number>(3);
  const location = useLocation();
  const isAmaze = CLIENTS.AMAZE === window.location.hostname;



  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes(TERM_ROUTES.BASIC_DETAILS)) {
      setStep(3);
    } else if (currentPath.includes(TERM_ROUTES.RIDERS_BENEFITS)
    ) {
      setStep(4);
    }

  }, [location.pathname]);
  const progressValue = (step / 4) * 100;

  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapper_m">
          <MTermNavbar />
          {isAmaze && <Box sx={{
            width: '100%',
            position: 'fixed',
            top: "42px",
            zIndex: 1000,
            backgroundColor: 'white'
          }}>
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{
                height: '4px',
                backgroundColor: '#00b58721',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00B587',
                  transition: 'transform 0.3s ease-out'
                }
              }}
            />
          </Box>}
          <Grid container spacing={0} className="d-non">
            <Grid xs={12} className="proposalContentSection">
              {BAJAJ_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <Navbar />
          {isAmaze && <Box sx={{
            width: '100%',
            position: 'fixed',
            top: "65px",
            zIndex: 1000,
            backgroundColor: 'white'
          }}>
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{
                height: '4px',
                backgroundColor: '#00b58721',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00B587',
                  transition: 'transform 0.3s ease-out'
                }
              }}
            />
          </Box>}
          <Grid container spacing={3} className="pb-0">
            <TermLeftSidebar />
            <Grid xs className="proposalContentSection">
              {BAJAJ_ROUTES()}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default BajajRoutes;
