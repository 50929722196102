import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Navbar from "../../../../Component/Navbar/Navbar";
import TermLeftSidebar from "../../../../Component/Term/TermLeftSidebar/TermLeftSidebar";
import ProposalBasicDetailConatiner from "../../../../Container/Term/Tata/ProposalBasicDetailConatiner";
import MTermNavbar from "../../../../Component/Term/MTermNavbar/MTermNavbar";
import LinearProgress from '@mui/material/LinearProgress';
import { CLIENTS } from "../../../../URLCollection/Clients";



const TATARoutes = () => {
  const isMobile = useIsMobile();

  const isAmaze = CLIENTS.AMAZE === window.location.hostname;

  const progressValue = (3 / 3) * 100;

  return (
    <>
      {isMobile ? (
        <Box className="proposalWrapper_m">
          <MTermNavbar />
          {isAmaze && <Box sx={{
            width: '100%',
            position: 'fixed',
            top: "42px",
            zIndex: 1000,
            backgroundColor: 'white'
          }}>
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{
                height: '4px',
                backgroundColor: '#00b58721',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00B587',
                  transition: 'transform 0.3s ease-out'
                }
              }}
            />
          </Box>}
          <Grid container spacing={3} className="pb-0">
            <Grid xs className="proposalContentSection">
              <ProposalBasicDetailConatiner />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="proposalWrapper">
          <Navbar />
          {isAmaze && <Box sx={{
            width: '100%',
            position: 'fixed',
            top: "65px",
            zIndex: 1000,
            backgroundColor: 'white'
          }}>
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{
                height: '4px',
                backgroundColor: '#00b58721',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#00B587',
                  transition: 'transform 0.3s ease-out'
                }
              }}
            />
          </Box>}
          <Grid container spacing={3} className="pb-0">
            <TermLeftSidebar />
            <Grid xs className="proposalContentSection">
              <ProposalBasicDetailConatiner />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default TATARoutes;
