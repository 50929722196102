import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import CareCKYCContainer from "../../../../../Container/CKYC/CARE/CareCKYCContainer";
import CareCKYCContainerNode from "../../../../../Container/ckyc-node/CARE/CareCKYCContainer";
import CAREManualKYCContainer from "../../../../../Container/ckyc-node/CARE/CareManualKycContainer";
import AddonDetailsContainer from "../../../../../Container/health-node/CARE/AddonDetailsContainer/AddonDetailsContainer";
import MedicalDetailsContainerNode from "../../../../../Container/health-node/CARE/MedicalDetailsContainer/MedicalDetailsContainer";
import AddonDetailsContainerPHP from "../../../../../Container/health-php/CARE/AddonDetailsContainer/AddonDetailsContainer";
import MedicalDetailsCareHeartContainer from "../../../../../Container/health-php/CARE/MedicalDetailsContainer/MedicalDetailsCareHeartContainer";
import MedicalDetailsContainer from "../../../../../Container/health-php/CARE/MedicalDetailsContainer/MedicalDetailsContainer";
import {
  GET_QUERY_PARAMETERS,
  isEmpty,
  isHealthNode,
} from "../../../../../SupportingFiles/HelpingFunction";
import { useAppSelector } from "../../../../../redux/hooks";
import { CKYC_ROUTES } from "../../../../Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Path/HealthRoutes";
import HealthProposalContainer from "../../HealthProposalContainer";

export const CARE_ROUTES = () => {
  const { id } = useAppSelector(
    (state) => state.Health.SELECTED_QUOTE_DATA?.productDetails
  );

  const isNode =
    isHealthNode(id) || isHealthNode(GET_QUERY_PARAMETERS("product_code"));

  if (id === 0 || isEmpty(id)) return null;

  return (
    <Routes>
      <Route
        path={HEALTH_ROUTE.PROPOSAL_PAGE}
        element={<HealthProposalContainer />}
      >
        {isNode ? (
          <>
            <Route
              path={CKYC_ROUTES.CKYC}
              element={<CareCKYCContainerNode />}
            />
            <Route
              path={CKYC_ROUTES.MANUAL_CKYC}
              element={<CAREManualKYCContainer />}
            />
          </>
        ) : (
          <Route path={CKYC_ROUTES.CKYC} element={<CareCKYCContainer />} />
        )}

        {isNode ? (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainer />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.ADDON_DETAILS}
            element={<AddonDetailsContainerPHP />}
          />
        )}

        {id === 1551 ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsCareHeartContainer />}
          />
        ) : isNode ? (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainerNode />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.MEDICAL_DETAILS}
            element={<MedicalDetailsContainer />}
          />
        )}
        {/* {isNode ? (
          <Route
            path={HEALTH_ROUTE.BANK_DETAILS}
            element={<CareBankDetailsContainerNode />}
          />
        ) : (
          <Route
            path={HEALTH_ROUTE.BANK_DETAILS}
            element={<CareBankDetailsContainer />}
          />
        )} */}
      </Route>
    </Routes>
  );
};
