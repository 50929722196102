import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Outlet, useLocation } from "react-router-dom";
import "../../../SCSS/CommonSCSS/ProposalForms.scss";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import Navbar from "../../../Component/Navbar/Navbar";
import MNavbar from "../../../Component/MNavbar/MNavbar";
import TWLeftSidebar from "../../../Component/TWLeftSideBar/TWLeftSidebar";
import LinearProgress from '@mui/material/LinearProgress';
import { useState, useEffect } from "react";
import { TW_ROUTES } from "../../Path/TWRoutes";
import { CLIENTS } from "../../../URLCollection/Clients";

function TWProposalContainer() {
  const isMobile = useIsMobile();

  const [step, setStep] = useState<number>(3);
  const location = useLocation();
  const isAmaze = CLIENTS.AMAZE === window.location.hostname;


  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes(TW_ROUTES.OWNER_DETAILS)) {
      setStep(4);
    } else if (currentPath.includes(TW_ROUTES.VEHICLE_DETAILS)
    ) {
      setStep(5);
    } else if (currentPath.includes(TW_ROUTES.NOMINEE_DETAILS)) {
      setStep(6);
    } else if (currentPath.includes(TW_ROUTES.ADDRESS_DETAILS)) {
      setStep(7);
    } else if (currentPath.includes(TW_ROUTES.PREVIEW_DEATILS)) {
      setStep(8);
    }

  }, [location.pathname]);
  const progressValue = (step / 8) * 100;
  return (
    <Box className={`${isMobile ? "proposalWrapper_m" : "proposalWrapper"}`}>
      <>{isMobile ? <MNavbar /> : <Navbar />}</>
      {/* Progress Bar */}
      {isAmaze && <Box sx={{
        width: '100%',
        position: 'fixed',
        top: isMobile ? "42px" : "65px",
        zIndex: 1000,
        backgroundColor: 'white'
      }}>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          sx={{
            height: '4px',
            backgroundColor: '#00b58721',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#00B587',
              transition: 'transform 0.3s ease-out'
            }
          }}
        />
      </Box>}
      <Grid container spacing={3} className="pb-0">
        {!isMobile && <TWLeftSidebar />}
        <Grid xs className="proposalContentSection">
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}

export default TWProposalContainer;
