import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CAR_ADD_FORM_SERVICES } from "../../Services/car-node/CarAddFormServices";
import { CAR_SERVICES } from "../../Services/car-node/CarServices";
import {
  DETERMINE_MOTOR_PLAN_TYPE,
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "../../SupportingFiles/HelpingFunction";
import { TAddressDetails } from "../../types/Common/TAddressDetails";
import { TNCB } from "../../types/Common/TNCB";
import { TClaimStatus, TNomineeDetails } from "../../types/TCarSlice";
import {
  TOwnerDetails,
  TTWAddFormResponse,
  TTWForm,
  TVehicleDetails,
} from "../../types/TTWSlice";
import { TTWAddonStatus } from "../../types/TwoWheeler/TTWAddonStatus";
import { TTWProduct } from "../../types/TwoWheeler/TTWProduct";
import { useAppDispatch } from "../hooks";
import { CKYCSlice } from "../slice/CKYC/CKYCSlice";
import { TWSlice } from "../slice/TwoWheeler/TWSlice";

export const TwInitialValues = () => {
  const dispatch = useAppDispatch();
  const [adrilaProStatus, setAdrilaProStatus] = useState<boolean>(false);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    const quote_no = params.get("quote_no");
    const product_id = params.get("product_id");
    const companyCode = params.get("companyCode");

    if (type && quote_no && companyCode && product_id && type === "TW") {
      const prams = {
        type: `${type}`,
        quote_no: quote_no,
        companyCode: companyCode,
        product_id: product_id,
      };

      GET_PREVIEW_DATA(prams);
    }
  }, []);

  const GET_PREVIEW_DATA = (params: {
    type: string;
    quote_no: string;
    companyCode: string;
    product_id: string;
  }) => {
    const onSuccess = (res: any) => {
      const results = res.data;
      const error = results.error;
      if (!error) {
        const response = results.response;

        if (params.type === "TW") {
          SET_TW_DATA(response);
        }
      }
    };
    const onError = () => {};

    CAR_SERVICES.GET_PREVIEW_DATA(onError, onSuccess, params);
  };

  const GET_FASTLANE_PRO_INFO = async (data: {
    reg_no: string;
    make_model: string;
    fuel_type: string;
    variant_cc: string;
  }): Promise<{
    chasis_no: string;
    engine_no: string;
    prevoius_policy_no: string;
  }> => {
    return new Promise((resolve, reject) => {
      const onSuccess = (res: any) => {
        const response_fast_lane = res.response;
        const chasis_no = response_fast_lane.chasis_no;
        const engine_no = response_fast_lane.engine_no;
        const prevoius_policy_no = response_fast_lane.prevoius_policy_no;

        resolve({ chasis_no, engine_no, prevoius_policy_no }); // Resolve the promise with data
      };

      const onError = (err: any) => {
        // if (window.location.hostname === CLIENTS.JIO) {
        setAdrilaProStatus(err?.response?.data?.error === true);
        // }
        reject(err); // Reject the promise in case of an error
      };

      CAR_ADD_FORM_SERVICES.GET_FASTLANE_PRO_INFO(
        onSuccess,
        onError,
        data.reg_no,
        "TW",
        data.make_model,
        data.fuel_type,
        data.variant_cc
      );
    });
  };

  const SET_TW_DATA = async (response: any) => {
    try {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      const create_proposal_response_tata = response?.tataDetails;
      const kycDetails = response?.CKYC_DETAILS?.kyc_details?.permanent_address;
      const utm_details = response?.utmDetails;
      const proposal_data = response?.praposal;
      const ckyc_details =
        response?.CKYC_DETAILS?.kyc_details ||
        response?.CKYC_DETAILS?.kycDetails ||
        response?.CKYC_DETAILS?.data;

      const form_data = response?.insurance;
      const manipulated_data_add_form: TTWForm = {
        pincode: {
          value:
            response?.insurance?.pincode ||
            response?.praposal?.address_p_pincode,
          warning: false,
        },
        utm_medium_d: response?.utmDetails?.utm_medium_d,
        invoice_date: { value: "", warning: false },
        previous_policy_type: { value: "SAOD", warning: false },
        name: {
          value:
            proposal_data?.customer_name ||
            ckyc_details?.full_name ||
            utm_details?.name,
          warning: false,
        },
        business_type: form_data?.business_type,
        claimed: form_data?.claim_made as TClaimStatus,
        make_model: {
          value: form_data?.make_model,
          warning: false,
        },
        fuel_type: {
          value: form_data?.fuel_type,
          warning: false,
        },
        make: { value: "", warning: false },
        mobile: {
          value:
            proposal_data?.customer_mobile ||
            ckyc_details?.mobile_number ||
            utm_details?.mobile,
          warning: false,
        },
        model: { value: "", warning: false },
        prev_ncb: form_data?.previous_ncb as TNCB,
        previous_insurer: {
          value: form_data?.previous_insurer,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data?.registration_date),
          warning: false,
        },
        reg_no: { value: form_data?.registration_no, warning: false },
        variant_cc: {
          value: form_data?.variant_cc,
          warning: false,
        },
        rto: { value: form_data?.rto_code, warning: false },
        policy_expiry_date: {
          value: FORMAT_DATE_DD_MM_YYYY(form_data?.policy_expiry_date),
          warning: false,
        },
      };

      const manipulated_data_add_form_response: TTWAddFormResponse = {
        ...TWSlice.getInitialState().ADD_FORM_RESPONSE,
        quote_no: response?.insurance?.quote_no,
        utm_medium: response?.utmDetails?.utm_medium,
        utm_source: response?.utmDetails?.utm_source,
      };

      const manipulated_selected_quote_data: TTWProduct = {
        ...TWSlice.getInitialState().SELECTED_QUOTE_DATA,
        quotationDetail: {
          cashlessGarageLists: [],
          CompanyDetails: response?.companyDetails,
          productDetails: response?.productDetails,
          premiumDetails: response?.jsonData,
        },
      };

      const manipulated_addon_status: TTWAddonStatus = {
        zero_depreciation: {
          label: "Zero Depreciation",
          value: form_data?.depreciation_cover === 1,
        },
        pa_owner_cover: {
          label: "Personal Accident Cover",
          value: form_data?.pa_owner_driver === 1,
        },
        rsa: {
          label: "24x7 Roadside Assistance",
          value: form_data?.road_side_assistance === 1,
        },
        driver_cover: {
          label: "Driver Cover",
          value: form_data?.driver_cover === 1,
        },
      };

      const manipulated_owner_details: TOwnerDetails = {
        ...TWSlice.getInitialState().OWNER_DETAILS,
        name: {
          value:
            proposal_data?.customer_name ||
            ckyc_details?.full_name ||
            utm_details?.name,
          warning: false,
        },
        dob: {
          value: !isEmpty(proposal_data?.customer_dob)
            ? FORMAT_DATE_DD_MM_YYYY(proposal_data?.customer_dob)
            : !isEmpty(ckyc_details?.dob)
            ? FORMAT_DATE_DD_MM_YYYY(ckyc_details?.dob)
            : "",
          warning: false,
        },
        email: {
          value:
            proposal_data?.customer_email ||
            ckyc_details?.email ||
            utm_details?.email,
          warning: false,
        },
        gender: {
          value: proposal_data?.customer_gender || ckyc_details?.gender,
          warning: false,
        },
        GST: { value: proposal_data?.customer_gst_no, warning: false },
        maritalStatus: {
          value: proposal_data?.customer_marital,
          warning: false,
        },
        mobile: {
          value:
            proposal_data?.customer_mobile ||
            ckyc_details?.mobile_number ||
            response?.utmDetails?.mobile,
          warning: false,
        },
        pincode: {
          value: proposal_data?.address_p_pincode,
          warning: false,
        },
        pacover: {
          value: response?.insurance?.reason_for_exemption_pa?.toString(),
          warning: false,
        },
      };

      const vehicle_data = response?.VEHICLE_DETAILS;
      const manipulated_vehicle_details: TVehicleDetails = {
        ...TWSlice.getInitialState().VEHICLE_DETAILS,
        bank_name: { value: vehicle_data?.bank_name, warning: false },
        chasis_no: { value: vehicle_data?.chasis_no, warning: false },
        city: { value: vehicle_data?.city, warning: false },
        engine_no: { value: vehicle_data?.engine_no, warning: false },
        is_vehicle_on_loan:
          vehicle_data?.is_vehicle_on_loan === "No" ||
          vehicle_data?.is_vehicle_on_loan === false
            ? false
            : true,
        mfg_year: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.mfg_year),
          warning: false,
        },
        PAN: { value: vehicle_data?.pan_no, warning: false },
        policy_exp_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.policy_exp_date),
          warning: false,
        },
        prev_insurer: {
          value: response.insurance.previous_insurer,
          warning: false,
        },
        prev_policy_no: {
          value: proposal_data?.existing_policy_no,
          warning: false,
        },
        reg_date: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.reg_date),
          warning: false,
        },
        tp_insurer: {
          value: response.insurance?.tp_previous_insurer,
          warning: false,
        },
        tp_policy: {
          value: proposal_data?.tp_policy || vehicle_data?.tp_policy,
          warning: false,
        },
        tp_policy_start: {
          value: FORMAT_DATE_DD_MM_YYYY(vehicle_data?.tp_policy_start),
          warning: false,
        },
      };

      const manipulated_nominee_details: TNomineeDetails = {
        ...TWSlice.getInitialState().NOMINEE_DETAILS,
        fullName: { value: proposal_data?.nominee_name, warning: false },
        dob: {
          value: FORMAT_DATE_DD_MM_YYYY(proposal_data?.nominee_dob),
          warning: false,
        },
        relationship: {
          value: proposal_data?.nominee_relationship,
          warning: false,
        },
      };

      const manipulated_address_details: TAddressDetails = {
        ...TWSlice.getInitialState().ADDRESS_DETAILS,
        pincode: {
          value:
            proposal_data?.address_p_pincode ||
            kycDetails?.pin_code ||
            ckyc_details?.pinCode ||
            ckyc_details?.permanentPincode,
          warning: false,
        },
        city: proposal_data?.address_p_city || kycDetails?.city || "",
        state: proposal_data?.address_p_state || kycDetails?.state,
        addressLineOne: {
          value:
            proposal_data?.address_p_house ||
            kycDetails?.address_line_1 ||
            ckyc_details?.address1 ||
            ckyc_details?.correspondenceAddress1,
          warning: false,
        },
        addressLineTwo: {
          value:
            proposal_data?.address_p_area ||
            kycDetails?.address_line_2 ||
            ckyc_details?.address2 ||
            ckyc_details?.correspondenceAddress2,
          warning: false,
        },
        isMailingAddressSame:
          proposal_data?.address_mailing_same === "Yes" ||
          isEmpty(proposal_data?.address_mailing_same),
        mailingPinCode: {
          value: proposal_data?.address_m_pincode,
          warning: false,
        },
        mailingCity: proposal_data?.address_m_city,
        mailingState: proposal_data?.address_m_state,
        mailingAddressLineOne: {
          value: proposal_data?.address_m_house,
          warning: false,
        },
        mailingAddressLineTwo: {
          value: proposal_data?.address_m_area,
          warning: false,
        },
      };

      const manipulated_create_proposal_response = {
        ...TWSlice.getInitialState().create_proposal_response,
        proposal_no: create_proposal_response_tata?.proposal_no,
        policyId: create_proposal_response_tata?.policyId,
      };

      const fast_lane_pro_data: {
        chasis_no: string;
        engine_no: string;
        prevoius_policy_no: string;
      } = await GET_FASTLANE_PRO_INFO({
        reg_no: manipulated_data_add_form.reg_no.value,
        make_model: manipulated_data_add_form.make_model.value,
        fuel_type: manipulated_data_add_form.fuel_type.value,
        variant_cc: manipulated_data_add_form.variant_cc.value,
      });
      const engine_no: string = fast_lane_pro_data?.engine_no;
      const chassis_no: string = fast_lane_pro_data?.chasis_no;
      const prevoius_policy_no: string = fast_lane_pro_data?.prevoius_policy_no;

      dispatch(
        CKYCSlice.actions.BULK_UPDATE({
          ...CKYCSlice.getInitialState(),
          VERIFY_KYC_FORM: {
            ...CKYCSlice.getInitialState().VERIFY_KYC_FORM,
            customer_type: {
              value: form_data.vehicle_owned,
              warning: false,
            },
          },
          CKYC_DETAILS: {
            ...response?.CKYC_DETAILS,
          },
        })
      );
      dispatch(
        TWSlice.actions.BULK_UPDATE({
          ...TWSlice.getInitialState(),
          ADD_FORM: {
            ...manipulated_data_add_form,
          },
          IDV_VALUE: form_data?.idv,
          ADD_FORM_RESPONSE: {
            ...manipulated_data_add_form_response,
          },
          SELECTED_QUOTE_DATA: {
            ...manipulated_selected_quote_data,
          },
          OWNER_DETAILS: {
            ...manipulated_owner_details,
          },
          VEHICLE_DETAILS: {
            ...manipulated_vehicle_details,
            engine_no: {
              value: isEmpty(engine_no)
                ? manipulated_vehicle_details?.engine_no.value
                : engine_no,
              warning: false,
            },
            chasis_no: {
              value: isEmpty(chassis_no)
                ? manipulated_vehicle_details?.chasis_no.value
                : chassis_no,
              warning: false,
            },
            prev_policy_no: {
              value: isEmpty(prevoius_policy_no)
                ? manipulated_vehicle_details?.prev_policy_no.value
                : prevoius_policy_no,
              warning: false,
            },
          },
          NOMINEE_DETAILS: { ...manipulated_nominee_details },
          ADDRESS_DETAILS: { ...manipulated_address_details },
          ADDON_STATUS: {
            ...manipulated_addon_status,
          },
          PLAN_TYPE: DETERMINE_MOTOR_PLAN_TYPE(
            `${manipulated_selected_quote_data?.quotationDetail?.productDetails.product_code}`,
            response?.insurance?.business_type
          ),
          QUOTE_LOADER: false,
          CKYC_DETAILS: { ...response?.CKYC_DETAILS },
          CUS_TYPE: !isEmpty(cus_type) ? true : false,
          create_proposal_response: {
            ...manipulated_create_proposal_response,
          },
        })
      );
    } catch (error) {
      toast.error(`${error}`);
      // Handle error gracefully, maybe show a message to the user
    }
  };

  return null;
};
