import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PreviewDetails from "../../../../Page/Desktop/health-node/common/PreviewDetails/PreviewDetails";
import MReviewDetails from "../../../../Page/Mobile/health-node/common/MReviewDetails/MReviewDetails";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { CKYCServcies } from "../../../../Services/CKYC/CKYCServices";
import { ECompanyCode, EProductId } from "../../../../Services/Enum/EHome";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-node/HealthPropsalServices";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { CommonDropdownSlice } from "../../../../redux/slice/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { HEALTH_CREATE_PROPOSAL_REQUEST } from "../../../../SupportingFiles/HealthHelper";
import {
  calculateAge,
  FIND_LABEL_OF_VALUE,
  FORMAT_DATE_DD_MM_YYYY,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { TDropdownData } from "../../../../types/DropdownData/DropdownData";
import { Box } from "@mui/material";

export default function PreviewDetailsContainer() {
  const isMobile = useIsMobile();
  const {
    NOMINEE_RELATIONSHIP_STATUS,
    ADDON_DETAILS,
    DROPDOWN_DATA,
    ADDON_DETAILS_ICIC,
    ADDON_QUESTION_NIVA_BUPA,
    ADDON_DETAILS_GOLDEN_SHIELD_ICICI,
    ADDON_DETAILS_GODDIGIT,
    ADDON_DETAILS_BAJAJ,
    ADDON_DETAILS_KOTAK,
    ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE,
    ADDON_DETAILS_CIGNA_PRIME_ACTIVE,
    ADDON_DETAILS_CIGNA_PRIME_PROTECT,
    ADDON_DETAILS_CIGNA_LIFETIME_INDIA,
    ADDON_DETAILS_RSA,
    ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL,
    ADDON_DETAILS_ADITYA_BIRLA,
    isDeduction_value,
    BANK_DETAILS_NIVABUPA,
    BANK_DETAILS_HDFC,
    BANK_DETAILS_ADITYABIRLA,
    BANK_DETAILS_CIGNA,
    SUPER_STAR_ADDON,
    ADDON_DETAILS_ELEVATE_ICICI,
    BANK_DETAILS_SUPERSTAR,
    BANK_DETAILS_CARE,
  } = useAppSelector((state) => state.Health);
  const navigate = useNavigate();
  const { Health, CKYC, CommonDropdownData } = useAppSelector((state) => state);
  const { AREA_TOWN } = useAppSelector((state) => state.CommonDropdownData);
  const [previewDetails, setPreviewDetails] = useState<Array<any>>([]);

  const [showAddon, setShowAddon] = useState<boolean>(false);
  const [showAddon2, setShowAddon2] = useState<boolean>(false);
  const [showAddon3, setShowAddon3] = useState<boolean>(false);

  const [occupationList, setOpccupationList] = useState<TDropdownData[]>([]);

  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);

  const PlanTypeOptions = [
    {
      label: "9  CriticalIllnesses covered",
      value: "PLAN1",
    },
    {
      label: "12 CriticalIllnesses covered",
      value: "PLAN2",
    },
    {
      label: "15 CriticalIllnesses covered",
      value: "PLAN3",
    },
    {
      label: "18 CriticalIllnesses covered",
      value: "PLAN4",
    },
    {
      label: "25 CriticalIllnesses covered",
      value: "PLAN5",
    },
    {
      label: "40 CriticalIllnesses covered",
      value: "PLAN6",
    },
    {
      label: "51 CriticalIllnesses coveredb",
      value: "PLAN7",
    },
  ];

  function findLabelByValue(value: string) {
    for (let i = 0; i < PlanTypeOptions?.length; i++) {
      if (PlanTypeOptions[i].value === value) {
        return PlanTypeOptions[i].label;
      }
    }
    return null; // Return null if value is not found
  }

  useEffect(() => {
    if (
      (Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 1000000 &&
        SELECTED_QUOTE_DATA.premiumDetails.term === 1) ||
      (Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 4000000 &&
        (SELECTED_QUOTE_DATA.premiumDetails.term === 2 ||
          SELECTED_QUOTE_DATA.premiumDetails.term === 3))
    ) {
      setShowAddon(true);
    }
  }, []);

  useEffect(() => {
    if (
      (Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 2000000 &&
        SELECTED_QUOTE_DATA.premiumDetails.term === 1) ||
      (Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >= 4000000 &&
        (SELECTED_QUOTE_DATA.premiumDetails.term === 2 ||
          SELECTED_QUOTE_DATA.premiumDetails.term === 3))
    ) {
      setShowAddon2(true);
    }
    function shouldShowAddon3(details: any) {
      return details?.Iscritical_Illness === "Yes";
    }

    // Example usage
    const showAddon3 = shouldShowAddon3(ADDON_DETAILS);
    console.log(showAddon3, "showAddon3");
    setShowAddon3(showAddon3);
    console.log(showAddon3, "showAddon3");
  }, []);

  useEffect(() => {
    setPreviewDetails([
      {
        title: "Proposer Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`,
        detailView: [
          {
            label: "Full Name",
            label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.name.value,
          },
          {
            label: "DOB",
            label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob?.value,
          },
          {
            label: "Gender",
            label_value:
              INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.gender.value === "M"
                ? "Male"
                : "Female",
          },
          {
            label: "Mobile",
            label_value: ADD_FORM.mobile.value,
          },
          {
            label: "Email",
            label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.email.value,
          },
          {
            label: "Aadhar",
            label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.aadhar.value,
          },
          INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.pan_card.value
            ? {
              label: "Pan",
              label_value:
                INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.pan_card.value,
            }
            : null,
          {
            label: "Emergency Phone",
            label_value:
              INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.emergencyPhone.value,
          },
        ],
      },
      {
        title: "1st Adult Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.INSURED_MEMBER}`,
        detailView: [
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? {
              label: "Relationship with proposer",
              label_value:
                INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value,
            }
            : {
              label: "Relationship with proposer",
              label_value: "Self",
            },
          {
            label: "Full Name",
            label_value: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
          },
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? {
              label: "DOB",
              label_value: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value,
            }
            : {
              label: "DOB",
              label_value: INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.dob?.value,
            },
          // {
          //   label: "DOB",
          //   label_value:
          //     INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.dob.value,
          // },
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? null
            : {
              label: "Gender",
              label_value:
                INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.gender.value === "F"
                  ? "Female"
                  : "Male",
            },
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? null
            : "",
          // : {
          //     label: "Mobile",
          //     label_value: ADD_FORM.mobile.value,
          //   },
          !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
            ? null
            : "",
          // {
          //   label: "Email",
          //   label_value: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.email.value,
          // },
          {
            label: "Aadhar",
            label_value: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.aadhar.value,
          },

          // INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.pan_card.value
          //   ? {
          //     label: "Pan",
          //     label_value:
          //       INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.pan_card.value,
          //   }
          //   : {
          //     label: "Pan",
          //     label_value:
          //       INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.pan_card.value,
          //   },

          {
            label: "Emergency Number",
            label_value:
              INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.emergencyPhone.value,
          },
          {
            label: "Weight",
            label_value: `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.weight.value} Kg`,
          },
          {
            label: "Height",
            label_value: `${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.heightInches.value} Inches`,
          },
          {
            label: "Marital Status",
            label_value:
              DROPDOWN_DATA.MARTIAL?.length !== 0
                ? FIND_LABEL_OF_VALUE(
                  DROPDOWN_DATA.MARTIAL,
                  INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.maritalStatus.value
                )
                : INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.maritalStatus.value.split(
                  "-"
                )[1],
          },
          {
            label: "Occupation",
            label_value: FIND_LABEL_OF_VALUE(
              DROPDOWN_DATA.OCCUPATION,
              INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.occupation.value
            ),
          },
          // {
          //   label: "Pan",
          //   label_value:
          //     INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.pan_card.value,
          // },
          // SELECTED_QUOTE_DATA.productDetails.id === 2551 && {
          //   label: "Annual Income",
          //   label_value:
          //     INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.annual_income.value,
          // },
          {
            label: "Qualification",
            label_value:
              INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.qualification.value,
          },
          {
            label: "Annual Income",
            label_value:
              INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.annual_income.value,
          },
        ],
      },
      {
        title: "2nd Adult Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_TWO_DETAILS}`,
        detailView: ADD_FORM.spouse_status
          ? [
            {
              label: "Relationship with proposer",
              label_value:
                INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship?.value,
            },
            {
              label: "Full Name",
              label_value:
                INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.name.value,
            },
            {
              label: "Height",
              label_value: `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.heightInches.value} Inches`,
            },
            {
              label: "Weight",
              label_value: `${INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.weight.value} Kg`,
            },
            {
              label: "DOB",
              label_value:
                INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS?.dob?.value,
            },
            {
              label: "Qualification",
              label_value:
                INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.qualification.value,
            },
            {
              label: "Occupation",
              label_value:
                SELECTED_QUOTE_DATA?.CompanyDetails?.company_code ===
                  ECompanyCode.NIVA_BUPA
                  ? FIND_LABEL_OF_VALUE(
                    DROPDOWN_DATA.OCCUPATION,
                    INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.occupation
                      .value
                  )
                  : "",
            },
          ]
          : [],
      },
      {
        title: "1st Child Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.CHILD_ONE_DETAILS}`,
        detailView: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value
          ? [
            {
              label: "Relationship with proposer",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.relationship.value,
            },
            {
              label: "Full Name",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
            },
            {
              label: "Height",
              label_value: `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS?.heightInches.value} Inches`,
            },
            {
              label: "Weight",
              label_value: `${INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS?.weight.value} Kg`,
            },
            {
              label: "DOB",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS?.dob?.value,
            },
          ]
          : [],
      },
      {
        title: "2nd Child Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.CHILD_TWO_DETAILS}`,
        detailView: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value
          ? [
            {
              label: "Relationship with proposer",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.relationship?.value,
            },
            {
              label: "Full Name",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
            },
            {
              label: "Height",
              label_value: `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.heightInches.value} Inches`,
            },
            {
              label: "Weight",
              label_value: `${INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS?.weight.value} Kg`,
            },
            {
              label: "DOB",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.dob?.value,
            },
          ]
          : [],
      },
      {
        title: "3rd Child Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.CHILD_THREE_DETAILS}`,
        detailView: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value
          ? [
            {
              label: "Relationship with proposer",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.relationship
                  ?.value,
            },
            {
              label: "Full Name",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
            },
            {
              label: "Height",
              label_value: `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.heightInches.value} Inches`,
            },
            {
              label: "Weight",
              label_value: `${INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.weight.value} Kg`,
            },
            {
              label: "DOB",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.dob?.value,
            },
          ]
          : [],
      },
      {
        title: "4th Child Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.CHILD_FOUR_DETAILS}`,
        detailView: INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS.name.value
          ? [
            {
              label: "Relationship with proposer",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.relationship.value,
            },
            {
              label: "Full Name",
              label_value:
                INSURED_MEMBER_DETAILS?.CHILD_FOUR_DETAILS.name.value,
            },
            {
              label: "Height",
              label_value: `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.heightFeet.value} Feet ${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.heightInches.value} Inches`,
            },
            {
              label: "Weight",
              label_value: `${INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.weight.value} Kg`,
            },
            {
              label: "DOB",
              label_value:
                INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.dob?.value,
            },
          ]
          : [],
      },
      {
        title: "Nominee Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`,
        detailView:
          NOMINEE_RELATIONSHIP_STATUS === "OTHER"
            ? [
              {
                label: "Relationship",
                label_value:
                  `${INSURED_MEMBER_DETAILS.NOMINEE__DETAILS.relationship.value}`.split(
                    "-"
                  )[1],
              },
              {
                label: "Nominee Name",
                label_value:
                  INSURED_MEMBER_DETAILS.NOMINEE__DETAILS?.full_name?.value,
              },
              {
                label: "DOB",
                label_value:
                  INSURED_MEMBER_DETAILS.NOMINEE__DETAILS?.dob.value,
              },
            ]
            : [
              {
                label: "Relationship",
                label_value:
                  INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.relationship.value,
              },
              {
                label: "Nominee Name",
                label_value:
                  INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              },
              {
                label: "DOB",
                label_value:
                  INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.dob.value,
              },
            ],
      },
      {
        title: "Addons Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}`,
        detailView:
          SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G015" &&
            SELECTED_QUOTE_DATA.productDetails.id !== 2947
            ? []
            : SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G012"
              ? []
              : SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G001"
                ? [
                  {
                    label: "Non Medical Expense",
                    label_value: ADDON_DETAILS_BAJAJ?.Addonnme ? "Yes" : "No",
                  },
                  {
                    label: "Room waiver addon",
                    label_value: ADDON_DETAILS_BAJAJ?.Addonroomwaiver
                      ? "Yes"
                      : "No",
                  },
                ]
                : SELECTED_QUOTE_DATA.productDetails.id === 2947
                  ? [
                    {
                      label: "SMART NETWORK",
                      label_value: SUPER_STAR_ADDON?.SMART_NETWORK ? "Yes" : "No",
                    },
                    {
                      label: "QUICK SHIELD",
                      label_value: SUPER_STAR_ADDON?.QUICK_SHIELD ? "Yes" : "No",
                    },
                    {
                      label: "CONSUMABLES COVER",
                      label_value: SUPER_STAR_ADDON?.CONSUMABLES_COVER
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "FUTURE SHIELD",
                      label_value: SUPER_STAR_ADDON?.FUTURE_SHIELD ? "Yes" : "No",
                    },
                    {
                      label: "MATERNITY EXPENSES",
                      label_value: SUPER_STAR_ADDON?.MATERNITY_EXPENSES
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "WOMEN CARE",
                      label_value: SUPER_STAR_ADDON?.WOMEN_CARE ? "Yes" : "No",
                    },
                    {
                      label: "HIGH-END DIAGNOSTICS",
                      label_value: SUPER_STAR_ADDON?.HIGHEND_DIAGNOSTICS
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "PERSONAL ACCIDENT COVER",
                      label_value: SUPER_STAR_ADDON?.PERSONAL_ACCIDENT_COVER
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "ANNUAL HEALTH CHECK-UP",
                      label_value: SUPER_STAR_ADDON?.ANNUAL_HEALTH_CHECKUP
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "VOLUNTARY CO-PAYMENT",
                      label_value: SUPER_STAR_ADDON?.VOLUNTARY_COPAYMENT
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "VOLUNTARY DEDUCTIBLE",
                      label_value: SUPER_STAR_ADDON?.VOLUNTARY_DEDUCTIBLE
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "ROOM RENT MODIFICATION",
                      label_value: SUPER_STAR_ADDON?.ROOM_RENT_MODIFICATION
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "E-INTERNATIONAL SECOND OPINION",
                      label_value: SUPER_STAR_ADDON?.E_INTERNATIONAL_SECOND_OPINION
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "DURABLE MEDICAL EQUIPMENT COVER",
                      label_value: SUPER_STAR_ADDON?.DURABLE_MEDICAL_EQUIPMENT_COVER
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "COMPASSIONATE VISIT",
                      label_value: SUPER_STAR_ADDON?.COMPASSIONATE_VISIT
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "HOSPITAL CASH BENEFIT",
                      label_value: SUPER_STAR_ADDON?.HOSPITAL_CASH_BENEFIT
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "REDUCTION OF SPECIFIC DISEASE/PROCEDURE WAITING",
                      label_value:
                        SUPER_STAR_ADDON?.REDUCTION_SPECIFIC_DISEASE_PROCEDURE
                          ? "Yes"
                          : "No",
                    },
                    {
                      label: "REDUCTION OF PRE-EXISTING DISEASE WAITING PERIOD",
                      label_value: SUPER_STAR_ADDON?.REDUCTION_PRE_EXISTING_DISEASE
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "LIMITLESS CARE",
                      label_value: SUPER_STAR_ADDON?.LIMITLESS_CARE ? "Yes" : "No",
                    },
                    {
                      label: "SUPER STAR BONUS",
                      label_value: SUPER_STAR_ADDON?.SUPER_STAR_BONUS
                        ? "Yes"
                        : "No",
                    },
                    {
                      label: "NRI ADVANTAGE",
                      label_value: SUPER_STAR_ADDON?.NRI_ADVANTAGE ? "Yes" : "No",
                    },
                  ]
                  : SELECTED_QUOTE_DATA.productDetails.id === 2772
                    ? [
                      {
                        label: "Outpatient",
                        label_value: ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.outpatient
                          ? "Yes"
                          : "No",
                      },
                      {
                        label: "Outpatient Value",
                        label_value:
                          ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.healthAddSumInsured,
                      },
                      {
                        label: "Critical Illness",
                        label_value:
                          ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.critical_illness
                            ? "Yes"
                            : "No",
                      },
                    ]
                    : SELECTED_QUOTE_DATA.productDetails.id === 2772
                      ? [
                        {
                          label: "Outpatient",
                          label_value: ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.outpatient
                            ? "Yes"
                            : "No",
                        },
                        {
                          label: "Outpatient Value",
                          label_value:
                            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.healthAddSumInsured,
                        },
                        {
                          label: "Critical Illness",
                          label_value:
                            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.critical_illness
                              ? "Yes"
                              : "No",
                        },
                        {
                          label: "Pro Health-Cumulative Bonus Booster",
                          label_value: ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.cumulative
                            ? "Yes"
                            : "No",
                        },
                        {
                          label: "Non-Medical Items",
                          label_value: ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.non_medical
                            ? "Yes"
                            : "No",
                        },
                        {
                          label: "Personal Accident Cover",
                          label_value:
                            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.personal_accident
                              ? "Yes"
                              : "No",
                        },
                        {
                          label: "PRIME ENHANCE PLUS/Prime Freedom",
                          label_value:
                            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.prime_enhance
                              ? "Yes"
                              : "No",
                        },
                        {
                          label: "Room Rent",
                          label_value: ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.room_rent
                            ? "Yes"
                            : "No",
                        },
                        {
                          label: "Supreme Bonus",
                          label_value:
                            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.supreme_bonus
                              ? "Yes"
                              : "No",
                        },
                        {
                          label: "Surplus Benefit",
                          label_value:
                            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.surplus_benifit
                              ? "Yes"
                              : "No",
                        },
                        {
                          label: "Premium Management",
                          label_value:
                            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE?.premium_management
                              ? "Yes"
                              : "No",
                        },
                      ]
                      : SELECTED_QUOTE_DATA.productDetails.id === 2773
                        ? [
                          {
                            label: "Prime Wellness",
                            label_value: ADDON_DETAILS_CIGNA_PRIME_ACTIVE?.wellness
                              ? "Yes"
                              : "No",
                          },
                          {
                            label: "Waiver of Diesease Specific Sub-Limit",
                            label_value: ADDON_DETAILS_CIGNA_PRIME_ACTIVE?.waiver_diesease
                              ? "Yes"
                              : "No",
                          },
                          {
                            label: "Non-Medical Items",
                            label_value: ADDON_DETAILS_CIGNA_PRIME_ACTIVE?.non_medical
                              ? "Yes"
                              : "No",
                          },
                          {
                            label: "Health Check Up",
                            label_value: ADDON_DETAILS_CIGNA_PRIME_ACTIVE?.health_ckeck
                              ? "Yes"
                              : "No",
                          },
                          {
                            label: "Waiver of Mandatory Co-pay",
                            label_value: ADDON_DETAILS_CIGNA_PRIME_ACTIVE?.waiver
                              ? "Yes"
                              : "No",
                          },
                          {
                            label: "Worldwide Accidental Emergency Hospitalization Cover",
                            label_value: ADDON_DETAILS_CIGNA_PRIME_ACTIVE?.world_accident
                              ? "Yes"
                              : "No",
                          },
                        ]
                        : SELECTED_QUOTE_DATA.productDetails.id === 1551
                          ? [
                            {
                              label: "Field CS",
                              label_value: ADDON_DETAILS.field_CS ? "Yes" : "No",
                            },
                            {
                              label: "Field HomeCare",
                              label_value: ADDON_DETAILS.field_HomeCare ? "Yes" : "No",
                            },
                            {
                              label: "Field OPD",
                              label_value: ADDON_DETAILS.field_OPD ? "Yes" : "No",
                            },
                          ]
                          : SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_FREEDOM
                            ? [
                              {
                                label: "Field HomeCare",
                                label_value: ADDON_DETAILS.field_HomeCare ? "Yes" : "No",
                              },
                              {
                                label: "Care OPD",
                                label_value: ADDON_DETAILS.field_OPD ? "Yes" : "No",
                              },
                              {
                                label: "Waiver",
                                label_value: ADDON_DETAILS.field_Deductible ? "Yes" : "No",
                              },
                              {
                                label: "Field HCP",
                                label_value: ADDON_DETAILS.field_HCP ? "Yes" : "No",
                              },
                            ]
                            : SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_CARE
                              ? [
                                {
                                  label: "OPD Care",
                                  label_value: ADDON_DETAILS.field_OPD ? "Yes" : "No",
                                },
                                ADDON_DETAILS.field_OPD
                                  ? {
                                    label: "Care OPD SI",
                                    label_value: ADDON_DETAILS.field_OPD_data.value
                                      ? `${ADDON_DETAILS.field_OPD_data.value}`
                                      : "No",
                                  }
                                  : {},

                                {
                                  label: "Air Ambulance",
                                  label_value: ADDON_DETAILS.field_35 ? "Yes" : "No",
                                },
                                {
                                  label: "Reduction in PED waiting period",
                                  label_value: ADDON_DETAILS.field_43 ? "Yes" : "No",
                                },
                                {
                                  label: "Care shield",
                                  label_value: ADDON_DETAILS.field_CS ? "Yes" : "No",
                                },
                                {
                                  label: "NCB super",
                                  label_value: ADDON_DETAILS.field_NCB ? "Yes" : "No",
                                },
                                {
                                  label: "Everyday care",
                                  label_value: ADDON_DETAILS.field_EDC ? "Yes" : "No",
                                },
                                {
                                  label: "Co-payment waiver",
                                  label_value: ADDON_DETAILS.field_CWP ? "Yes" : "No",
                                },
                                {
                                  label: "Unlimited Recharge",
                                  label_value: ADDON_DETAILS.field_UA ? "Yes" : "No",
                                },
                              ]
                              : SELECTED_QUOTE_DATA.productDetails.id === 2712
                                ? [
                                  {
                                    label: "Critical Illness",
                                    label_value:
                                      ADDON_DETAILS_CIGNA_PRIME_PROTECT?.critical_illness
                                        ? "Yes"
                                        : "No",
                                  },
                                  {
                                    label: "Pro Health-Cumulative Bonus Booster",
                                    label_value: ADDON_DETAILS_CIGNA_PRIME_PROTECT?.cumulative
                                      ? "Yes"
                                      : "No",
                                  },
                                  {
                                    label: "Deductible",
                                    label_value: ADDON_DETAILS_CIGNA_PRIME_PROTECT?.reduction
                                      ? "Yes"
                                      : "No",
                                  },
                                  {
                                    label: "health Add Sum Insured",
                                    label_value:
                                      ADDON_DETAILS_CIGNA_PRIME_PROTECT?.healthAddSumInsured,
                                  },
                                  {
                                    label: "Non-Medical Items",
                                    label_value: ADDON_DETAILS_CIGNA_PRIME_PROTECT?.non_medical
                                      ? "Yes"
                                      : "No",
                                  },
                                  {
                                    label: "Personal Accident Cover",
                                    label_value:
                                      ADDON_DETAILS_CIGNA_PRIME_PROTECT?.personal_accident
                                        ? "Yes"
                                        : "No",
                                  },
                                  {
                                    label: "PRIME ENHANCE PLUS/Prime Freedom",
                                    label_value: ADDON_DETAILS_CIGNA_PRIME_PROTECT?.prime_enhance
                                      ? "Yes"
                                      : "No",
                                  },
                                  {
                                    label: "Room Rent",
                                    label_value: ADDON_DETAILS_CIGNA_PRIME_PROTECT?.room_rent
                                      ? "Yes"
                                      : "No",
                                  },
                                  {
                                    label: "Supreme Bonus",
                                    label_value: ADDON_DETAILS_CIGNA_PRIME_PROTECT?.supreme_bonus
                                      ? "Yes"
                                      : "No",
                                  },
                                  {
                                    label: "Surplus Benefit",
                                    label_value:
                                      ADDON_DETAILS_CIGNA_PRIME_PROTECT?.surplus_benifit
                                        ? "Yes"
                                        : "No",
                                  },
                                  {
                                    label: "Premium Management",
                                    label_value:
                                      ADDON_DETAILS_CIGNA_PRIME_PROTECT?.premium_management
                                        ? "Yes"
                                        : "No",
                                  },
                                  ADD_FORM.gender.value === "F" ||
                                    ADD_FORM.spouse_relationship.value === "Spouse"
                                    ? {
                                      label: "Woman Care",
                                      label_value: ADDON_DETAILS_CIGNA_PRIME_PROTECT?.woman_care
                                        ? "Yes"
                                        : "No",
                                    }
                                    : null,
                                  ...(Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >=
                                    300000 &&
                                    Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) <= 500000
                                    ? [
                                      {
                                        label: "PRIME ASSURE",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_PRIME_PROTECT?.prime_assure
                                            ? "Yes"
                                            : "No",
                                      },
                                    ]
                                    : []),
                                  ...(Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) >=
                                    750000 &&
                                    Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) <=
                                    10000000 &&
                                    (ADD_FORM.gender.value === "F" ||
                                      ADD_FORM.spouse_relationship.value === "Spouse")
                                    ? [
                                      {
                                        label: "INFERTILITY TREATMENT",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_PRIME_PROTECT?.infertility_treatement
                                            ? "Yes"
                                            : "No",
                                      },
                                    ]
                                    : []),
                                ]
                                : SELECTED_QUOTE_DATA.productDetails.id === 2757
                                  ? [
                                    {
                                      label: "Critical Illness",
                                      label_value:
                                        ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.critical_illness
                                          ? "Yes"
                                          : "No",
                                    },
                                    {
                                      label: "cumulative",
                                      label_value: ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.cumulative
                                        ? "Yes"
                                        : "No",
                                    },
                                    {
                                      label: "wwhospitalized",
                                      label_value:
                                        ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.wwhospitalized
                                          ? "Yes"
                                          : "No",
                                    },
                                    {
                                      label: "Maternity Expenses",
                                      label_value:
                                        ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.maternity_expenses
                                          ? "Yes"
                                          : "No",
                                    },
                                    {
                                      label: "Infertility",
                                      label_value: ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.infertility
                                        ? "Yes"
                                        : "No",
                                    },
                                    {
                                      label: "Oocyte",
                                      label_value: ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.Oocyte
                                        ? "Yes"
                                        : "No",
                                    },
                                    {
                                      label: "Health 360 Advance",
                                      label_value:
                                        ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.health_360_advance
                                          ? "Yes"
                                          : "No",
                                    },
                                    {
                                      label: "Health 360 Opd",
                                      label_value:
                                        ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.health_360_opd
                                          ? "Yes"
                                          : "No",
                                    },
                                    {
                                      label: "Health 360 Shield",
                                      label_value:
                                        ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.health_360_shield
                                          ? "Yes"
                                          : "No",
                                    },
                                    {
                                      label: "Health+",
                                      label_value: ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.health_plus
                                        ? "Yes"
                                        : "No",
                                    },
                                    {
                                      label: "Women+",
                                      label_value: ADDON_DETAILS_CIGNA_LIFETIME_INDIA?.woman_plus
                                        ? "Yes"
                                        : "No",
                                    },
                                  ]
                                  : SELECTED_QUOTE_DATA.productDetails.id === 2895
                                    ? [
                                      {
                                        label: "Critical Illness",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.critical_illness
                                            ? "Yes"
                                            : "No",
                                      },
                                      {
                                        label: "cumulative",
                                        label_value: ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.cumulative
                                          ? "Yes"
                                          : "No",
                                      },
                                      {
                                        label: "wwhospitalized",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.wwhospitalized
                                            ? "Yes"
                                            : "No",
                                      },
                                      {
                                        label: "Maternity Expenses",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.maternity_expenses
                                            ? "Yes"
                                            : "No",
                                      },
                                      {
                                        label: "Infertility",
                                        label_value: ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.infertility
                                          ? "Yes"
                                          : "No",
                                      },
                                      {
                                        label: "Oocyte",
                                        label_value: ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.Oocyte
                                          ? "Yes"
                                          : "No",
                                      },
                                      {
                                        label: "Health 360 Advance",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.health_360_advance
                                            ? "Yes"
                                            : "No",
                                      },
                                      {
                                        label: "Health 360 Opd",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.health_360_opd
                                            ? "Yes"
                                            : "No",
                                      },
                                      {
                                        label: "Health 360 Shield",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.health_360_shield
                                            ? "Yes"
                                            : "No",
                                      },
                                      {
                                        label: "coverage_type",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.coverage_type
                                            ? "Yes"
                                            : "No",
                                      },
                                      {
                                        label: "coverage_illness",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.coverage_illness
                                            ? "Yes"
                                            : "No",
                                      },
                                      {
                                        label: "wwh_suminsured",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.wwh_suminsured
                                            ? "Yes"
                                            : "No",
                                      },
                                      {
                                        label: "global_suminsured",
                                        label_value:
                                          ADDON_DETAILS_CIGNA_LIFETIME_GLOBAL?.global_suminsured
                                            ? "Yes"
                                            : "No",
                                      },
                                    ]
                                    : SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G033"
                                      ? [
                                        {
                                          label: "Consumables Cover",
                                          label_value: ADDON_DETAILS_GODDIGIT?.consumables_cover
                                            ? "Yes"
                                            : "No",
                                        },
                                      ]
                                      : SELECTED_QUOTE_DATA.productDetails.id === 2027 ||
                                        SELECTED_QUOTE_DATA.productDetails.id === 2026
                                        ? [
                                          {
                                            label: "Critical Illness Cover",
                                            label_value: ADDON_DETAILS_KOTAK.critical_illness
                                              ? "Yes"
                                              : "No",
                                          },
                                          {
                                            label: "Compassionate Visit",
                                            label_value: ADDON_DETAILS_KOTAK.compassionate_visit
                                              ? "Yes"
                                              : "No",
                                          },
                                          {
                                            label: "Air Ambulance Cover",
                                            label_value: ADDON_DETAILS_KOTAK.air_ambulance ? "Yes" : "No",
                                          },
                                          {
                                            label: "Personal Accident Cover",
                                            label_value: ADDON_DETAILS_KOTAK.personal_accident
                                              ? "Yes"
                                              : "No",
                                          },
                                          {
                                            label: "Claim Protect",
                                            label_value: ADDON_DETAILS_KOTAK.claim_protect ? "Yes" : "No",
                                          },
                                          {
                                            label: "Inflation Protect",
                                            label_value: ADDON_DETAILS_KOTAK.inflation_proptect
                                              ? "Yes"
                                              : "No",
                                          },
                                          {
                                            label: "Super NCB",
                                            label_value: ADDON_DETAILS_KOTAK.super_ncb ? "Yes" : "No",
                                          },
                                          {
                                            label: "Restoration Benefit Plus",
                                            label_value: ADDON_DETAILS_KOTAK.restoration_benefit_plus
                                              ? "Yes"
                                              : "No",
                                          },
                                          {
                                            label: "Maternity Benefit",
                                            label_value: ADDON_DETAILS_KOTAK.maternity_benefit
                                              ? "Yes"
                                              : "No",
                                          },
                                          {
                                            label: "New Born Baby Cover",
                                            label_value: ADDON_DETAILS_KOTAK.new_born_baby_cover
                                              ? "Yes"
                                              : "No",
                                          },
                                          {
                                            label: "Vaccination Expenses",
                                            label_value: ADDON_DETAILS_KOTAK.vaccination_expenses
                                              ? "Yes"
                                              : "No",
                                          },
                                        ]
                                        : SELECTED_QUOTE_DATA.productDetails.id === 2025
                                          ? [
                                            {
                                              label: "Critical Illness Cover",
                                              label_value: ADDON_DETAILS_KOTAK.critical_illness
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Compassionate Visit",
                                              label_value: ADDON_DETAILS_KOTAK.compassionate_visit
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Air Ambulance Cover",
                                              label_value: ADDON_DETAILS_KOTAK.air_ambulance ? "Yes" : "No",
                                            },
                                            {
                                              label: "Personal Accident Cover",
                                              label_value: ADDON_DETAILS_KOTAK.personal_accident
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Claim Protect",
                                              label_value: ADDON_DETAILS_KOTAK.claim_protect ? "Yes" : "No",
                                            },
                                            {
                                              label: "Inflation Protect",
                                              label_value: ADDON_DETAILS_KOTAK.inflation_proptect
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Super NCB",
                                              label_value: ADDON_DETAILS_KOTAK.super_ncb ? "Yes" : "No",
                                            },
                                            {
                                              label: "Restoration Benefit Plus",
                                              label_value: ADDON_DETAILS_KOTAK.restoration_benefit_plus
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Maternity Benefit",
                                              label_value: ADDON_DETAILS_KOTAK.maternity_benefit
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "New Born Baby Cover",
                                              label_value: ADDON_DETAILS_KOTAK.new_born_baby_cover
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Vaccination Expenses",
                                              label_value: ADDON_DETAILS_KOTAK.vaccination_expenses
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Hospital Daily Cash",
                                              label_value: ADDON_DETAILS_KOTAK.hospital_daily_cash
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Home Nursing Benefit",
                                              label_value: ADDON_DETAILS_KOTAK.home_nursing_benefit
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Daily Cash",
                                              label_value: ADDON_DETAILS_KOTAK.daily_cash ? "Yes" : "No",
                                            },
                                            {
                                              label: "Convalescence Benefit",
                                              label_value: ADDON_DETAILS_KOTAK.convalescence_benefit
                                                ? "Yes"
                                                : "No",
                                            },
                                            {
                                              label: "Optional Cover",
                                              label_value: ADDON_DETAILS_KOTAK.cap_on_room_rent
                                                ? "Yes"
                                                : "No",
                                            },
                                          ]
                                          : SELECTED_QUOTE_DATA.productDetails.id === 2651
                                            ? [
                                              {
                                                label: "Wellness Benefit",
                                                label_value: ADDON_DETAILS?.field_WB ? "Yes" : "No",
                                              },
                                              {
                                                label: "Be-Fit Benefit",
                                                label_value: ADDON_DETAILS?.field_BFB ? "Yes" : "No",
                                              },
                                              {
                                                label: "Care OPD (Outpatient Department)",
                                                label_value: ADDON_DETAILS?.field_OPD ? "Yes" : "No",
                                              },
                                              {
                                                label: "Air Ambulance Cover",
                                                label_value: ADDON_DETAILS?.AirAmbulanceCover ? "Yes" : "No",
                                              },
                                              {
                                                label: "Pre-Existing disease wait period modification",
                                                label_value: ADDON_DETAILS?.field_43 ? "Yes" : "No",
                                              },
                                              {
                                                label: "Claim Shield",
                                                label_value: ADDON_DETAILS?.CareShield ? "Yes" : "No",
                                              },
                                              {
                                                label: "Cumulative Bonus Super",
                                                label_value: ADDON_DETAILS?.NCBSuper ? "Yes" : "No",
                                              },
                                              {
                                                label: "Annual Health Check-up",
                                                label_value: ADDON_DETAILS?.field_AHC ? "Yes" : "No",
                                              },
                                              {
                                                label: "Instant Cover",
                                                label_value: ADDON_DETAILS?.field_IC ? "Yes" : "No",
                                              },
                                            ]
                                            : SELECTED_QUOTE_DATA.productDetails.id === 443
                                              ? [
                                                {
                                                  label: "Smart Select",
                                                  label_value: ADDON_DETAILS?.SmartSelect ? "Yes" : "No",
                                                },
                                                {
                                                  label: "Claim Shield",
                                                  label_value: ADDON_DETAILS?.CareShield ? "Yes" : "No",
                                                },
                                                {
                                                  label: "Cumulative Bonus Super",
                                                  label_value: ADDON_DETAILS?.NCBSuper ? "Yes" : "No",
                                                },
                                                {
                                                  label: "Waiver",
                                                  label_value: ADDON_DETAILS?.field_CPW ? "Yes" : "No",
                                                },
                                                {
                                                  label: "OPD",
                                                  label_value: ADDON_DETAILS.field_OPD_data.value
                                                    ? ADDON_DETAILS.field_OPD_data.value
                                                    : ADDON_DETAILS?.field_OPD === false
                                                      ? "No"
                                                      : "",
                                                },
                                              ]
                                              : SELECTED_QUOTE_DATA.productDetails.id ===
                                                EProductId.ICICI_ELEVATE_PLAN
                                                ? [
                                                  {
                                                    label: "Critical Illness",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Critical_Illness
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Maternity Coverr",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Maternity
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Vaccination For New Born Baby",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Vaccination
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "New Born",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.New_Born
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Annual Health Checkup",
                                                    label_value:
                                                      ADDON_DETAILS_ELEVATE_ICICI?.Anuual_Health_Checkup
                                                        ? "Yes"
                                                        : "No",
                                                  },
                                                  {
                                                    label: "Domestic Air Ambulance",
                                                    label_value:
                                                      ADDON_DETAILS_ELEVATE_ICICI?.Domestic_Air_Ambulance
                                                        ? "Yes"
                                                        : "No",
                                                  },
                                                  {
                                                    label: "Dependent accommodation benefit",
                                                    label_value:
                                                      ADDON_DETAILS_ELEVATE_ICICI?.Dependent_Accomodation_benefit
                                                        ? "Yes"
                                                        : "No",
                                                  },
                                                  {
                                                    label: "Convalescence Benefit",
                                                    label_value:
                                                      ADDON_DETAILS_ELEVATE_ICICI?.Convalescence_Benefit
                                                        ? "Yes"
                                                        : "No",
                                                  },
                                                  {
                                                    label: "Durable medical equipment cover",
                                                    label_value:
                                                      ADDON_DETAILS_ELEVATE_ICICI?.Durable_Medical_Equipment_Cover
                                                        ? "Yes"
                                                        : "No",
                                                  },
                                                  {
                                                    label: "Worldwide Cover",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Worldwide_Cover
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Personal Accident",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Personal_Accident
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Sum Insured Protector",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.SI_Protector
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Claim Protector",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Claim_Protector
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Infinite Care",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Infinite_Care
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Compassionate Visit HP",
                                                    label_value:
                                                      ADDON_DETAILS_ELEVATE_ICICI?.Compassionate_Visit_HP
                                                        ? "Yes"
                                                        : "No",
                                                  },
                                                  {
                                                    label: "Nursing At Home HP",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Nursing_at_Home_HP
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "BeFit A",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.BeFit_A_HP
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "BeFit B",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.BeFit_B_HP
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "BeFit C",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.BeFit_C_HP
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Befit D",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.BeFit_D_HP
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Befit E",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.BeFit_E_HP
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Befit F",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.BeFit_F_HP
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Befit G",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.BeFit_G_HP
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Befit H",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.BeFit_H_HP
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Room Upgrade",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Room_Upgrade
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                  {
                                                    label: "Waiting Period",
                                                    label_value: ADDON_DETAILS_ELEVATE_ICICI?.Waiting_Period
                                                      ? "Yes"
                                                      : "No",
                                                  },
                                                ]
                                                : SELECTED_QUOTE_DATA.productDetails.id === 2551
                                                  ? [
                                                    {
                                                      label: "Claim Protector",
                                                      label_value: ADDON_DETAILS_ICIC?.Claim_Protector
                                                        ? "Yes"
                                                        : "No",
                                                    },
                                                    {
                                                      label: "Critical Illness",
                                                      label_value: showAddon
                                                        ? ""
                                                        : ADDON_DETAILS_ICIC?.Critical_Illness
                                                          ? "Yes"
                                                          : "No",
                                                    },
                                                    {
                                                      label: "Vaccination",
                                                      label_value: ADDON_DETAILS_ICIC?.Vaccination ? "Yes" : "No",
                                                    },
                                                    {
                                                      label: "Maternity",
                                                      label_value: ADDON_DETAILS_ICIC?.Maternity ? "Yes" : "No",
                                                    },
                                                    {
                                                      label: "SI Protector",
                                                      label_value: ADDON_DETAILS_ICIC?.SI_Protector ? "Yes" : "No",
                                                    },
                                                    {
                                                      label: "Compassionate Visit",
                                                      label_value: ADDON_DETAILS_ICIC?.Compassionate_Visit
                                                        ? "Yes"
                                                        : "No",
                                                    },
                                                    {
                                                      label: "Nursing At Home",
                                                      label_value: ADDON_DETAILS_ICIC?.Nursing_at_Home
                                                        ? "Yes"
                                                        : "No",
                                                    },
                                                    {
                                                      label: "personal_accident",
                                                      label_value: showAddon2
                                                        ? ""
                                                        : ADDON_DETAILS_ICIC?.personal_accident
                                                          ? "Yes"
                                                          : "No",
                                                    },
                                                    {
                                                      label: "New Born",
                                                      label_value: ADDON_DETAILS_ICIC?.New_Born ? "Yes" : "No",
                                                    },
                                                    {
                                                      label: "BeFit A",
                                                      label_value: ADDON_DETAILS_ICIC?.BeFit_A ? "Yes" : "No",
                                                    },
                                                    {
                                                      label: "BeFit B",
                                                      label_value: ADDON_DETAILS_ICIC?.BeFit_B ? "Yes" : "No",
                                                    },
                                                    {
                                                      label: "BeFit C",
                                                      label_value: ADDON_DETAILS_ICIC?.BeFit_C ? "Yes" : "No",
                                                    },
                                                    {
                                                      label: "BeFit D",
                                                      label_value: ADDON_DETAILS_ICIC?.BeFit_D ? "Yes" : "No",
                                                    },
                                                    // {
                                                    //   label: "BeFit E",
                                                    //   label_value: ADDON_DETAILS_ICIC?.BeFit_E ? "Yes" : "No",
                                                    // },
                                                    // {
                                                    //   label: "BeFit F",
                                                    //   label_value: ADDON_DETAILS_ICIC?.BeFit_F ? "Yes" : "No",
                                                    // },
                                                    // {
                                                    //   label: "BeFit G",
                                                    //   label_value: ADDON_DETAILS_ICIC?.BeFit_G ? "Yes" : "No",
                                                    // },
                                                    // {
                                                    //   label: "BeFit H",
                                                    //   label_value: ADDON_DETAILS_ICIC?.BeFit_H ? "Yes" : "No",
                                                    // },
                                                  ]
                                                  : SELECTED_QUOTE_DATA.productDetails.id === 2843
                                                    ? [
                                                      {
                                                        label: "Claim Protector",
                                                        label_value:
                                                          ADDON_DETAILS_GOLDEN_SHIELD_ICICI?.Claim_protector
                                                            ? "Yes"
                                                            : "No",
                                                      },
                                                      {
                                                        label: "Modification of Base Co-Payment",
                                                        label_value:
                                                          ADDON_DETAILS_GOLDEN_SHIELD_ICICI?.Modification_base_co_payment
                                                            ? "Yes"
                                                            : "No",
                                                      },
                                                      {
                                                        label: "Voluntary Deductible",
                                                        label_value:
                                                          ADDON_DETAILS_GOLDEN_SHIELD_ICICI?.Voluntary_deduction
                                                            ? "Yes"
                                                            : "No",
                                                      },
                                                      {
                                                        label: "Care Management Program",
                                                        label_value:
                                                          ADDON_DETAILS_GOLDEN_SHIELD_ICICI?.Care_management_program
                                                            ? "Yes"
                                                            : "No",
                                                      },
                                                      {
                                                        label: "Care Management Plus Program",
                                                        label_value:
                                                          ADDON_DETAILS_GOLDEN_SHIELD_ICICI?.Care_management_plus_program
                                                            ? "Yes"
                                                            : "No",
                                                      },
                                                    ]
                                                    : SELECTED_QUOTE_DATA.productDetails.id === 2763 ||
                                                      SELECTED_QUOTE_DATA.productDetails.id === 2111
                                                      ? [
                                                        {
                                                          label: "Daily Hospital Cash Rider",
                                                          label_value: ADDON_DETAILS?.hdcSa.value
                                                            ? ADDON_DETAILS?.hdcSa.value
                                                            : "No",
                                                        },
                                                        {
                                                          label: "Protector Rider",
                                                          label_value: ADDON_DETAILS?.critical_illness ? "Yes" : "No",
                                                        },
                                                        {
                                                          label: "Ipa RiderCheck",
                                                          label_value: ADDON_DETAILS.ipaRiderCheck
                                                            ? Number(SELECTED_QUOTE_DATA.premiumDetails.sum_insured) * 5
                                                            : "",
                                                        },
                                                        {
                                                          label: "unlimited RiderCheck",
                                                          label_value: ADDON_DETAILS?.unlimitedRiderCheck
                                                            ? "Yes"
                                                            : "No",
                                                        },
                                                        ,
                                                      ]
                                                      : SELECTED_QUOTE_DATA.productDetails.id === 2767 ||
                                                        SELECTED_QUOTE_DATA.productDetails.id === 2766 ||
                                                        SELECTED_QUOTE_DATA.productDetails.id === 2916
                                                        ? [
                                                          {
                                                            label: "Overseas Travel",
                                                            label_value: ADDON_DETAILS?.overseas_travel ? "Yes" : "No",
                                                          },
                                                          {
                                                            label: "IPA Rider",
                                                            label_value: ADDON_DETAILS?.ipa ? "Yes" : "No",
                                                          },
                                                          {
                                                            label: "Optima Well",
                                                            label_value: ADDON_DETAILS?.optima_well ? "Yes" : "No",
                                                          },
                                                          {
                                                            label: "IsUnlimited Restore",
                                                            label_value: ADDON_DETAILS?.IsUnlimitedRestore ? "Yes" : "No",
                                                          },
                                                          {
                                                            label: "Critical Illness",
                                                            label_value: ADDON_DETAILS?.Iscritical_Illness ? "Yes" : "No",
                                                          },
                                                          {
                                                            label: "health Hospital Cash Benefit",
                                                            label_value: ADDON_DETAILS?.health_hospital_cash_benefit
                                                              ? "Yes"
                                                              : "No",
                                                          },
                                                          {
                                                            label: "Global RIder",
                                                            label_value: ADDON_DETAILS?.isGlobalRider ? "Yes" : "No",
                                                          },
                                                          {
                                                            label: "Sum Insured",
                                                            label_value: ADDON_DETAILS?.Iscritical_Illness_sum_insured,
                                                          },
                                                          {
                                                            label: "Critical Illness Plan Type",
                                                            label_value: ADDON_DETAILS?.Iscritical_Illness
                                                              ? findLabelByValue(
                                                                ADDON_DETAILS?.Insured_CriticalIllnessAddonCover_PlanType
                                                              )
                                                              : "",
                                                          },
                                                          {
                                                            label: "Deduction value",
                                                            label_value: isDeduction_value,
                                                          },
                                                        ]
                                                        : SELECTED_QUOTE_DATA.productDetails.id === 2650
                                                          ? [
                                                            {
                                                              label: "IPA Rider",
                                                              label_value: ADDON_DETAILS?.ipa ? "Yes" : "No",
                                                            },
                                                            {
                                                              label: "IsUnlimited Restore",
                                                              label_value: ADDON_DETAILS?.IsUnlimitedRestore ? "Yes" : "No",
                                                            },
                                                            {
                                                              label: "Critical Illness",
                                                              label_value: ADDON_DETAILS?.Iscritical_Illness ? "Yes" : "No",
                                                            },
                                                            {
                                                              label: "health Hospital Cash Benefit",
                                                              label_value: ADDON_DETAILS?.health_hospital_cash_benefit
                                                                ? "Yes"
                                                                : "No",
                                                            },
                                                            {
                                                              label: "Global RIder",
                                                              label_value: ADDON_DETAILS?.isGlobalRider ? "Yes" : "No",
                                                            },
                                                            {
                                                              label: "Sum Insured",
                                                              label_value: ADDON_DETAILS?.Iscritical_Illness_sum_insured,
                                                            },
                                                            {
                                                              label: "Critical Illness Plan Type",
                                                              label_value: ADDON_DETAILS?.Iscritical_Illness
                                                                ? findLabelByValue(
                                                                  ADDON_DETAILS?.Insured_CriticalIllnessAddonCover_PlanType
                                                                )
                                                                : "",
                                                            },
                                                            {
                                                              label: "Deduction value",
                                                              label_value: isDeduction_value,
                                                            },
                                                          ]
                                                          : SELECTED_QUOTE_DATA.productDetails.id === 2592 ||
                                                            SELECTED_QUOTE_DATA.productDetails.id === 2593 ||
                                                            SELECTED_QUOTE_DATA.productDetails.id === 2594
                                                            ? [
                                                              {
                                                                label: "Hospital Cash",
                                                                label_value: ADDON_QUESTION_NIVA_BUPA?.MQ01.main_question
                                                                  .status
                                                                  ? "Yes"
                                                                  : "No",
                                                              },
                                                              {
                                                                label: "Acute Care Opted",
                                                                label_value: ADDON_QUESTION_NIVA_BUPA?.MQ02.main_question
                                                                  .status
                                                                  ? "Yes"
                                                                  : "No",
                                                              },
                                                              {
                                                                label: "Safeguard Opted",
                                                                label_value: ADDON_QUESTION_NIVA_BUPA?.MQ03.main_question
                                                                  .status
                                                                  ? "Yes"
                                                                  : "No",
                                                              },
                                                              {
                                                                label: "Safeguard+ Opted",
                                                                label_value: ADDON_QUESTION_NIVA_BUPA?.MQ04.main_question
                                                                  .status
                                                                  ? "Yes"
                                                                  : "No",
                                                              },
                                                              {
                                                                label: "Personal Accident Cover",
                                                                label_value: ADDON_QUESTION_NIVA_BUPA?.MQ05.main_question
                                                                  .status
                                                                  ? "Yes"
                                                                  : "No",
                                                              },
                                                              {
                                                                label: "Disease Management",
                                                                label_value: ADDON_QUESTION_NIVA_BUPA?.MQ06.main_question
                                                                  .status
                                                                  ? "Yes"
                                                                  : "No",
                                                              },
                                                            ]
                                                            : SELECTED_QUOTE_DATA.productDetails.id === 2943 ||
                                                              SELECTED_QUOTE_DATA.productDetails.id === 2944 ||
                                                              SELECTED_QUOTE_DATA.productDetails.id === 2945 ||
                                                              SELECTED_QUOTE_DATA.productDetails.id === 2946
                                                              ? [
                                                                {
                                                                  label: "Hospital Cash",
                                                                  label_value: ADDON_QUESTION_NIVA_BUPA?.MQ01.main_question
                                                                    .status
                                                                    ? "Yes"
                                                                    : "No",
                                                                },
                                                                {
                                                                  label: "Acute Care Opted",
                                                                  label_value: ADDON_QUESTION_NIVA_BUPA?.MQ02.main_question
                                                                    .status
                                                                    ? "Yes"
                                                                    : "No",
                                                                },
                                                                {
                                                                  label: "Safeguard+ Opted",
                                                                  label_value: ADDON_QUESTION_NIVA_BUPA?.MQ04.main_question
                                                                    .status
                                                                    ? "Yes"
                                                                    : "No",
                                                                },
                                                                {
                                                                  label: "Personal Accident Cover",
                                                                  label_value: ADDON_QUESTION_NIVA_BUPA?.MQ05.main_question
                                                                    .status
                                                                    ? "Yes"
                                                                    : "No",
                                                                },
                                                                {
                                                                  label: "Disease Management",
                                                                  label_value: ADDON_QUESTION_NIVA_BUPA?.MQ06.main_question
                                                                    .status
                                                                    ? "Yes"
                                                                    : "No",
                                                                },
                                                                {
                                                                  label: "Future Ready Premium",
                                                                  label_value: ADDON_QUESTION_NIVA_BUPA?.MQ10?.main_question
                                                                    ?.status
                                                                    ? "Yes"
                                                                    : "No",
                                                                },
                                                              ]
                                                              : SELECTED_QUOTE_DATA.productDetails.id === 2890 ||
                                                                SELECTED_QUOTE_DATA.productDetails.id === 2891 ||
                                                                SELECTED_QUOTE_DATA.productDetails.id === 2892 ||
                                                                SELECTED_QUOTE_DATA.productDetails.id === 2893
                                                                ? [
                                                                  {
                                                                    label: "Safeguard Opted",
                                                                    label_value: ADDON_QUESTION_NIVA_BUPA?.MQ03.main_question
                                                                      .status
                                                                      ? "Yes"
                                                                      : "No",
                                                                  },
                                                                ]
                                                                : SELECTED_QUOTE_DATA.productDetails.id === 98 ||
                                                                  SELECTED_QUOTE_DATA.productDetails.id === 99 ||
                                                                  SELECTED_QUOTE_DATA.productDetails.id === 100
                                                                  ? [
                                                                    {
                                                                      label: "Consumable Items Coverage Opted",
                                                                      label_value: ADDON_DETAILS_RSA?.consumableItemsCoverageOpted
                                                                        ? "Yes"
                                                                        : "No",
                                                                    },
                                                                    {
                                                                      label: "Deductible Amount",
                                                                      label_value: ADDON_DETAILS_RSA?.deductibleAmount
                                                                        ? "Yes"
                                                                        : "No",
                                                                    },
                                                                    {
                                                                      label: "Hospital Cashbenefit Opted",
                                                                      label_value: ADDON_DETAILS_RSA?.hospitalCashbenefitOpted
                                                                        ? "Yes"
                                                                        : "No",
                                                                    },
                                                                    {
                                                                      label: "International Treatment Opted",
                                                                      label_value: ADDON_DETAILS_RSA?.internationalTreatmentOpted
                                                                        ? "Yes"
                                                                        : "No",
                                                                    },
                                                                    {
                                                                      label: "Is-Voluntary Co-Payment Selected",
                                                                      label_value: ADDON_DETAILS_RSA?.isVoluntaryCoPaymentSelected
                                                                        ? "Yes"
                                                                        : "No",
                                                                    },
                                                                    {
                                                                      label: "Opt TopUp Opted",
                                                                      label_value: ADDON_DETAILS_RSA?.opttopupOpted ? "Yes" : "No",
                                                                    },

                                                                    {
                                                                      label: "TopUp Opted Count",
                                                                      label_value: ADDON_DETAILS_RSA?.topUpOptedCount
                                                                        ? "Yes"
                                                                        : "No",
                                                                    },
                                                                    {
                                                                      label: "Voluntary Co-Payment Percentage",
                                                                      label_value: ADDON_DETAILS_RSA?.voluntaryCoPaymentPercentage
                                                                        ? "Yes"
                                                                        : "No",
                                                                    },
                                                                    // Exclude "Elite Plus Cover Selected" for id === 99 and id === 100
                                                                    SELECTED_QUOTE_DATA.productDetails.id !== 99 &&
                                                                      SELECTED_QUOTE_DATA.productDetails.id !== 98
                                                                      ? {
                                                                        label: "Elite Plus Cover Selected",
                                                                        label_value: ADDON_DETAILS_RSA?.elitePlusCoverSelected
                                                                          ? "Yes"
                                                                          : "No",
                                                                      }
                                                                      : null,
                                                                    // Exclude "Supreme Plus Cover Selected" for id === 98 and id === 100
                                                                    SELECTED_QUOTE_DATA.productDetails.id !== 98 &&
                                                                      SELECTED_QUOTE_DATA.productDetails.id !== 100
                                                                      ? {
                                                                        label: "Supreme Plus Cover Selected",
                                                                        label_value: ADDON_DETAILS_RSA?.supremePlusCoverSelected
                                                                          ? "Yes"
                                                                          : "No",
                                                                      }
                                                                      : null,
                                                                  ]
                                                                  : SELECTED_QUOTE_DATA.productDetails.id === 2902 ||
                                                                    SELECTED_QUOTE_DATA.productDetails.id === 2903 ||
                                                                    SELECTED_QUOTE_DATA.productDetails.id === 2904 ||
                                                                    SELECTED_QUOTE_DATA.productDetails.id === 2905 ||
                                                                    SELECTED_QUOTE_DATA.productDetails.id === 2906 ||
                                                                    SELECTED_QUOTE_DATA.productDetails.id === 2907
                                                                    ? [
                                                                      // {
                                                                      //   label: "Second medical opinion for listed major illness",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.second_medical_opinion_for_listed_major_illness
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Compassionate visit",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.compassionate_visit
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      {
                                                                        label: "Durable equipment cover",
                                                                        label_value:
                                                                          ADDON_DETAILS_ADITYA_BIRLA?.durable_equipment_cover
                                                                            ? "Yes"
                                                                            : "No",
                                                                      },
                                                                      {
                                                                        label: "Cancer booster",
                                                                        label_value: ADDON_DETAILS_ADITYA_BIRLA?.cancer_booster
                                                                          ? "Yes"
                                                                          : "No",
                                                                      },
                                                                      // {
                                                                      //   label: "Reduction in specific disease waiting period",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.reduction_in_specific_disease_waiting_period
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      {
                                                                        label: "Reduction in pre existing disease waiting period",
                                                                        label_value:
                                                                          ADDON_DETAILS_ADITYA_BIRLA?.reduction_in_pre_existing_disease_waiting_period
                                                                            ? "Yes"
                                                                            : "No",
                                                                      },

                                                                      {
                                                                        label: "Room rent type options",
                                                                        label_value:
                                                                          ADDON_DETAILS_ADITYA_BIRLA?.room_rent_type_options
                                                                            ? "Yes"
                                                                            : "No",
                                                                      },
                                                                      // {
                                                                      //   label: "Per claim deductible",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.per_claim_deductible
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "In patient hospitalization",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.in_patient_hospitalization
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Road ambulance cover per hospitalization",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.road_ambulance_cover_per_hospitalization
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Modern procedures treatments",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.modern_procedures_treatments
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Hiv aids and std over",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.hiv_aids_and_std_over
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Mental illness hospitalization",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.mental_illness_hospitalization
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Obesity treatment",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.obesity_treatment
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Pre hospitalization expenses",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.pre_hospitalization_expenses
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Post hospitalization expenses",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.post_hospitalization_expenses
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Domiciliary hospitalization",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.domiciliary_hospitalization
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Home health care",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.home_health_care
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Ayush treatment",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.ayush_treatment
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Organ donor expenses",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.organ_donor_expenses
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Super reload",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.super_reload
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Health assessmentTM",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.healthReturnsTM
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Health returnsTM",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.healthReturnsTM
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Claim protect non medical",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.claim_protect_non_medical
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Preferred provider network",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.preferred_provider_network
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      {
                                                                        label: "Critical illness cover",
                                                                        label_value:
                                                                          ADDON_DETAILS_ADITYA_BIRLA?.critical_illness_cover
                                                                            ? "Yes"
                                                                            : "No",
                                                                      },
                                                                      {
                                                                        label: "Personal accident cover",
                                                                        label_value:
                                                                          ADDON_DETAILS_ADITYA_BIRLA?.personal_accident_cover
                                                                            ? "Yes"
                                                                            : "No",
                                                                      },
                                                                      // {
                                                                      //   label: "Super credit increases irrespective of claim",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.Super_credit_increases_irrespective_of_claim
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },

                                                                      {
                                                                        label: "Chronic management program",
                                                                        label_value:
                                                                          ADDON_DETAILS_ADITYA_BIRLA?.chronic_management_program
                                                                            ? "Yes"
                                                                            : "No",
                                                                      },
                                                                      // {
                                                                      //   label: "Chronic care restriction",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.chronic_care_restriction
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label:
                                                                      //     "Annual screening package for cancer diagnosed patients",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.annual_screening_package_for_cancer_diagnosed_patients
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Annual health check up",
                                                                      //   label_value:
                                                                      //     ADDON_DETAILS_ADITYA_BIRLA?.annual_health_check_up
                                                                      //       ? "Yes"
                                                                      //       : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Vaccine cover",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.vaccine_cover
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Tele OPD consultation",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.tele_OPD_consultation
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                      // {
                                                                      //   label: "Opd add on",
                                                                      //   label_value: ADDON_DETAILS_ADITYA_BIRLA?.opd_add_on
                                                                      //     ? "Yes"
                                                                      //     : "No",
                                                                      // },
                                                                    ]
                                                                    : SELECTED_QUOTE_DATA.productDetails.id === 2908
                                                                      ? [
                                                                        {
                                                                          label: "Durable equipment cover",
                                                                          label_value:
                                                                            ADDON_DETAILS_ADITYA_BIRLA?.durable_equipment_cover
                                                                              ? "Yes"
                                                                              : "No",
                                                                        },
                                                                        {
                                                                          label: "Cancer booster",
                                                                          label_value: ADDON_DETAILS_ADITYA_BIRLA?.cancer_booster
                                                                            ? "Yes"
                                                                            : "No",
                                                                        },
                                                                        {
                                                                          label: "Reduction in pre existing disease waiting period",
                                                                          label_value:
                                                                            ADDON_DETAILS_ADITYA_BIRLA?.reduction_in_pre_existing_disease_waiting_period
                                                                              ? "Yes"
                                                                              : "No",
                                                                        },
                                                                        {
                                                                          label: "Room rent type options",
                                                                          label_value:
                                                                            ADDON_DETAILS_ADITYA_BIRLA?.room_rent_type_options
                                                                              ? "Yes"
                                                                              : "No",
                                                                        },
                                                                        {
                                                                          label: "Critical illness cover",
                                                                          label_value:
                                                                            ADDON_DETAILS_ADITYA_BIRLA?.critical_illness_cover
                                                                              ? "Yes"
                                                                              : "No",
                                                                        },
                                                                        {
                                                                          label: "Personal accident cover",
                                                                          label_value:
                                                                            ADDON_DETAILS_ADITYA_BIRLA?.personal_accident_cover
                                                                              ? "Yes"
                                                                              : "No",
                                                                        },
                                                                        {
                                                                          label: "Chronic management program",
                                                                          label_value:
                                                                            ADDON_DETAILS_ADITYA_BIRLA?.chronic_management_program
                                                                              ? "Yes"
                                                                              : "No",
                                                                        },
                                                                        {
                                                                          label: "Chronic care",
                                                                          label_value: ADDON_DETAILS_ADITYA_BIRLA?.chronic_care
                                                                            ? "Yes"
                                                                            : "No",
                                                                        },
                                                                      ]
                                                                      : SELECTED_QUOTE_DATA.productDetails.id === 2133
                                                                        ? [
                                                                          {
                                                                            label: "Air Ambulance Cover",
                                                                            label_value: ADDON_DETAILS?.AirAmbulanceCover ? "Yes" : "No",
                                                                          },
                                                                          {
                                                                            label: "Care Shield",
                                                                            label_value: ADDON_DETAILS?.CareShield ? "Yes" : "No",
                                                                          },
                                                                          {
                                                                            label: "Global Coverage",
                                                                            label_value:
                                                                              ADDON_DETAILS?.GlobalCoverage.value === ""
                                                                                ? "No"
                                                                                : ADDON_DETAILS?.GlobalCoverage.value,
                                                                          },
                                                                          {
                                                                            label: "NCB Super",
                                                                            label_value: ADDON_DETAILS?.NCBSuper ? "Yes" : "No",
                                                                          },
                                                                          {
                                                                            label: "Room Rent",
                                                                            label_value: ADDON_DETAILS?.field_34 ? "Yes" : "No",
                                                                          },
                                                                          {
                                                                            label: "Smart Select",
                                                                            label_value: ADDON_DETAILS?.SmartSelect ? "Yes" : "No",
                                                                          },
                                                                          {
                                                                            label: "Unlimited Automatic Recharge",
                                                                            label_value: ADDON_DETAILS?.field_UAR ? "Yes" : "No",
                                                                          },
                                                                          {
                                                                            label: "Care OPD",
                                                                            label_value: ADDON_DETAILS?.field_OPD ? "Yes" : "No",
                                                                          },
                                                                          {
                                                                            label: "Annual Health Check-up",
                                                                            label_value: ADDON_DETAILS?.field_AHC ? "Yes" : "No",
                                                                          },
                                                                          {
                                                                            label: "Reduction in PED",
                                                                            label_value: ADDON_DETAILS?.field_43 ? "Yes" : "No",
                                                                          },
                                                                          {
                                                                            label: "Co-Payment",
                                                                            label_value:
                                                                              calculateAge(
                                                                                FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
                                                                              ) >= 61 ||
                                                                                calculateAge(
                                                                                  FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
                                                                                ) >= 61
                                                                                ? ADDON_DETAILS?.field_COPAY
                                                                                : "",
                                                                          },
                                                                        ]
                                                                        : null,
      },
      {
        title: "Address Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}`,
        detailView: [
          {
            label: "H.No./Building",
            label_value: ADDRESS_DETAILS.addressLineOne.value,
          },
          {
            label: "Street/Area",
            label_value:
              SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G015"
                ? FIND_LABEL_OF_VALUE(
                  AREA_TOWN,
                  ADDRESS_DETAILS.addressLineTwo.value
                )
                : ADDRESS_DETAILS.addressLineTwo.value,
          },
          {
            label: "City",
            label_value: ADDRESS_DETAILS.city,
          },
          {
            label: "Pincode",
            label_value: ADDRESS_DETAILS.pincode.value,
          },
        ],
        mailing_detail_view: ADDRESS_DETAILS.isMailingAddressSame
          ? null
          : [
            {
              label: "H.No./Building",
              label_value: ADDRESS_DETAILS.mailingAddressLineOne.value,
            },
            {
              label: "Street/Area",
              label_value: ADDRESS_DETAILS.mailingAddressLineTwo.value,
            },
            {
              label: "City",
              label_value: ADDRESS_DETAILS.mailingCity,
            },
            {
              label: "State",
              label_value: ADDRESS_DETAILS.mailingState,
            },
            {
              label: "Pincode",
              label_value: ADDRESS_DETAILS.mailingPinCode.value,
            },
          ],
      },
      // {
      //   title: "Proposer's Bank Details",
      //   url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}`,
      //   detailView:
      //     SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.CARE
      //       ? [
      //           {
      //             label: "Bank Account No.",
      //             label_value: BANK_DETAILS_CARE.BankAccountNumber.value,
      //           },
      //           {
      //             label: "IFSC Code",
      //             label_value: BANK_DETAILS_CARE.IFSCCode.value,
      //           },
      //         ]
      //       : [],
      // },
      {
        title: "Proposer's Bank Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}`,
        detailView:
          SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA
            ? [
              {
                label: "Bank Account No.",
                label_value: BANK_DETAILS_NIVABUPA.BankAccountNumber.value,
              },
              {
                label: "Bank Account Type",
                label_value: BANK_DETAILS_NIVABUPA.BankAccountType.value,
              },
              {
                label: "Bank Name",
                label_value: BANK_DETAILS_NIVABUPA.BankName.value,
              },
              {
                label: "IFSC Code",
                label_value: BANK_DETAILS_NIVABUPA.IFSCCode.value,
              },
              {
                label: "Customer Bank Branch ",
                label_value: BANK_DETAILS_NIVABUPA.CustomerBankBranch.value,
              },
              {
                label: "Bank City",
                label_value: BANK_DETAILS_NIVABUPA.BankCity.value,
              },
            ]
            : null,
      },
      {
        title: "Proposer's Bank Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}`,
        detailView:
          SELECTED_QUOTE_DATA.productDetails.id === 2947
            ? [
              {
                label: "Name in Bank Account",
                label_value:
                  BANK_DETAILS_SUPERSTAR.BankAccountHolderName.value,
              },
              {
                label: "Bank Account No.",
                label_value: BANK_DETAILS_SUPERSTAR.BankAccountNumber.value,
              },
              {
                label: "Bank Account Type",
                label_value: BANK_DETAILS_SUPERSTAR.BankAccountType.value,
              },
              {
                label: "Bank Name",
                label_value: BANK_DETAILS_SUPERSTAR.BankName.value,
              },
              {
                label: "IFSC Code",
                label_value: BANK_DETAILS_SUPERSTAR.IFSCCode.value,
              },
              {
                label: "Customer Bank Branch ",
                label_value: BANK_DETAILS_SUPERSTAR.CustomerBankBranch.value,
              },
              {
                label: "Bank City",
                label_value: BANK_DETAILS_SUPERSTAR.BankCity.value,
              },
            ]
            : null,
      },
      {
        title: "Proposer's Bank Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}`,
        detailView:
          SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.HDFC_HEALTH
            ? [
              {
                label: "Name in Bank Account",
                label_value: BANK_DETAILS_HDFC.Name_In_Bank_Account.value,
              },
              {
                label: "Bank Account No.",
                label_value: BANK_DETAILS_HDFC.Bank_Account_No.value,
              },
              {
                label: "Bank Name",
                label_value: BANK_DETAILS_HDFC.Bank_Name.value,
              },
              {
                label: "IFSC Code",
                label_value: BANK_DETAILS_HDFC.IFSC_Code.value,
              },
              {
                label: "Cheque No.",
                label_value: BANK_DETAILS_HDFC.Cheque_No.value,
              },
              {
                label: "Cheque Date",
                label_value: BANK_DETAILS_HDFC.Cheque_Date.value,
              },
              {
                label: "Cheque Amount For",
                label_value: BANK_DETAILS_HDFC.Cheque_Amount_For.value,
              },
              {
                label: "MICR Code",
                label_value: BANK_DETAILS_HDFC.MICR_Code.value,
              },
              {
                label: "Branch Name",
                label_value: BANK_DETAILS_HDFC.Branch_Name.value,
              },
            ]
            : [],
      },
      {
        title: "Proposer's Bank Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}`,
        detailView:
          SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.ADITY_BIRLA
            ? [
              {
                label: "Bank Account No.",
                label_value: BANK_DETAILS_ADITYABIRLA.Bank_Account_No.value,
              },
              {
                label: "Bank Account Type",
                label_value: BANK_DETAILS_ADITYABIRLA.Bank_Account_Type.value,
              },
              {
                label: "Bank Name",
                label_value: BANK_DETAILS_ADITYABIRLA.Bank_Name.value,
              },
              {
                label: "IFSC Code",
                label_value: BANK_DETAILS_ADITYABIRLA.IFSC_Code.value,
              },
              {
                label: "Branch Name",
                label_value: BANK_DETAILS_ADITYABIRLA.Branch_Name.value,
              },
              {
                label: "MICR Code",
                label_value: BANK_DETAILS_ADITYABIRLA.MICR_Code.value,
              },
            ]
            : [],
      },
      {
        title: "Proposer's Bank Details",
        url: `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}`,
        detailView:
          SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.CIGNA
            ? [
              {
                label: "Account Number",
                label_value: BANK_DETAILS_CIGNA.AccountNum.value,
              },
              {
                label: "Account Holder Name",
                label_value: BANK_DETAILS_CIGNA.AccountHolderName.value,
              },
              {
                label: "Account Category",
                label_value: BANK_DETAILS_CIGNA.AccountCategoryCd.value,
              },
              {
                label: "Bank Account Type",
                label_value: BANK_DETAILS_CIGNA.AccountTypeCd.value,
              },
              {
                label: "IFSC Code",
                label_value: BANK_DETAILS_CIGNA.IfscCd.value,
              },
              {
                label: "Bank Code",
                label_value: BANK_DETAILS_CIGNA.BankCd.value,
              },
              {
                label: "Bank Name",
                label_value: BANK_DETAILS_CIGNA.BankName.value,
              },
              {
                label: "Branch Name",
                label_value: BANK_DETAILS_CIGNA.BranchName.value,
              },
              {
                label: "Ledger Folio Number",
                label_value: BANK_DETAILS_CIGNA.LedgerFolioNum.value,
              },
              {
                label: "Card Type",
                label_value: BANK_DETAILS_CIGNA.CardTypeCd.value,
              },
              {
                label: "Card Expiry Date",
                label_value: BANK_DETAILS_CIGNA.CardExpiryDt.value,
              },
              {
                label: "State",
                label_value: BANK_DETAILS_CIGNA.State.value,
              },
              {
                label: "City",
                label_value: BANK_DETAILS_CIGNA.City.value,
              },
              {
                label: "MICR No",
                label_value: BANK_DETAILS_CIGNA.MicrNum.value,
              },
              {
                label: "",
                label_value: BANK_DETAILS_CIGNA.BranchCd.value,
              },
              {
                label: "",
                label_value: BANK_DETAILS_CIGNA.SortCd.value,
              },
            ]
            : [],
      },
    ]);
  }, [Health]);

  const {
    ADD_FORM,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    ADDRESS_DETAILS,
    INSURED_MEMBER_DETAILS,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const dispatch = useAppDispatch();
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const [loader, setLoader] = useState<boolean>(false);
  const [paymentForm, setPaymentForm] = useState<any>("");

  const [paymentFormData, setPaymentFormData] = useState<any>();
  const CIS_URL_DATA = (propsal: any, TransId: any) => {
    const onSuccess = (res: any) => {
      setLoader(false);
      setOpenRedirectPopup(true);
      dispatch(HealthSlice.actions.CIS_DATA(res.response));
      // navigate(`${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PAYMENT_CIS}`);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };

    let param: any = {
      ProposalNumber: propsal,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      TransactionID: TransId,
      product_id: SELECTED_QUOTE_DATA.productDetails.id,
    };
    HEALTH_PROPOSAL_SERVICES.GET_CIS_URL(onSuccess, onError, param);
  };

  const CREATE_PROPOSAL = () => {
    const onSuccess = (res: any) => {
      const error = res.error;
      dispatch(HealthSlice.actions.PAYMENT_DATA(res.response));

      if (error) {
        const message = res.message;
        toast.error(`${message}`);
      } else {
        const response = res.response;

        const paymentRequest = response["paymentRequest"];
        const actionURL = paymentRequest["actionURL"];
        const actionMethod = paymentRequest["actionMethod"];

        let payment_gateway_url = "";

        if (actionMethod === "POST") {
          let form = document.createElement("form");
          form.action = actionURL;
          form.method = actionMethod;

          Object.entries(paymentRequest).forEach(([key, value]) => {
            if (key !== "actionURL" && key !== "actionMethod") {
              const input = document.createElement("input");
              input.type = "hidden";
              input.name = key;
              input.value = `${value}`;
              form.appendChild(input);
            }
          });

          document.body.appendChild(form);
          if (
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.HDFC_HEALTH
          ) {
            CIS_URL_DATA(res.response.proposal_no, res.response.transactionID);
            setPaymentFormData(res?.response);
            setPaymentForm(form);
          } else {
            setLoader(false);
            form.submit();
          }
        } else if (`${actionMethod.toLowerCase()}` === "get") {
          if (
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA
          ) {
            let form = document.createElement("form");
            form.action = actionURL;
            form.method = actionMethod;

            Object.entries(paymentRequest).forEach(([key, value]) => {
              if (key !== "actionURL" && key !== "actionMethod") {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = `${value}`;
                form.appendChild(input);
              }
            });

            document.body.appendChild(form);
            setLoader(false);
            form.submit();
          } else {
            window.location.href = actionURL;
          }
        } else {
          const response = res.response;
          if (actionMethod !== "POST") {
            const message = response.err_description;
            toast.error(`${message}`);
            return;
          } else {
            payment_gateway_url = actionURL;
            window.location.href = actionURL;
          }
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };
    let params: any = HEALTH_CREATE_PROPOSAL_REQUEST(
      Health,
      CKYC,
      CommonDropdownData
    );
    if (
      SELECTED_QUOTE_DATA?.CompanyDetails?.company_code === ECompanyCode.BAJAJ
    ) {
      const windowURL = window.location.search;
      const params = new URLSearchParams(windowURL);
      const cus_type = params.get("cus_type");

      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
      );
    } else {
      setLoader(true);
      HEALTH_PROPOSAL_SERVICES.makePayment(onSuccess, onError, {
        ...params,
        kycDetails: {
          ...CKYC_DETAILS,
          kycPincode: CKYC_DETAILS.kycPincode || ADDRESS_DETAILS.pincode.value,
        },
        product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
      });
    }
  };

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.CompanyDetails.company_code) {
      setLoader(true);
      const onSuccess = (res: any) => {
        if (res.error === false) {
          let quoteData = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.response.premiumDetails.finalPremium,
              transactionid: res.response.premiumDetails.transactionid,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
          dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
          setLoader(false);
        }
        FinalPremium();
      };
      const onError = (err: any) => {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
        setLoader(false);
        console.log("err..", err);
      };
      let param: any = {
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        quote_no: ADD_FORM_RESPONSE.quote_no,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        medical_info: "No",
      };
      setTimeout(() => {
        HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      }, 2000);
    }
  }, [ADDRESS_DETAILS.mailingPinCode.value]);

  const [openRedirectPopup, setOpenRedirectPopup] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [otpValue, setOtpValue] = useState("");

  const handleContinue = () => {
    GENERATE_OTP();
  };

  // START GENERATE_OTP***********************************
  const GENERATE_OTP = () => {
    const onSuccess = (res: any) => {
      const results = res;
      setShowOTPPopup(true);
    };

    const onError = (err: any) => {
      console.log("error", err);
    };

    let param = {
      product_id: `${SELECTED_QUOTE_DATA?.productDetails.id} `,
      customerName: `${INSURED_MEMBER_DETAILS?.PROPOSER_DETAILS.name.value} `,
      emailId: `${INSURED_MEMBER_DETAILS?.PROPOSER_DETAILS.email.value} `,
      mobileNo: `${ADD_FORM.mobile.value} `,
      quote_no: `${ADD_FORM_RESPONSE?.quote_no} `,
      // quoteId: `${ SELECTED_QUOTE_DATA?.premiumDetails?.RSQUOTEID } `,
    };

    HEALTH_PROPOSAL_SERVICES.GENERATE_OTP(onSuccess, onError, param);
  };
  // END GENERATE_OTP***********************************
  // START VERIFY_OTP**********************************
  const VERIFY_OTP = () => {
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setShowOTPPopup(false);
        setOtpValue("");
        CREATE_PROPOSAL();
      } else {
        toast.error("Please Enter Valid OTP");
      }
    };

    const onError = (err: any) => {
      console.log("error", err);
    };

    let param = {
      quote_no: `${ADD_FORM_RESPONSE?.quote_no} `,
      product_id: `${SELECTED_QUOTE_DATA?.productDetails.id} `,
      message: otpValue,
      customerName: `${INSURED_MEMBER_DETAILS?.PROPOSER_DETAILS.name.value} `,
      emailId: `${INSURED_MEMBER_DETAILS?.PROPOSER_DETAILS.email.value} `,
      mobileNo: `${ADD_FORM.mobile.value} `,
    };

    // Call API service to generate OTP
    HEALTH_PROPOSAL_SERVICES.VERIFY_OTP(onSuccess, onError, param);
    // setOtpValue("");
  };
  // END VERIFY_OTP***********************************

  useEffect(() => {
    dispatch(HealthSlice.actions.CHANGE_PAGE_STATUS(true));
  }, []);

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
      setLoader(false);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  const getOccupationData = () => {
    const onSuccess = (res: any) => {
      let data: TDropdown[] = res?.response?.map((maritalData: any) => ({
        value:
          SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G023"
            ? `${maritalData.code}-${maritalData.title}`
            : maritalData.code,
        label: maritalData.title,
      }));
      setOpccupationList(data);
      dispatch(
        HealthSlice.actions.BULK_UPLOAD({
          ...Health,
          DROPDOWN_DATA: {
            ...Health.DROPDOWN_DATA,
            OCCUPATION: data,
          },
        })
      );
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      product_type_code: SELECTED_QUOTE_DATA?.productDetails?.product_type_code,
    };
    HEALTH_PROPOSAL_SERVICES.getOccupationData(onSuccess, onError, param);
  };

  useEffect(() => {
    if (
      SELECTED_QUOTE_DATA?.CompanyDetails.company_code !== ECompanyCode.CARE
    ) {
      getOccupationData();
    }
  }, [SELECTED_QUOTE_DATA?.productDetails?.product_code]);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.STAR) {
      if (CKYC_DETAILS.kycAddress3) {
        GET_AREA_BY_CITY();
      }
    }
  }, [
    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
    CKYC_DETAILS.kycAddress3,
  ]);

  const GET_AREA_BY_CITY = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        const response = results.response?.area;
        const manupulated_data: TDropdown[] = [];
        for (const data of response) {
          manupulated_data.push({ value: data.areaID, label: data.areaName });
        }
        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: "AREA_TOWN",
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => { };
    const pincode_params = CKYC_DETAILS.kycPincode;
    const city_params = CKYC_DETAILS.kycAddress3;

    CKYCServcies.GET_AREA_BY_CITY(
      onSuccess,
      onError,
      pincode_params,
      city_params
    );
  };

  return <>
    {loader && <Box
      position="fixed"
      height="100%"
      width="100%"
      zIndex="991"
      top="0px"
      left="0px"
    />}
    {
      isMobile ? (
        <MReviewDetails
          PreviewDetails={previewDetails}
          navigate={navigate}
          VERIFY_OTP={VERIFY_OTP}
          CREATE_PROPOSAL={CREATE_PROPOSAL}
          loader={loader}
          handleContinue={handleContinue}
          openRedirectPopup={openRedirectPopup}
          setOpenRedirectPopup={setOpenRedirectPopup}
          paymentForm={paymentForm}
          paymentFormData={paymentFormData}
          showOTPPopup={showOTPPopup}
          setShowOTPPopup={setShowOTPPopup}
          setOtpValue={setOtpValue}
          otpValue={otpValue}
        />
      ) : (
        <PreviewDetails
          PreviewDetails={previewDetails}
          navigate={navigate}
          VERIFY_OTP={VERIFY_OTP}
          CREATE_PROPOSAL={CREATE_PROPOSAL}
          loader={loader}
          handleContinue={handleContinue}
          openRedirectPopup={openRedirectPopup}
          setOpenRedirectPopup={setOpenRedirectPopup}
          paymentForm={paymentForm}
          paymentFormData={paymentFormData}
          showOTPPopup={showOTPPopup}
          setShowOTPPopup={setShowOTPPopup}
          setOtpValue={setOtpValue}
          otpValue={otpValue}
        />)
    }
  </>
}
