import { TCarAddonStatus } from "../types/Car/TCarAddonStatus";
import { TCarSingleQuoteDetail } from "../types/Car/TCarSingleQuoteDetail";
import { calculate_passenger_cover } from "./HelpingFunction";

function parse_value(value: any): number {
  if (isNaN(parseFloat(value))) {
    return 0;
  } else {
    return parseFloat(value);
  }
}

export function RSA_DISCOUNT_SELECTION(
  ADDON_STATUS: TCarAddonStatus,
  quote_details: TCarSingleQuoteDetail | undefined
) {
  let discount_percentage: number = 0;
  const premium_details = quote_details?.premiumDetails;
  const zero_depth: boolean =
    ADDON_STATUS?.depreciation_cover?.value &&
    Number(premium_details?.depreciationCover) > 0;
  const ncb_cover: boolean =
    ADDON_STATUS?.ncb_cover?.value &&
    Number(premium_details?.noClaimBonusSameSlab) > 0;
  const invoice_cover: boolean =
    ADDON_STATUS?.invoice_cover?.value &&
    Number(premium_details?.invoicePriceCover) > 0;
  const consumable_cover: boolean =
    ADDON_STATUS?.consumable_cover?.value &&
    Number(premium_details?.consumablesCover) > 0;
  const personal_belonging_cover: boolean =
    ADDON_STATUS?.personalBelonging?.value &&
    Number(premium_details?.personalBelonging) > 0;
  const key_replacement_cover: boolean =
    ADDON_STATUS?.key_replacement?.value &&
    Number(premium_details?.keyReplacementCover) > 0;
  const tyre_cover: boolean =
    ADDON_STATUS?.tyre_cover?.value &&
    Number(premium_details?.tyreSecureCover) > 0;

  let addon_count = [
    ncb_cover,
    invoice_cover,
    consumable_cover,
    personal_belonging_cover,
    key_replacement_cover,
    tyre_cover,
  ].filter(Boolean).length;

  if (zero_depth) {
    if (addon_count === 1) {
      discount_percentage = 10; // 10% discount
    } else if (addon_count === 2) {
      discount_percentage = 15; // 15% discount
    } else if (addon_count > 2) {
      discount_percentage = 20; // 20% discount
    }
  }

  return discount_percentage;
}

export function CarPremiumCalRSA(
  quote_details: TCarSingleQuoteDetail | undefined,
  ADDON_STATUS: TCarAddonStatus,
  passenger_cover_user_value: string,
  PLAN_TYPE: string,
  OWNERSHIP: "Individual" | "Organization",
  reg_date: string
) {
  let premium: number = 0;
  let final_premium: number = 0;

  if (quote_details === undefined) return 0;

  const netPremium = parse_value(quote_details.premiumDetails.netPremium);
  const paOwnerDriver = parse_value(quote_details.premiumDetails.paOwnerDriver);
  const depreciationCover = parse_value(
    quote_details.premiumDetails.depreciationCover
  );
  // const withDiscountdepreciationCover = parse_value(
  //   quote_details.premiumDetails.withDiscountdepreciationCover
  // );
  const invoicePriceCover = parse_value(
    quote_details.premiumDetails.invoicePriceCover
  );
  // const withDiscountinvoicePriceCover = parse_value(
  //   quote_details.premiumDetails.withDiscountinvoicePriceCover
  // );
  const roadSideAssistance = parse_value(
    quote_details.premiumDetails.roadSideAssistance
  );
  // const withDiscountroadSideAssistance = parse_value(
  //   quote_details.premiumDetails.withDiscountroadSideAssistance
  // );
  const engineGearBoxProtection = parse_value(
    quote_details.premiumDetails.engineGearBoxProtection
  );
  const noClaimBonusSameSlab = parse_value(
    quote_details.premiumDetails.noClaimBonusSameSlab
  );
  const consumablesCover = parse_value(
    quote_details.premiumDetails.consumablesCover
  );
  // const withDiscountconsumablesCover = parse_value(
  //   quote_details.premiumDetails.withDiscountconsumablesCover
  // );
  const keyReplacementCover = parse_value(
    quote_details.premiumDetails.keyReplacementCover
  );
  const driverCover = parse_value(quote_details.premiumDetails.driverCover);
  const personalBelonging = parse_value(
    quote_details.premiumDetails.personalBelonging
  );
  const tyreSecureCover = parse_value(
    quote_details.premiumDetails.tyreSecureCover
  );

  const cngKitOD = parse_value(quote_details.premiumDetails.cngKitOD);
  const cngKitTP = parse_value(quote_details.premiumDetails.cngKitTP);
  const passengerCover = parse_value(
    quote_details.premiumDetails.passerngerCover
  );

  premium =
    netPremium -
    paOwnerDriver -
    engineGearBoxProtection -
    noClaimBonusSameSlab -
    keyReplacementCover -
    driverCover -
    tyreSecureCover -
    cngKitOD -
    cngKitTP -
    passengerCover -
    personalBelonging;

  if (
    ADDON_STATUS?.pa_owner_cover?.value ||
    quote_details.premiumDetails.includedPAOwnerDriver === 1
  ) {
    premium += paOwnerDriver;
  }

  if (
    OWNERSHIP === "Organization" &&
    (ADDON_STATUS?.pa_owner_cover?.value === true ||
      quote_details.premiumDetails?.includedPAOwnerDriver === 1)
  ) {
    premium -= paOwnerDriver;
  }

  if (
    ADDON_STATUS?.personalBelonging?.value ||
    quote_details.premiumDetails.includedPersonalBelonging === 1
  ) {
    premium += personalBelonging;
  }

  if (ADDON_STATUS?.depreciation_cover?.value) {
    premium += depreciationCover;
  }

  if (ADDON_STATUS?.invoice_cover?.value) {
    premium += invoicePriceCover;
  }

  if (ADDON_STATUS?.rsa?.value) {
    premium += roadSideAssistance;
  }

  if (ADDON_STATUS?.engine_cover?.value) {
    premium += engineGearBoxProtection;
  }

  if (ADDON_STATUS?.ncb_cover?.value) {
    premium += noClaimBonusSameSlab;
  }

  if (ADDON_STATUS?.consumable_cover?.value) {
    premium += consumablesCover;
  }

  if (ADDON_STATUS?.key_replacement?.value) {
    premium += keyReplacementCover;
  }

  if (ADDON_STATUS?.tyre_cover?.value) {
    premium += tyreSecureCover;
  }

  if (ADDON_STATUS?.driver_cover?.value) {
    premium += driverCover;
  }

  premium += cngKitOD;
  premium += cngKitTP;

  if (ADDON_STATUS?.passenger_cover?.value && PLAN_TYPE !== "SAOD") {
    premium += calculate_passenger_cover(
      Number(`${passenger_cover_user_value}`),
      quote_details,
      PLAN_TYPE
    );
  }

  const discount_amount =
    (premium / 100) * RSA_DISCOUNT_SELECTION(ADDON_STATUS, quote_details);
  premium = premium - discount_amount;

  let tax_amount = (premium / 100) * 18;

  final_premium = premium + tax_amount;

  return final_premium;
}
