import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import ProposerDetails from "../../../Page/Desktop/Term/ShortPay/Godigit/ProposerDetails";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import {
  calculateAge,
  FORMAT_DATE_DD_MM_YYYY,
  formatToCurrency,
  isEmpty,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  valueExistsInObjects,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TermSlice } from "../../../redux/slice/Term/TermSlice";
import { TDropdown } from "../../../types/Common/TDropdown";
import { TTermProposerData } from "../../../types/TTermSlice";
import { toast } from "react-toastify";

const BasicDetailsContainer = () => {
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const {
    PROPOSER_DATA,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    RIDERS_BENEFITS,
    SELECTED_QUOTE_DATA,
    QUOTE_FIELDS_DATA,
    LOADER,
    DROPDOWN_DATA,
  } = useAppSelector((state) => state.Term);
  const state = useAppSelector((state) => state);
  const { Term } = useAppSelector((state) => state);
  const [loader, setLoader] = useState<boolean>(false);
  const [journeyBlock, setJourneyBlock] = useState<boolean>(false);
  const [isProposerSameInsured, setIsProposerSameInsured] =
    useState<boolean>(true);
  const [isChecked, setChecked] = useState<boolean>(true);
  const [openIPRUPDFModel, setOpenIPRUPDFModel] = useState(false);

  const [saveRedirectionUrl, setSaveRedirectionUrl] = useState("");
  const [activeField, setActiveField] = useState(undefined);
  const [proposerFields, setProposerFields] =
    useState<TTermProposerData>(PROPOSER_DATA);

  useEffect(() => {
    GET_RIDERS_PREMIUM();
  }, [proposerFields.sum_assured.value]);

  useEffect(() => {
    if (
      isEmpty(proposerFields?.occupation?.value) &&
      !isEmpty(proposerFields?.qualification?.value)
    ) {
      setJourneyBlock(true);
      // toast.error("Please select occupation");
    } else {
      setJourneyBlock(false);
    }
  }, [proposerFields?.qualification?.value, proposerFields?.occupation?.value]);

  const GET_RIDERS_PREMIUM = () => {
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    const onSuccess = (data: any) => {
      const results = data.response;
      const error = data.error;
      const message = data.message;
      if (!error) {
        const response = data.response;
        setLoader(false);
        dispatch(
          TermSlice.actions.BULK_UPDATE({
            ...Term,
            LOADER: false,
            RIDERS_BENEFITS: {
              ...Term.RIDERS_BENEFITS,
              riders_benefits_response: response,
            },
            SELECTED_QUOTE_DATA: {
              ...Term.SELECTED_QUOTE_DATA,
              premiumDetails: {
                ...Term.SELECTED_QUOTE_DATA.premiumDetails,
                pay_term: response.pay_term,
                term: response.term,
                sum_assured: proposerFields?.sum_assured?.value,
              },
            },
          })
        );
      } else {
        dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
        console.log({ error: error, message: message });
      }
      PROPOSER_OCCUPATION_DETAILS();
      PROPOSER_QUALIFICATION_DETAILS();
    };
    const onError = (err: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
    };

    const data = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails?.id],
      quote_no: ADD_FORM_RESPONSE?.quote_no,
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      accidentalDisabilitySelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      terminalIllnessSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      sum_assured: proposerFields.sum_assured.value,
    };
    TERM_SERVICES.UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };
  const GET_REDIRECTION = () => {
    if (saveRedirectionUrl !== "") {
      window.location.href = saveRedirectionUrl;
    }
  };

  const GET_REDIRECTION_URL = () => {
    dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
    const onSuccess = (data: any) => {
      const results = data.response;
      const error = data.error;
      const message = data.message;
      if (!error) {
        if (window.location.hostname.toLowerCase().includes("bimastreet")) {
          const response = data.response;
          setLoader(false);
          setOpenIPRUPDFModel(true);

          const application_url = response.redirectionUrl;
          if (!isEmpty(application_url)) {
            setSaveRedirectionUrl(application_url);
            setOpenIPRUPDFModel(true);
          }
          dispatch(
            TermSlice.actions.BULK_UPDATE({
              ...Term,
              LOADER: false,
              SELECTED_QUOTE_DATA: {
                ...Term.SELECTED_QUOTE_DATA,
                premiumDetails: {
                  ...Term.SELECTED_QUOTE_DATA.premiumDetails,
                  pay_term: response.pay_term,
                  term: response.term,
                },
              },
            })
          );
        } else {
          GET_REDIRECTION_URL_UPDATED();
        }
      } else {
        dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
        console.log({ error: error, message: message });
      }
    };
    const onError = (err: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
    };

    const data = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails?.id],
      quote_no: ADD_FORM_RESPONSE?.quote_no,
      accidentalDeathSelected: RIDERS_BENEFITS.local_value.tf001Status
        ? "Yes"
        : "No",
      accidentalDisabilitySelected: RIDERS_BENEFITS.local_value.tf002Status
        ? "Yes"
        : "No",
      terminalIllnessSelected: RIDERS_BENEFITS.local_value.tf003Status
        ? "Yes"
        : "No",
      sum_assured: proposerFields.sum_assured.value,
    };
    TERM_SERVICES.UPDATE_RIDERS_STATUS(onSuccess, onError, data);
  };

  const GET_REDIRECTION_URL_UPDATED = () => {
    setLoader(true);
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res;
      const error = results.error;
      if (!error) {
        const response = results.response;
        setLoader(false);
        console.log(response);
        const redirectionUrl = response[0].redirectionUrl;
        const policyPdf = response[0].policyPdf;

        if (redirectionUrl) {
          setSaveRedirectionUrl(redirectionUrl);
          dispatch(
            TermSlice.actions.BULK_UPDATE({
              ...Term,
              LOADER: false,
              RIDERS_BENEFITS: {
                ...Term.RIDERS_BENEFITS,
                riders_benefits_response: {
                  ...Term.RIDERS_BENEFITS.riders_benefits_response,
                  policyPdf: policyPdf,
                  redirectionUrl: redirectionUrl,
                },
              },
            })
          );
          setOpenIPRUPDFModel(true);
        } else {
          toast.error("Redirection Url Missing!");
        }
      }
    };
    const onError = () => {
      setLoader(false);
    };
    const params = {
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      quote_no: ADD_FORM_RESPONSE.quote_no,
    };
    TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
  };

  console.log(
    "proposer fields",
    proposerFields,
    "quotefields",
    QUOTE_FIELDS_DATA
  );

  const fieldsUpdateState = (attr: any, value: any) => {
    if (attr[0] === "annual_income") {
      setActiveField(attr[0]);
    } else {
      setActiveField(undefined);
    }
    setProposerFields({
      ...proposerFields,
      [attr[0]]: {
        value: attr[0] === "email" ? value?.trimStart() : value,
        warning:
          attr[0] === "full_name"
            ? !validateFullName(value)
            : attr[0] === "mobile"
            ? !validateMobileNumber(value)
            : attr[0] === "email"
            ? !validateEmail(value)
            : isEmpty(value),
      },
    });
  };

  const validateForm = () => {
    let data = { ...proposerFields };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data.dob.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
      occupation: {
        ...data.occupation,
        warning: isEmpty(data.occupation.value),
      },
      qualification: {
        ...data.qualification,
        warning: isEmpty(data.qualification.value),
      },
      annual_income: {
        ...data.annual_income,
        warning: isEmpty(data.annual_income.value),
      },
      sum_assured: {
        ...data.sum_assured,
        warning: isEmpty(data.sum_assured.value),
      },
    };
    setProposerFields({ ...data });

    let hasError = false;

    if (
      data.full_name.warning ||
      data.dob.warning ||
      data.mobile.warning ||
      data.email.warning ||
      data.gender.warning ||
      data.occupation.warning ||
      data.qualification.warning ||
      data.annual_income.warning ||
      data.sum_assured.warning
    ) {
      hasError = true;
    }
    if (!hasError) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({ ...Term, PROPOSER_DATA: { ...data } })
      );
      UPDATE_PROPOSER_DETAILS();
    }
  };

  function convertDate(inputDate: string) {
    const [year, month, day] = inputDate.split("-");
    return `${day}-${month}-${year}`;
  }

  const UPDATE_PROPOSER_DETAILS = () => {
    let params = {
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
      quote_no: btoa(ADD_FORM_RESPONSE.quote_no),
      section: "proposer_details",
      details: {
        proposer_name: proposerFields?.full_name?.value,
        proposer_mobile: proposerFields?.mobile?.value,
        proposer_email: proposerFields?.email?.value,
        proposer_gender: proposerFields?.gender?.value,
        proposer_dob: convertDate(proposerFields?.dob?.value),
        proposer_occupation: proposerFields.occupation.value,
        proposer_education: proposerFields.qualification.value,
        address_pincode: proposerFields.pincode.value,
        insured_name: proposerFields?.full_name?.value,
        insured_mobile: ADD_FORM.mobile.value,
        insured_email: proposerFields.email.value,
        insured_gender: ADD_FORM.gender.value,
        insured_dob: ADD_FORM.age.value,
        insured_occupation: proposerFields.occupation.value,
        insured_education: proposerFields.qualification.value,
      },
    };

    const onSuccess = (res: any) => {
      GET_REDIRECTION_URL();

      console.log("res", res);
      setLoader(false);
    };
    const onError = () => {
      setLoader(false);
    };
    setLoader(true);
    TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);
  };

  useEffect(() => {
    if (DROPDOWN_DATA.MAX_SUM_ASSURED.length > 0) {
      setProposerFields((prev) => ({
        ...prev,
        sum_assured: {
          value: valueExistsInObjects(
            prev.sum_assured.value,
            DROPDOWN_DATA.MAX_SUM_ASSURED
          )
            ? prev.sum_assured.value
            : "",
          warning: isEmpty(prev.sum_assured.value),
        },
      }));
    }
  }, [
    proposerFields?.education?.value,
    proposerFields?.occupation?.value,
    DROPDOWN_DATA.MAX_SUM_ASSURED,
  ]);

  useEffect(() => {
    if (activeField) {
      let min_sum_assured = 25;
      let max_sum_assured = 5000000;
      const age = calculateAge(
        FORMAT_DATE_DD_MM_YYYY(`${proposerFields?.dob?.value}`)
      );
      const annual_income = parseInt(
        isEmpty(proposerFields.annual_income.value)
          ? state.Term.PROPOSER_DATA.annual_income.value
          : proposerFields.annual_income.value
      );

      let data: any[] = [];
      let multiplier = 0;

      if (
        proposerFields.occupation.value === "SAL" ||
        proposerFields.occupation.value === "HSW"
      ) {
        if (age <= 45) {
          multiplier = 60 - age;
        } else if (age >= 46 && age <= 50) multiplier = 10;
        else if (age >= 50) multiplier = 5;
      }
      if (proposerFields.occupation.value === "SEM") {
        if (age <= 45) {
          multiplier = 15;
        } else if (age >= 45) {
          multiplier = 5;
        }
      }

      max_sum_assured =
        multiplier * parseInt(proposerFields.annual_income.value);

      data = [];
      if (annual_income) {
        for (let i = 1; i <= multiplier; i++) {
          let opt = {
            label: formatToCurrency(i * annual_income),
            value: i * annual_income,
          };
          data.push(opt);
        }
      }

      dispatch(
        TermSlice.actions.UPDATE_DROPDOWN_DATA({
          key: "MAX_SUM_ASSURED",
          value: data,
        })
      );
    }
  }, [
    proposerFields.annual_income,
    proposerFields.occupation,
    proposerFields.dob,
    SELECTED_QUOTE_DATA.productDetails.product_code,
    activeField,
  ]);

  const PROPOSER_OCCUPATION_DETAILS = () => {
    let params = {
      product_type_code: "TP",
      product_code: "TPGD01",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };

    const onSuccess = (res: any) => {
      console.log("res", res);

      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        value: item.code,
        label: item.title,
      }));

      dispatch(
        TermSlice.actions.SET_DROPDOWN_DATA({
          key: "OCCUPATION_DATA",
          value: data,
        })
      );
    };

    const onError = () => {};

    TERM_SERVICES.PROPOSER_OCCUPATION_DETAILS(onSuccess, onError, params);
  };

  const PROPOSER_QUALIFICATION_DETAILS = () => {
    let params = {
      product_type_code: "TP",
      product_code: "TPGD01",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        value: item.code,
        label: item.title,
      }));
      dispatch(
        TermSlice.actions.SET_DROPDOWN_DATA({
          key: "QUALIFICATION_DATA",
          value: data,
        })
      );
    };
    const onError = () => {};
    TERM_SERVICES.PROPOSER_QUALIFICATION_DETAILS(onSuccess, onError, params);
  };

  return (
    <>
      {LOADER ? (
        <Box
          position={"fixed"}
          height={"100%"}
          width={"100%"}
          top="0"
          left="0"
          zIndex={911}
        />
      ) : null}
      {isMobile ? null : (
        <ProposerDetails
          proposerFields={proposerFields}
          fieldsUpdateState={fieldsUpdateState}
          validateForm={validateForm}
          loader={loader}
          isProposerSameInsured={isProposerSameInsured}
          setIsProposerSameInsured={setIsProposerSameInsured}
          isChecked={isChecked}
          setChecked={setChecked}
          openIPRUPDFModel={openIPRUPDFModel}
          setOpenIPRUPDFModel={setOpenIPRUPDFModel}
          journeyBlock={journeyBlock}
          // GET_REDIRECTION_URL={GET_REDIRECTION_URL_UPDATED}
          GET_REDIRECTION_URL={GET_REDIRECTION}
        />
      )}
    </>
  );
};

export default BasicDetailsContainer;
