import { useEffect, useState } from "react";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { TAddressDetails } from "../../../../types/Health/ProposalDeatail/TProposalDetail";
import {
  isEmpty,
  validatePincode,
} from "../../../../SupportingFiles/HelpingFunction";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-php/HealthPropsalServices";
import { CAR_SERVICES } from "../../../../Services/Car/CarServices";
import AddressDetails from "../../../../Page/Desktop/health-php/CARE/AddressDetails/AddressDetails";
import MAddressDetails from "../../../../Page/Mobile/health-php/CARE/MAddressDetails/MAddressDetails";
import { ECompanyCode, EProductId } from "../../../../Services/Enum/EHome";
import { toast } from "react-toastify";
import { TDropdown } from "../../../../types/Common/TDropdown";
import { CKYCServcies } from "../../../../Services/CKYC/CKYCServices";
import { CommonDropdownSlice } from "../../../../redux/slice/CommonDropdown/CommonDropdownSlice";

export default function AddressDetailsContainer() {
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);
  const {
    ADDRESS_DETAILS,
    PAGE_STATUS,
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SELECTED_QUOTE_DATA,
    BANK_DETAILS_HDFC,
  } = useAppSelector((state) => state.Health);
  // Permanent Address
  const [permanentAddress, setPermanentAddress] =
    useState<TAddressDetails>(ADDRESS_DETAILS);

  console.log(
    "parmanent address",
    permanentAddress,
    "address",
    ADDRESS_DETAILS
  );

  useEffect(() => {
    if (validatePincode(permanentAddress.pincode.value)) {
      GetCityStateByPin("PERMANENT");
    }
  }, [permanentAddress.pincode.value]);

  useEffect(() => {
    setPermanentAddress(ADDRESS_DETAILS);
  }, [ADDRESS_DETAILS]);

  useEffect(() => {
    if (validatePincode(permanentAddress.mailingPinCode.value)) {
      GetCityStateByPin("MAILING");
    }
  }, [permanentAddress.mailingPinCode.value]);
  const ADD_API_DATA = () => {
    const onSuccess = (res: any) => {
      if (res.results.error === false) {
        let quoteData = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

        // Perform hard refresh on success
        // (window.location.reload as any)(true);
      }
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    // dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
    };

    // setTimeout(() => {
    //   HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
    // }, 500); // 2000 milliseconds = 2 seconds
  };
  useEffect(() => {
    // if (
    //   SELECTED_QUOTE_DATA.CompanyDetails.company_code !== ECompanyCode.GO_DIGIT
    // ) {
    if (ADDRESS_DETAILS.isMailingAddressSame === true) {
      let formValue = { ...ADDRESS_DETAILS };
      formValue = {
        ...formValue,
        mailingPinCode: {
          ...formValue.pincode,
          warning: !validatePincode(formValue.pincode.value),
        },
        mailingAddressLineOne: {
          ...formValue.addressLineOne,
          warning: isEmpty(formValue.addressLineOne.value),
        },
        mailingAddressLineTwo: {
          ...formValue.addressLineTwo,
          warning: isEmpty(formValue.addressLineTwo.value),
        },
      };
      setPermanentAddress({ ...formValue });
    } else {
      setPermanentAddress(ADDRESS_DETAILS);
    }
    // }
  }, [
    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
    ADDRESS_DETAILS.isMailingAddressSame,
  ]);

  const updateMasterState = (attrName: any, value: any) => {
    setPermanentAddress((prev: any) => ({
      ...prev,
      [attrName]: {
        value: value,
        warning:
          attrName === "pincode" ? !validatePincode(value) : isEmpty(value),
      },
    }));
  };

  const handleClick = () => {
    let pinCode = permanentAddress.pincode.value;
    let addressLine1 = permanentAddress.addressLineOne.value;
    let addressLine2 = permanentAddress.addressLineTwo.value;

    if (addressLine1 || pinCode || addressLine2) {
      setPermanentAddress({
        ...permanentAddress,
        isMailingAddressSame: !permanentAddress.isMailingAddressSame,
        mailingPinCode: {
          value: pinCode,
          warning: pinCode ? !validatePincode(pinCode) : false,
        },
        mailingAddressLineOne: {
          value: addressLine1,
          warning: addressLine1 ? isEmpty(addressLine1) : false,
        },
        mailingAddressLineTwo: {
          value: addressLine2,
          warning: addressLine2 ? isEmpty(addressLine2) : false,
        },
      });
    }
  };

  const validateForm = () => {
    let formValue = { ...permanentAddress };
    formValue = {
      ...formValue,
      pincode: {
        ...formValue.pincode,
        warning: !validatePincode(formValue.pincode.value),
      },
      addressLineOne: {
        ...formValue.addressLineOne,
        warning: isEmpty(formValue.addressLineOne.value),
      },
      addressLineTwo: {
        ...formValue.addressLineTwo,
        warning: isEmpty(formValue.addressLineTwo.value),
      },
      mailingPinCode: {
        ...formValue.mailingPinCode,
        warning: !validatePincode(formValue.mailingPinCode.value),
      },
      mailingAddressLineOne: {
        ...formValue.mailingAddressLineOne,
        warning: isEmpty(formValue.mailingAddressLineOne.value),
      },
      mailingAddressLineTwo: {
        ...formValue.mailingAddressLineTwo,
        warning: isEmpty(formValue.mailingAddressLineTwo.value),
      },
    };
    setPermanentAddress({ ...formValue });
    const onSuccess = (res: any) => {
      console.log("onSuccess");
      ADD_API_DATA();
      const results = res.results;
      const error = results.error;
    };
    const onError = (err: any) => {
      console.log("errr", err);
    };

    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    let param = {
      addressPPincode: formValue.pincode.value,
      addressPHouse: formValue.addressLineOne.value,
      addressPArea: formValue.addressLineTwo.value,
      addressPCity: formValue.city,
      addressPState: formValue.state,
      addressMailingSame: formValue.isMailingAddressSame ? "Yes" : "No",
      addressMPincode:
        formValue.isMailingAddressSame === false
          ? formValue.mailingPinCode.value
          : formValue.pincode.value,
      addressMHouse: formValue.mailingAddressLineOne.value,
      addressMArea: formValue.mailingAddressLineTwo.value,
      addressMCity: formValue.mailingCity,
      addressMState: formValue.mailingState,
      section: "address_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${
        (parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
          ? `${
              (parseInt(ADD_FORM?.son_count?.value) || 0) +
              (parseInt(ADD_FORM?.daughter_count?.value) || 0)
            }C`
          : ""
      }`,
      finalPremium: `${SELECTED_QUOTE_DATA.premiumDetails.finalPremium}`,
      DispatchFlag: BANK_DETAILS_HDFC.DispatchFlag.value ? "true" : "false",
    };

    if (
      !formValue.pincode.warning &&
      !formValue.addressLineOne.warning &&
      !formValue.addressLineTwo.warning
    ) {
      let shouldNavigate = false;
      if (!permanentAddress.isMailingAddressSame) {
        if (
          !formValue.mailingAddressLineOne.warning &&
          !formValue.mailingAddressLineTwo.warning &&
          !formValue.mailingPinCode.warning
        ) {
          shouldNavigate = true;
        }
      } else {
        shouldNavigate = true;
      }

      if (shouldNavigate) {
        HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(
          onSuccess,
          onError,
          param
        );
        if (PAGE_STATUS === true) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        }
        // else if (
        //   SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.CARE
        // ) {
        //   navigate(
        //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
        //   );
        // }
        else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.MEDICAL_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        }
        dispatch(HealthSlice.actions.SAVE_ADDRESS_DETAILS({ ...formValue }));
      }
    }
  };

  const GetCityStateByPin = (key: "PERMANENT" | "MAILING") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (error === false) {
        const response = results.response;

        if (key === "PERMANENT") {
          setPermanentAddress((prev: any) => ({
            ...prev,
            city: response.city,
            state: response.state,
          }));
        } else {
          setPermanentAddress((prev: any) => ({
            ...prev,
            mailingCity: response?.city,
            mailingState: response?.state,
          }));
        }
      }
    };

    const onError = (err: any) => {
      console.log("updatefalse", err);
    };
    let param = {
      pincode:
        key === "PERMANENT"
          ? permanentAddress.pincode.value
          : permanentAddress.mailingPinCode.value,
    };

    CAR_SERVICES.GetCityStateByPin(onSuccess, onError, param);
  };

  useEffect(() => {
    if (
      SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      ECompanyCode.ADITY_BIRLA
    ) {
      const prop_data = { ...permanentAddress };
      setPermanentAddress({
        ...prop_data,
        pincode: {
          value: isEmpty(prop_data.pincode.value)
            ? CKYC_DETAILS.kycPincode
            : prop_data.pincode.value,
          warning: false,
        },
        addressLineTwo: {
          value: `${CKYC_DETAILS.kycAddress3}, ${CKYC_DETAILS.kycCity}, ${CKYC_DETAILS.kycDistrict}, ${CKYC_DETAILS.kycState}`,
          warning: false,
        },
      });
    }
  }, [CKYC_DETAILS]);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_ADVANTAGE) {
      toast.info(
        `They should use the pincode from the customer's Aadhaar card to prevent any issues during the claims process.`
      );
    }
  }, []);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.STAR) {
      if (permanentAddress.pincode.value) {
        GET_AREA_BY_CITY();
      }
    }
  }, [
    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
    permanentAddress.pincode.value,
  ]);

  const GET_AREA_BY_CITY = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        const response = results.response?.area;
        const manupulated_data: TDropdown[] = [];
        for (const data of response) {
          manupulated_data.push({ value: data.areaID, label: data.areaName });
        }
        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: "AREA_TOWN",
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {};
    const pincode_params = permanentAddress.pincode.value;
    const city_params = permanentAddress.addressLineTwo.value.toUpperCase();

    CKYCServcies.GET_AREA_BY_CITY(
      onSuccess,
      onError,
      pincode_params,
      city_params
    );
  };

  return isMobile ? (
    <MAddressDetails
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      handleClick={handleClick}
      permanentAddress={permanentAddress}
    />
  ) : (
    <AddressDetails
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      handleClick={handleClick}
      permanentAddress={permanentAddress}
    />
  );
}
