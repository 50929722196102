import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { EProductId } from "../../../../Services/Enum/EHome";
import { HealthSlice } from "../../../../redux/slice/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateAge,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { TCareAddon } from "../../../../types/Health/TCareAddon";
import { CKYCSlice } from "../../../../redux/slice/CKYC/CKYCSlice";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/health-node/HealthPropsalServices";
import MAddonDetails from "../../../../Page/Mobile/health-php/CARE/MAddonDetails/MAddonDetails";
import AddonDetails from "../../../../Page/Desktop/health-php/CARE/AddonDetails/AddonDetails";

export default function AddonDetailsContainer() {
  const isMobile = useIsMobile();
  const { Health, CKYC } = useAppSelector((state) => state);
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [addonStatus, setAddonStatus] = useState<TCareAddon>(ADDON_DETAILS);
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const [mandatoryAddon, setMandatoryAddon] = useState<[]>([]);
  const [selectedAddons, setSelectedAddons] = useState<number>(0);

  const [isContinueButtonDisabled, setIsContinueButtonDisabled] =
    useState(false);

  const careAdvantageAddons: any = {
    NCBSuper: addonStatus.NCBSuper,
    field_34: addonStatus.field_34,
    SmartSelect: addonStatus.SmartSelect,
    field_COPAY: addonStatus.field_COPAY,
    AirAmbulanceCover: addonStatus.AirAmbulanceCover,
    CareShield: addonStatus.CareShield,
    field_UAR: addonStatus.field_UAR,
    GlobalCoverage: {
      value: addonStatus.GlobalCoverage.value,
      warning: false,
    },
    field_OPD: addonStatus.field_OPD,
    field_AHC_Care_Advatage: addonStatus.field_AHC_Care_Advatage,
    field_43: addonStatus.field_43,
  };

  const [userType, setUserType] = useState<string>("");
  const [utm_medium, setUtm_medium] = useState<string>("");
  const [utm_source, setUtm_source] = useState<string>("");

  useEffect(() => {
    // Access the query parameters directly from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const userTypeFromQuery = searchParams.get("user_type");
    const utm_mediumFromQuery = searchParams.get("utm_medium");
    const utm_sourceFromQuery = searchParams.get("utm_source");
    setUtm_medium(`${utm_mediumFromQuery}`);
    setUtm_source(`${utm_sourceFromQuery}`);
    setUserType(
      atob(`${userTypeFromQuery}`) === "agent" ? btoa("agent") : btoa("user")
    );
  }, []);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_ADVANTAGE) {
      let count = 0; // Temporary counter variable
      for (let key in careAdvantageAddons) {
        if (key === "GlobalCoverage") {
          if (addonStatus[key].value !== "Not Applicable") count += 1;
        } else {
          // @ts-ignore:
          if (addonStatus[key]) {
            count += 1;
          }
        }
      }
      if (count > 8) {
        toast.error("You Can Only Select Upto 7 Addons");
        setIsContinueButtonDisabled(true);
      } else {
        setIsContinueButtonDisabled(false);
      }
      setSelectedAddons(count);
    }
  }, [addonStatus]);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === 2651) {
      setAddonStatus((prev) => ({
        ...prev,
        field_WB: true,
        AirAmbulanceCover: true,
      }));

      if (calculateAge(ADD_FORM.self_dob.value) <= 45) {
        setAddonStatus((prev) => ({
          ...prev,
          field_WB: true,
          AirAmbulanceCover: true,
        }));
      }
    } else if (SELECTED_QUOTE_DATA.productDetails.id === 443) {
      if (CKYC_DETAILS.zone === "Zone 3" && PAGE_STATUS === false) {
        setAddonStatus((prev) => ({
          ...prev,
          field_OPD: true,
          field_OPD_data: { value: "5000", warning: false },
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === 2651) {
      if (
        calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) <= 45 ||
        (calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) >= 76 &&
          calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) <= 99)
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          field_WB: true,
          AirAmbulanceCover: true,
        }));
      }
    }
    if (SELECTED_QUOTE_DATA.productDetails.id === 2651) {
      const selfAge = calculateAge(
        FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
      );
      const spouseAge = calculateAge(
        FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
      );

      if (
        (PAGE_STATUS === false && selfAge >= 46 && selfAge <= 75) ||
        (!PAGE_STATUS === false && spouseAge >= 46 && spouseAge <= 75)
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          NCBSuper: false,
        }));
      } else if (
        (selfAge >= 18 && selfAge < 46) ||
        selfAge > 75 ||
        (spouseAge >= 18 && spouseAge < 46) ||
        spouseAge > 75
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          NCBSuper: true,
        }));
      }

      if (
        (selfAge >= 76 && selfAge <= 99) ||
        selfAge > 75 ||
        (spouseAge >= 76 && spouseAge <= 99) ||
        spouseAge > 75
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          NCBSuper: true,
        }));
      }
    }

    if (SELECTED_QUOTE_DATA.productDetails.id === 2133) {
      if (
        (calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) >= 76 &&
          calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) <=
          99) ||
        (calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)) >=
          76 &&
          calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)) <=
          99) ||
        ADD_FORM.age.value <= "45" ||
        CKYC_DETAILS.ncb_tag === "Yes"
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          NCBSuper: true,
          AirAmbulanceCover: false,
        }));
      } else {
        setAddonStatus((prev) => ({
          ...prev,
          AirAmbulanceCover: false,
        }));
      }
      if (!ADDON_DETAILS.GlobalCoverage.value?.value) {
        setAddonStatus((prev) => ({
          ...prev,
          GlobalCoverage: {
            value:
              addonStatus.GlobalCoverage.value?.value ||
              ADDON_DETAILS.GlobalCoverage.value?.value ||
              "Not Applicable",
            warning: false,
          },
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (
      (CKYC_DETAILS.zone === "Zone 1" && PAGE_STATUS === false) ||
      (CKYC_DETAILS.zone === "Zone 2" && PAGE_STATUS === false)
    ) {
      setAddonStatus((prev) => ({
        ...prev,
        field_OPD_data: { value: "", warning: false },
      }));
    }
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    console.log(value, "attrName", attrName);
    if (selectedAddons > 7 && SELECTED_QUOTE_DATA.productDetails.id === 2133) {
      if (value == false) {
        if (attrName === "field_OPD" && value === false) {
          setAddonStatus((prevState) => ({
            ...prevState,
            field_OPD_data: { value: "", warning: false },
          }));
          //  dispatch(HealthSlice.actions.ADDON_DETAILS_DATA(addonStatus));
        } else if (attrName === "field_OPD" && value === true) {
          setAddonStatus((prevState) => ({
            ...prevState,
            field_OPD_data: { value: "5000", warning: false },
          }));
        } else if (attrName === "field_43") {
          setAddonStatus((prev) => ({
            ...prev,
            field_43: value,
            field_IC: false,
          }));
        } else if (attrName === "field_IC") {
          setAddonStatus((prev) => ({
            ...prev,
            field_IC: value,
            field_43: false,
            field_PED_tenure: { value: "1", warning: false },
          }));
        }
        setAddonStatus((prevState) => ({
          ...prevState,
          [attrName]:
            attrName === "GlobalCoverage" ||
              attrName === "field_PED_tenure" ||
              attrName === "field_OPD_data"
              ? { value: value, warning: false }
              : value,
        }));
      }
      return;
    }
    else {
      if (attrName === "field_OPD" && value === false) {
        setAddonStatus((prevState) => ({
          ...prevState,
          field_OPD_data: { value: "", warning: false },
        }));
        //  dispatch(HealthSlice.actions.ADDON_DETAILS_DATA(addonStatus));
      } else if (attrName === "field_OPD" && value === true) {
        setAddonStatus((prevState) => ({
          ...prevState,
          field_OPD_data: { value: "5000", warning: false },
        }));
      } else if (attrName === "field_43") {
        setAddonStatus((prev) => ({
          ...prev,
          field_43: value,
          field_IC: false,
        }));
      } else if (attrName === "field_IC") {
        setAddonStatus((prev) => ({
          ...prev,
          field_IC: value,
          field_43: false,
          field_PED_tenure: { value: "1", warning: false },
        }));
      }
      else if (attrName === "field_NCB" && !value && SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_CARE) {
        setAddonStatus({ ...addonStatus, field_43: false });
      }
      setAddonStatus((prevState) => ({
        ...prevState,
        [attrName]:
          attrName === "GlobalCoverage" ||
            attrName === "field_PED_tenure" ||
            attrName === "field_OPD_data"
            ? { value: value, warning: false }
            : value,
      }));
    }
  };

  const GET_ADDON_PREMIUM_CARE_HEARTH = () => {
    const onSuccess = (res: any) => {
      if (!res.error) {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        field_CS: addonStatus.field_CS === true ? 1 : 0,
        field_HomeCare: addonStatus.field_HomeCare === true ? 1 : 0,
        field_OPD: addonStatus.field_OPD === true ? 1 : 0,
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIUM_CARE_HEART(
      onSuccess,
      onError,
      param
    );
  };
  const GET_ADDON_PREMIUM_CARE_FREEDOM = () => {
    const onSuccess = (res: any) => {
      console.log("res freedom", res);
      if (!res.error) {
        setLoader(false);

        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: { ...addonStatus, field_OPD: true },
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      product_ids: [219],
      addon: {
        field_Deductible: addonStatus.field_Deductible ? 1 : 0,
        field_HCP: addonStatus.field_HCP ? 1 : 0,
        field_HomeCare: addonStatus.field_HomeCare ? 1 : 0,
        field_OPD: addonStatus.field_OPD ? 1 : 1,
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIUM_CARE_FREEDOM(
      onSuccess,
      onError,
      param
    );
  };
  const GET_ADDON_PREMIUM_CARE_SUPREME = () => {
    const onSuccess = (res: any) => {
      if (!res.error) {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: addonStatus,
            QUOTE_LOADER: false,
          })
        );
        dispatch(
          CKYCSlice.actions.BULK_UPDATE({
            ...CKYC,
            CKYC_DETAILS: {
              ...CKYC.CKYC_DETAILS,
              zone: res?.quoteLists[0]?.premiumDetails?.zone,
            },
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      addon: {
        field_BFB: addonStatus.field_BFB === true ? 1 : 0,
        field_WB: addonStatus.field_WB === true ? 1 : 0,
        field_OPD: addonStatus.field_OPD === true ? 1 : 0,
        field_35: addonStatus.AirAmbulanceCover === true ? 1 : 0,
        field_43: addonStatus.field_43 === true ? 1 : 0,
        field_PED_TENURE: addonStatus.field_PED_tenure.value,
        field_CS: addonStatus.CareShield === true ? 1 : 0,
        field_NCB: addonStatus.NCBSuper === true ? 1 : 0,
        field_AHC: addonStatus.field_AHC === true ? 1 : 0,
        field_IC: addonStatus.field_IC === true ? 1 : 0,
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIUM_CARE_SUPREME(
      onSuccess,
      onError,
      param
    );
  };

  const GET_ADDON_PREMIUM_CARE_SENIOR = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

      setLoader(false);
      if (!res.error) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };
    setLoader(true);
    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      pincode: CKYC_DETAILS.kycPincode,
      product_id: `${SELECTED_QUOTE_DATA.productDetails.id}`,
      addon: {
        field_NCB: addonStatus.NCBSuper === true ? 1 : 0,
        field_SS: addonStatus.SmartSelect === true ? 1 : 0,
        field_35: addonStatus.AirAmbulanceCover === true ? 1 : 0,
        field_DK: addonStatus.field_DK === true ? 1 : 0,
        field_DUE: addonStatus.field_DUE === true ? 1 : 0,
        field_CS: addonStatus.CareShield === true ? 1 : 0,
        field_CPW: addonStatus.field_CPW === true ? 1 : 0,
        field_OPD: addonStatus.field_OPD === true ? 1 : 0,
        field_OPD_SI: addonStatus.field_OPD_data.value,
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIUM_CARE_SENIOR(
      onSuccess,
      onError,
      param
    );
  };

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.error === false) {
        setLoader(false);
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.quoteLists[0].premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };
    setLoader(true);
    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      product_ids: [SELECTED_QUOTE_DATA.productDetails.id],
      addon: {
        field_SS: addonStatus.SmartSelect === true ? "1" : "0",
        field_NCB: addonStatus.NCBSuper === true ? "1" : "0",
        field_35: addonStatus.AirAmbulanceCover === true ? "1" : "0",
        field_34: addonStatus.field_34 === true ? "1" : "0",
        field_CS: addonStatus.CareShield === true ? "1" : "0",
        field_UAR: "1",
        field_COPAY: addonStatus.field_COPAY === true ? "1" : "0",
        global_coverage: addonStatus.GlobalCoverage.value,
        field_OPD: addonStatus.field_OPD === true ? "1" : "0",
        field_AHC: addonStatus.field_AHC_Care_Advatage === true ? "1" : "0",
        field_43: addonStatus.field_43 === true ? "1" : "0",
      },
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddon(onSuccess, onError, param);
  };

  const GET_ADDON_PREMIUM_CARE_CARE = () => {
    const onSuccess = (res: any) => {
      console.log("res freedom", res);
      if (!res.error) {
        setLoader(false);

        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: { ...addonStatus, field_OPD: true },
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      product_ids: [EProductId.CARE_CARE],
      addon: {
        field_OPD: addonStatus.field_OPD ? 1 : 0,
        field_OPD_SI: addonStatus.field_OPD
          ? addonStatus.field_OPD_data.value
          : 0,
        field_35: addonStatus.field_35 ? 1 : 0,
        field_43: addonStatus.field_43 ? 1 : 0,
        field_CS: addonStatus.field_CS ? 1 : 0,
        field_NCB: addonStatus.field_NCB ? 1 : 0,
        field_EDC: addonStatus.field_EDC ? 1 : 0,
        field_CWP: addonStatus.field_CWP ? 1 : 0,
        field_UA: addonStatus.field_UA ? 1 : 0,
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIUM_CARE_CARE(
      onSuccess,
      onError,
      param
    );
  };

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === 2651) {
      GET_ADDON_PREMIUM_CARE_SUPREME();
    } else if (SELECTED_QUOTE_DATA.productDetails.id === 443) {
      GET_ADDON_PREMIUM_CARE_SENIOR();
    } else if (SELECTED_QUOTE_DATA.productDetails.id === 1551) {
      GET_ADDON_PREMIUM_CARE_HEARTH();
    } else if (
      SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_FREEDOM
    ) {
      GET_ADDON_PREMIUM_CARE_FREEDOM();
    } else if (SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_CARE) {
      GET_ADDON_PREMIUM_CARE_CARE();
    } else {
      updatePreiumApi();
    }
  }, [addonStatus]);
  console.log("addonStatus", addonStatus);

  const handleCAREAddons = () => {
    const age = calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value));
    if (
      age >= 25 &&
      age <= 35 &&
      SELECTED_QUOTE_DATA.premiumDetails.sum_insured === 500000
    ) {
      setAddonStatus({ ...addonStatus, field_CS: true });
    }
    if (
      age <= 45
    ) {
      setAddonStatus({ ...addonStatus, field_NCB: true });
    }
  };
  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_CARE) {
      handleCAREAddons();
    }
  }, []);

  const validate = () => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const cus_type = params.get("cus_type");

    if (SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_FREEDOM) {
      dispatch(
        HealthSlice.actions.ADDON_DETAILS_DATA({
          ...addonStatus,
          field_OPD: true,
        })
      );
    } else {
      dispatch(HealthSlice.actions.ADDON_DETAILS_DATA(addonStatus));
    }
    const onSuccess = (res: any) => {
      if (!res.error) {
        // ADD_API_DATA();
        setLoader(false);
        // if (PAGE_STATUS) {
        //   navigate(
        //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
        //       HEALTH_ROUTE.PREVIEW_DETAILS
        //     }?type=HP&companyCode=${
        //       SELECTED_QUOTE_DATA?.CompanyDetails.company_code
        //     }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
        //       SELECTED_QUOTE_DATA?.productDetails.id
        //     }${cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""}`
        //   );
        // } else {
        //   navigate(
        //     `${HEALTH_ROUTE.PROPOSAL_PAGE}/${
        //       HEALTH_ROUTE.ADULT_ONE_DETAILS
        //     }?type=HP&companyCode=${
        //       SELECTED_QUOTE_DATA?.CompanyDetails.company_code
        //     }&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${
        //       SELECTED_QUOTE_DATA?.productDetails.id
        //     }${cus_type === "YWdlbnQ=" ? "&user_type=YWdlbnQ=" : ""}`
        //   );
        // }
        if (PAGE_STATUS) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        } else {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}&user_type=${userType}&utm_source=${utm_source}&utm_medium=${utm_medium}`
          );
        }
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,

      pincode: CKYC_DETAILS.kycPincode,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
      addon: {
        ...(SELECTED_QUOTE_DATA.productDetails.id === 2651
          ? {
            field_BFB: addonStatus.field_BFB === true ? 1 : 0,
            field_WB: addonStatus.field_WB === true ? 1 : 0,
            field_OPD: addonStatus.field_OPD === true ? 1 : 0,
            field_35: addonStatus.AirAmbulanceCover === true ? 1 : 0,
            field_43: addonStatus.field_43 === true ? 1 : 0,
            field_PED_TENURE: addonStatus.field_PED_tenure.value,
            field_CS: addonStatus.CareShield === true ? 1 : 0,
            field_NCB: addonStatus.NCBSuper === true ? 1 : 0,
            field_AHC: addonStatus.field_AHC === true ? 1 : 0,
            field_IC: addonStatus.field_IC === true ? 1 : 0,
          }
          : SELECTED_QUOTE_DATA.productDetails.id === 1551
            ? {
              field_CS: addonStatus.field_CS === true ? 1 : 0,
              field_HomeCare: addonStatus.field_HomeCare === true ? 1 : 0,
              field_OPD: addonStatus.field_OPD === true ? 1 : 0,
            }
            : SELECTED_QUOTE_DATA.productDetails.id === 443
              ? {
                field_NCB: addonStatus.NCBSuper === true ? 1 : 0,
                field_SS: addonStatus.SmartSelect === true ? 1 : 0,
                field_35: addonStatus.AirAmbulanceCover === true ? 1 : 0,
                field_DK: addonStatus.field_DK === true ? 1 : 0,
                field_DUE: addonStatus.field_DUE === true ? 1 : 0,
                field_CS: addonStatus.CareShield === true ? 1 : 0,
                field_CPW: addonStatus.field_CPW === true ? 1 : 0,
                field_OPD: addonStatus.field_OPD === true ? 1 : 0,
                field_OPD_SI: addonStatus.field_OPD_data.value,
              }
              : SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_FREEDOM
                ? {
                  field_Deductible: addonStatus.field_Deductible ? 1 : 0,
                  field_HCP: addonStatus.field_HCP ? 1 : 0,
                  field_HomeCare: addonStatus.field_HomeCare ? 1 : 0,
                  field_OPD: addonStatus.field_OPD ? 1 : 1,
                }
                : SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_CARE
                  ? {
                    field_OPD: addonStatus.field_OPD ? 1 : 0,
                    field_OPD_SI: addonStatus.field_OPD
                      ? addonStatus.field_OPD_data.value
                      : 0,
                    field_35: addonStatus.field_35 ? 1 : 0,
                    field_43: addonStatus.field_43 ? 1 : 0,
                    field_CS: addonStatus.field_CS ? 1 : 0,
                    field_NCB: addonStatus.field_NCB ? 1 : 0,
                    field_EDC: addonStatus.field_EDC ? 1 : 0,
                    field_CWP: addonStatus.field_CWP ? 1 : 0,
                    field_UA: addonStatus.field_UA ? 1 : 0,
                  }
                  : {
                    field_SS: addonStatus.SmartSelect === true ? "1" : "0",
                    field_NCB: addonStatus.NCBSuper === true ? "1" : "0",
                    field_35: addonStatus.AirAmbulanceCover === true ? "1" : "0",
                    field_34: addonStatus.field_34 === true ? "1" : "0",
                    field_CS: addonStatus.CareShield === true ? "1" : "0",
                    field_UAR: "1",
                    field_COPAY: addonStatus.field_COPAY === true ? "1" : "0",
                    global_coverage: addonStatus.GlobalCoverage.value,
                    field_OPD: addonStatus.field_OPD === true ? "1" : "0",
                    field_AHC: addonStatus.field_AHC_Care_Advatage === true ? "1" : "0",
                    field_43: addonStatus.field_43 === true ? "1" : "0",
                  })
      },
      section: "add_ons_details",
      insurance_type: "HP",
    };

    if (
      ADD_FORM_RESPONSE.sum_insured >= 20000000 &&
      (isEmpty(addonStatus.GlobalCoverage?.value?.value) ||
        addonStatus.GlobalCoverage.value.value === "Not Applicable")
    ) {
      toast.error("Select value in Global Coverage");
      return;
    }
    setLoader(true);
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  useEffect(() => {
    const onSuccess = (res: any) => {
      setLoader(false);
      if (!res.error) {
        const mand_addon_status = res.response;
        setMandatoryAddon(mand_addon_status);

        setAddonStatus((prev) => ({
          ...prev,
          field_BFB: mand_addon_status.be_fit === 1 ? true : prev.field_BFB,
          field_OPD: mand_addon_status.care_opd === 1 ? true : prev.field_OPD,
          field_WB:
            mand_addon_status.wellness_benefit === 1 ? true : prev.field_WB,
          field_43:
            mand_addon_status.ped_wait_period_modification === 1
              ? true
              : prev.field_43,
          CareShield:
            mand_addon_status.claim_shield === 1 ? true : prev.CareShield,
          NCBSuper:
            mand_addon_status.cumulative_bonus_super === 1
              ? true
              : prev.NCBSuper,
          field_AHC:
            mand_addon_status.annual_health_check_up === 1
              ? true
              : prev.field_AHC,
          field_IC:
            mand_addon_status.instant_cover === 1 ? true : prev.field_IC,
        }));
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log("err", err);
    };

    let param = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      zone: CKYC.CKYC_DETAILS.zone,
    };

    setLoader(true);

    if (SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_SUPREME) {
      HEALTH_PROPOSAL_SERVICES.GET_MANDATORY_CARE_SUPREME_ADDON(
        onSuccess,
        onError,
        param
      );
    }
  }, []);

  const FinalPremium = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
    };

    const onError = (err: any) => {
      console.log("err", err);
    };

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      final_premium: SELECTED_QUOTE_DATA?.premiumDetails?.finalPremium,
    };
    HEALTH_PROPOSAL_SERVICES.GET_FINAL_PREMIUM(onSuccess, onError, param);
  };

  useEffect(() => {
    FinalPremium();
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    RECORD_CKYC_RESPONSE();
  }, [CKYC.CKYC_DETAILS?.kycPincode]);

  const RECORD_CKYC_RESPONSE = () => {
    const onSuccess = (res: any) => {
      console.log("res...", res);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      details: CKYC.CKYC_DETAILS,
      section: "kyc_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
      insurance_type: "HP",
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MAddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          mandatoryAddon={mandatoryAddon}
        />
      ) : (
        <AddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          mandatoryAddon={mandatoryAddon}
          isContinueButtonDisabled={isContinueButtonDisabled}
        />
      )}
    </>
  );
}
